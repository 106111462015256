<template>
<v-container>
  <v-list>
    <v-list-item  class="py-5" >
      <v-hover  v-slot="{ hover }">
        <v-card width="100%" rounded="0" :elevation="hover ? 16 : 4" :class="{'on-hover': hover, 'transition-swing':true}" :to="'/curriculum/Intro-to-AI'" target="_blank">
      <v-row>
              <v-col class="py-0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                <v-img contain :src="'/resource/public/curriculum/intro-to-ai-title.png'"></v-img>
              </v-col>
              <v-col class="py-0" cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                <v-card-title class="py-md-6 pt-1 pb-0">
                  DATA130008: 人工智能导论 | Introduction to Artificial Intelligence
                </v-card-title>
                <v-card-text>
                  本课程的内容主要涵盖人工智能领域的几个基本模块，由简到繁、循序渐进的讲述智能体在解决现实问题过程中相关原理和技术，主要包括对抗搜索、强化学习和概率图模型三个模块的内容。 
                </v-card-text>
              </v-col>
            </v-row>
      </v-card>
      </v-hover>
    </v-list-item>

    <v-list-item  class="py-5" >
      <v-hover v-slot="{ hover }">
        <v-card width="100%" rounded="0" :elevation="hover ? 16 : 4" :class="{'on-hover': hover, 'transition-swing':true}" :to="'/curriculum/Intro-to-NLP'" target="_blank">
      <v-row>
              <v-col class="py-0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                <v-img contain :src="'/resource/public/curriculum/intro-to-nlp-title.png'"></v-img>
              </v-col>
              <v-col class="py-0" cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                <v-card-title class="py-md-6 pt-1 pb-0">
                  DATA130006: 自然语言处理 | Introduction to Natural Language Processing
                </v-card-title>
                <v-card-text>
                  本课程的内容主要涵盖自然语言处理的基础知识，主要包括文本的预处理，语言模型，文本分类，情感分析，文本聚类，特征选择，序列标注等内容，为后续解决更复杂的自然语言问题奠定基础。 
                </v-card-text>
              </v-col>
            </v-row>
      </v-card>
      </v-hover>
    </v-list-item>
  </v-list>


</v-container>
</template>

<script>
  export default {
    name: "indexCurriculum",
}
</script>

<style scoped>
  .v-card {
    transition: opacity .8s ease-in-out;
  }

  .v-card:not(.on-hover) {
    opacity: 0.7;
  }

</style>