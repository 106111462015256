<template>
  <v-container>
    <!-- 无需确认的提示框 snackbar 控制变量为 isShowSnackbar -->
    <v-snackbar top text color="primary" :timeout="2000" v-model="isShowSnackbar">
      <v-row align="center" justify="space-between">
        <v-col cols="1" class="pl-2 py-0" v-if="isSubmitSuccess">
          <v-icon small color="green">check_circle</v-icon>
        </v-col>
        <v-col class="py-0" v-else>
          <v-icon small color="red">cancel</v-icon>
        </v-col>
        <v-col cols="9" class="pa-0">
          <div class="caption" v-for="(message, i) in messages" :key="i">{{message}}</div>
        </v-col>
      </v-row>
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="isShowSnackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- 需要确认的提示框 dialog 控制变量为 isShowDialog-->
    <v-dialog v-model="isShowDialog" max-width="500px">
      <v-card clas="text-center">
        <v-card-title class="dialog-text justify-center">{{dialogMessage}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="closeDelete">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="deleteItemConfirm">确认</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 卡片: 上传文件 -->
     <v-card>
      <v-card-text>
        <v-file-input class="mr-8" label="上传文件" filled multiple small-chips v-model="files" @change="previewImage"></v-file-input>
        <v-card flat class="d-flex flex-column justify-space-between" v-if="files.length">
          <v-row align="start" class="mx-6">
            <v-col v-for="(file, index) in files" :key="index" class="text-center" cols="2">
              <v-icon>mdi-file</v-icon>
              <div class="image-name">{{file.name}}</div>
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on" @click="deletePreviewFile(file)">mdi-close</v-icon>
                  </template>
                  <span>取消上传</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn color="primary" dark class="my-6" @click="uploadFile">确认上传</v-btn>
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>

    <!-- 展示数据的表格 -->
    <v-card class="mt-3">
      <v-card-text>
        <v-data-table :headers="headers" :items="items" :sort-by="['did']" :sort-desc="[true]"
                      :page.sync="page" :items-per-page="itemsPerPage" class="elevation-1" hide-default-footer
                      @page-count="pageCount = $event" :loading="isLoading">

          <template #item.filename="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <a class="a-item" v-bind="attrs" v-on="on" :href="item.url" target="_blank">
                  {{ item.filename }}
                </a>
              </template>
              <span>打开链接</span>
            </v-tooltip>
          </template>

          <!-- 表格中的操作按钮 -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small @click="deleteItem(item)">mdi-delete</v-icon>
              </template>
              <span>删除</span>
            </v-tooltip>
          </template>
        </v-data-table>

      </v-card-text>
      <!-- 表格翻页按钮 -->
      <v-card flat class="text-center pb-6">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
        <!-- <v-text-field :value="itemsPerPage" label="Items per page" type="number" min="0" max="12"
                      @input="itemsPerPage = parseInt($event, 10)"></v-text-field> -->
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: "consolePrivateData",
    data() {
      return {
        // 页面状态控制变量
        isShowSnackbar: false,
        isShowDialog: false,
        isLoading: false,
        isSubmitting: false,
        isSubmitSuccess: false,
        dialogMessage: "", // dialog中显示的message
        messages: [], // snackbar中显示的message

        // 提示框信息
        dialogDelete: false,
        dialogNotice: false,

        // 加载状态
        loadedState: false,

        // 预备数据
        files: [],

        // 表格数据
        page: 1, // 默认所在页数
        pageCount: 0,
        itemsPerPage: 10,
        loadingData: true,
        headers: [
          {text: '序号', sortable: true, value: 'order', width:"8%"},
          {text: '上传时间', value: 'created_at_'},
          {text: '文件名', value: 'filename'},
          {text: '文件大小', value: 'size_'},
          {text: '操作', value: 'actions', sortable: false, align: 'center'},
        ],
        items: [], // 通过api获取初始化数据
        // 当前编辑对象
        editedItem: null,
      }
    },
    methods: {
      // 其他功能函数
      // 文件大小格式化
      formatSize(size, pointLength, units) {
        var unit;
        units = units || ['B', 'K', 'M', 'G', 'TB'];
        while ((unit = units.shift()) && size > 1024) {
          size = size / 1024;
        }
        return (unit === 'B' ? size : size.toFixed(pointLength === undefined ? 2 : pointLength)) + unit;
      },

      // 修改时间格式
      converTime(timeStamp) {
        if (timeStamp) {
          var timeStampObj = new Date(timeStamp)
          return timeStampObj.toLocaleDateString().replace(/\//g, "-") + " " + timeStampObj.toTimeString().substr(0, 8);
        } else {
          return null;
        }
      },

      previewImage() {
      },

      // 加载已有数据信息
      loadData() {
        this.isLoading = true;
        this.$http.get("/api/data/?owner="+localStorage.username,
        {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
            console.log(response.data);
            this.items = response.data;
            for (let i=0;i<this.items.length;i++) {
              this.items[i].size_ = this.formatSize(this.items[i].size)
              this.items[i].created_at_ = this.converTime(this.items[i].created_at)
              this.items[i].order = this.items.length - i;
            }
            var dataSaved = response.data;
            this.isLoading = false;
          })
      },

      // 上传文件
      uploadFile() {
        let formData = new FormData();
        formData.append('uid', localStorage.uid);
        formData.append('username', localStorage.username);
        formData.append('name_zh', localStorage.name_zh);
        formData.append('owner', localStorage.username)

        for (var i = 0; i < this.files.length; i++) {
          formData.append('files[' + i + ']', this.files[i]);
        }
        this.$http.post("/api/data/", formData,
          {headers: {"Authorization": "Token " + localStorage.token, 'content-type': 'multipart/form-data'}})
          .then(response => {
            console.log(response.data);
            this.dialogNotice = true;
            this.messages = ["文件上传成功"];
            this.files = [];
            this.loadData();
          })
      },

      deletePreviewFile(file) {
        this.$delete(this.files, this.files.indexOf(file));
      },

      deleteItemConfirm() {
        this.$http.delete("/api/data/?did=" + this.editedItem.did,
        {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
            this.isShowDialog = false;
            if (response.data == "success") {
              this.isSubmitSuccess = true; // 显示成功图标
              this.isShowSnackbar = true; // 显示消息提示框
              this.messages = ["删除文件成功"];
              this.loadData();
            }
          })
      },

      // 界面按钮功能
      deleteItem(item) {
        this.dialogMessage = "确认删除"
        this.isShowDialog = true;
        this.editedItem = item;
      },

      closeNotice() {
        this.dialogNotice = false;
        this.messages = [];
      },

      closeDelete() {
        this.dialogDelete = false;
      },
    },

    mounted() {
      this.loadData();
    },
  }
</script>

<style scoped>

</style>