<template>
  <v-container>
    <v-card class="mb-4">
      <v-card-title>流量分析</v-card-title>
      <v-card-text>
        <v-card flat class="primary--text"
                v-html="'https://analytics.google.com/analytics/web/#/p274049416/reports/defaulthome?params=_u..nav%3Ddefault'"
                href="https://analytics.google.com/analytics/web/#/p274049416/reports/defaulthome?params=_u..nav%3Ddefault" target="_blank"></v-card>
      </v-card-text>
    </v-card>
    <disc-time></disc-time>
  </v-container>
</template>

<!-- d3 绘图库 -->

<script>
  import discTime from "../elements/discTime";

  export default {
    name: "consoleHome",
    components: {"disc-time": discTime},
  }
</script>

<style scoped>

</style>