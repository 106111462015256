<template>
  <v-container>
    <!-- 无需确认的提示框 snackbar 控制变量为 isShowSnackbar -->
    <v-snackbar top text color="primary" :timeout="2000" v-model="isShowSnackbar">
      <div class="caption" v-for="(message, i) in messages" :key="i">{{message}}</div>
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="isShowSnackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- 无需确认的提示框 snackbar 控制变量为 isShowSnackbar -->
    <v-snackbar top text color="primary" :timeout="2000" v-model="isShowSnackbar">
      <v-row align="center">
        <v-col class="py-0" v-if="isSubmitSuccess">
          <v-icon small color="green">check_circle</v-icon>
        </v-col>
        <v-col class="py-0" v-else>
          <v-icon small color="red">cancel</v-icon>
        </v-col>
        <v-col cols="9" class="pa-0">
          <div class="caption" v-for="(message, i) in messages" :key="i">{{message}}</div>
        </v-col>
      </v-row>
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="isShowSnackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>


    <!-- 图片上传对话框 -->
    <my-upload field="img" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess"
               @crop-upload-fail="cropUploadFail" v-model="isShowUpload"
               :width="600" :height="600" url="" :params="imgParams" :headers="imgHeaders" img-format="png">
    </my-upload>

    <!-- 编辑人员信息表单 -->
    <v-card class="mt-3" :loading="isLoading">
      <v-card-text>
        <!-- 表单填写部分 -->
        <v-form ref="form">
          <v-card-title>基本信息</v-card-title>
          <v-divider></v-divider>
          <v-row>
            <v-col md="6">
              <v-text-field filled disabled label="学工号 ID" value="" :rules="[rules.required]"
                            v-model="editedItem.uid"></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field filled disabled label="用户名 Username" value=""
                            v-model="editedItem.username"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <v-text-field filled label="姓名 Name (zh)" value=""
                            v-model="editedItem.name_zh" :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field filled label="英文名 Name (en)" value=""
                            v-model="editedItem.name_en" :rules="[rules.required]"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <v-text-field filled label="密码 Password" v-model="editedItem.password"
                            :append-icon="pwShow ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]" :type="pwShow ? 'text' : 'password'"
                            name="input-10-1" autocomplete='password' hint="至少 8 位" counter
                            @click:append="pwShow = !pwShow"></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field filled label="再次输入密码 Password Confirm" v-model="editedItem.password2"
                            :append-icon="pwShow2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.match]" :type="pwShow2 ? 'text' : 'password'"
                            name="input-10-2" autocomplete='new-password' hint="至少 8 位" counter
                            @click:append="pwShow2 = !pwShow2"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <v-text-field filled label="电子邮箱 E-mail" name="email" value=""
                            v-model="editedItem.email"></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field filled label="联系方式 Phone" name="phone" value=""
                            v-model="editedItem.phone"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <v-text-field filled label="中文地址 Location (zh)" name="location" value=""
                            v-model="editedItem.location_zh"></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field filled label="英文地址 Location (en)" name="location" value=""
                            v-model="editedItem.location_en"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-textarea filled class="body-2" label="中文简介 Introduction (zh)" value=""
                          v-model="editedItem.description_zh"
                          rows="3"></v-textarea>
            </v-col>
            <v-col cols="6">
              <v-textarea filled class="body-2" label="英文简介 Introduction (en)" value=""
                          v-model="editedItem.description_en"
                          rows="3"></v-textarea>
            </v-col>
          </v-row>

          <!-- 表单选项区域 -->
          <v-row style="z-index: 999;">
            <v-col md="3">
              <v-select offset-y v-model="editedItem.type" :items="typeAll" label="类型 Type">
                <template v-slot:selection="{ item }">
                  <v-chip small dark class="primary">
                    <span>{{ item }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col md="3">
              <v-select v-model="editedItem.entrance" :items="entranceAll" label="入学年份 Entrance">
                <template v-slot:selection="{ item }">
                  <v-chip small dark class="primary">
                    <span>{{ item }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col md="3">
              <v-select v-model="editedItem.area" :items="areaAll" label="研究方向 Area">
                <template v-slot:selection="{ item }">
                  <v-chip small dark class="primary">
                    <span>{{ item }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col md="3">
              <v-select disabled v-model="editedItem.authority" :items="authorityAll" label="权限 Authority">
                <template v-slot:selection="{ item }">
                  <v-chip small dark class="primary">
                    <span>{{ item }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-spacer></v-spacer>
            <v-btn depressed rounded class="my-3" @click="isShowUpload=true">上传头像</v-btn>
            <v-spacer></v-spacer>
          </v-row>
          <v-row align-content="center" justify="center" class="my-4">
            <v-avatar size="200" class="elevation-10">
              <v-img :src="computePortraitUrl" ref="imageForm"></v-img>
            </v-avatar>
          </v-row>


          <v-card-title class="mt-8">个人主页</v-card-title>
          <v-divider></v-divider>

          <v-list shaped class="mb-4">
            <v-list-item-group v-model="editedItem.link_type">
              <template v-for="(item, i) in linkTypes.length">
                <v-list-item :key="`item-${i}`"
                             :value="i"
                             active-class="primary--text text--accent-4"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="$store.state.people.linkTypes[i]"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox :input-value="active" color="primary accent-4"></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>

          <v-card flat v-if="editedItem.link_type==1">
            <v-text-field disabled filled label="网站链接 Link"
                          :value="'/people/'+editedItem.username"></v-text-field>
            <!-- markdown 编辑界面 -->
            <v-card flat id="editor-main" height="600">
              <v-md-editor v-model="editedItem.link1" height="600px" :left-toolbar="leftToolbar"></v-md-editor>
            </v-card>
          </v-card>
          <v-card flat v-if="editedItem.link_type==2">
            <v-text-field disabled filled label="网站链接 Link"
                          :value="'/people/'+editedItem.username"></v-text-field>

            <v-textarea filled label="HTML 文本" value="" v-model="editedItem.link2"
                        rows="8"></v-textarea>
            <v-card align="center" flat class="elevation-3" v-html="editedItem.link2"
                    style="max-height:600px; overflow-y: auto;"></v-card>

          </v-card>
          <v-card flat v-if="editedItem.link_type==3">
            <v-text-field filled label="网站链接 Link" value="" v-model="editedItem.link3"></v-text-field>
          </v-card>
        </v-form>
        <!-- 表单按钮区域 -->
        <v-row justify="center">
          <v-col md="2">
            <v-btn color="primary" dark block class="my-2" @click="submit" :loading="isSubmitting">保 存</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import myUpload from 'vue-image-crop-upload';

  export default {
    name: "consolePrivateProfile",
    components: {"my-upload": myUpload},

    data() {
      return {
        // 页面状态控制变量
        isShowSnackbar: false,
        isShowDialog: false,
        isShowEditor: true,
        isLoading: false,
        isSubmitting: false,
        isSubmitSuccess: false,
        messages: [],

        // 表格数据
        page: 1, // 默认所在页数
        pageCount: 0,
        itemsPerPage: 10,
        loadingData: true,
        headers: [
          // {text: '序号', sortable: true, value: 'pid', width: "10%"},
          {text: '类型', value: 'type'},
          {text: '学工号', value: 'uid'},
          {text: '用户名', value: 'username', sortable: false},
          {text: '姓名', value: 'name_zh'},
          // {text: '英文名', value: 'name_en'},
          {text: '研究方向', value: 'area'},
          {text: '操作', value: 'actions', sortable: false, align: 'center', width: "15%"},
        ],

        // 表单数据选项
        typeAll: this.$store.state.people.typeAllZh,
        areaAll: this.$store.state.people.areaAllZh,
        entranceAll: this.$store.state.people.entranceAll,
        authorityAll: this.$store.state.people.authorityAll,
        linkTypes: this.$store.state.people.linkTypes,

        // 表单密码输入区域
        pwShow: false,
        pwShow2: false,
        rules: {
          required: value => !!value || '必填',
          min: v => v.length >= 8 || '最少8位密码',
          match: v => v == this.editedItem.password || '两次密码输入需保持一致',
        },

        // 表单图片上传
        isShowUpload: false,
        imgParams: {
          token: '123456798',
          name: 'avatar'
        },
        imgHeaders: {
          smail: '*_~'
        },

        // markdown区域参数
        leftToolbar: "undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image code | emoji todo-list tip",

        // 当前编辑的数据
        editedItem: {
          pid: -1,
          uid: "",
          username: "",
          name_zh: "",
          name_en: "",
          password: "",
          password2: "",
          type: "",
          type_num: -1,
          area: "",
          area_num: -1,
          entrance: "",
          authority: "",
          portrait_url: "/resource/public/portrait/default.png",
        },
        // 默认的空白编辑数据, 便于编辑已有数据后再返回空白
      }
    },
    methods: {
      // ----- 后端交互功能 -----
      // 加载数据
      loadData() {
        this.isLoading = true;
        this.$http.get("/api/people/?uid=" + localStorage.uid, {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
            this.editedItem = response.data;
            if (!this.editedItem.link_type | this.editedItem.link_type == "null") {
              this.editedItem.link_type = 0;
            }
            var array = ["link", "link1", "link2", "link3", "description_zh", "description_en"];
            var self = this;
            array.forEach(function (key) {
              if (!self.editedItem[key] | self.editedItem[key] == "null") {
                self.editedItem[key] = "";
              }
            })
            this.isLoading = false;
            // console.log(this.editedItem);
          })
      },

      // 提交数据
      submit() {
        if (this.$refs.form.validate()) { // 表单数据合格开始上传
          this.isSubmitting = true; // 显示按钮上传状态
          // 判断 link 类型
          if (this.editedItem.link_type == 3) {
            this.editedItem.link = this.editedItem.link3;
          } else {
            this.editedItem.link = "/people/" + this.editedItem.username;
          }
          this.editedItem.type_num = this.typeAll.indexOf(this.editedItem.type); // 得到type_num的值 (便于排序)
          this.editedItem.area_num = this.areaAll.indexOf(this.editedItem.area); // 得到area_num的值 (便于排序)
          var formData = new FormData(); //创建表单数据
          for (let key in this.editedItem) {
            formData.append(key, this.editedItem[key])
          }
          // 对于修改的数据 使用PUT请求
          this.$http.put("/api/people/?uid=" + this.editedItem.uid, formData,
            {headers: {"Authorization": "Token " + localStorage.token, 'content-type': 'multipart/form-data'}})
            .then(response => {
              this.isSubmitting = false; // 收到响应关闭按钮上传状态
              if (response.data == "success") {
                this.messages = ["保存成功"]
                this.isSubmitSuccess = true; // 显示保存成功图标
                this.isShowSnackbar = true; // 显示消息提示框
                this.isShowEditor = false;  // 关闭编辑界面
                this.loadData();      // 重新加载数据
              } else {
                this.messages = [];   // 清空消息缓存
                var errors = response.data;
                for (let key in errors) {
                  this.messages.push(key + ": " + errors[key])
                }
                this.isSubmitSuccess = false; // 显示保存失败图标
                this.isShowSnackbar = true;
              }
            })
        }
      },

      // 确认删除数据
      deleteItemConfirm() {
        this.$http.delete("/api/people/?pid=" + this.editedItem.pid,
          {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
            this.messages = ["删除成功"];
            this.isShowDialog = false;  // 关闭删除提示框
            this.isShowSnackbar = true; // 进行消息提示
            this.loadData();            // 重新加载数据
          })
      },

      // 插件功能
      // 头像上传
      cropSuccess(imgDataUrl, field) {
        console.log('-------- crop success --------');
        this.editedItem.portrait_url = imgDataUrl;
        console.log(this.imgDataUrl);
      },
      cropUploadSuccess(jsonData, field) {
        console.log('-------- upload success --------');
        console.log(jsonData);
        console.log('field: ' + field);
      },
      cropUploadFail(status, field) {
        console.log('-------- upload fail --------');
        console.log(status);
        console.log('field: ' + field);
      },

    },
    computed: {
      formTitle() {
        return this.editedItem.pid === -1 ? '添加人员' : '编辑人员 ' + this.editedItem.name_zh
      },
      computePortraitUrl() {
        if (this.editedItem.portrait_url[0] == "/") {
          return this.editedItem.portrait_url + "?" + new Date().getTime();
        } else {
          return this.editedItem.portrait_url;
        }
      }
    },
    mounted() {
      this.loadData();
    },
  }
</script>

<style scoped>

</style>