var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"dense":"","app":_vm.navBarApp,"fixed":"","dark":"","flat":_vm.navBarFlat,"color":_vm.navBarColor}},[_c('v-btn',{staticClass:"hidden-md-and-up",attrs:{"icon":"","color":"white"},on:{"click":function($event){_vm.isShowDrawer=!_vm.isShowDrawer}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("menu")])],1),_c('router-link',{attrs:{"to":"/"}},[_c('div',{staticClass:"nav-logo hidden-sm-and-down",style:({backgroundImage:'url(' + _vm.navLogo + ')'})})]),_c('v-spacer'),_c('v-tabs',{staticClass:"hidden-sm-and-down",attrs:{"right":"","slider-color":"transparent"}},[_c('v-tab',{staticClass:"tab-text",attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t('menu.home')))]),_c('v-tab',{staticClass:"tab-text",attrs:{"to":"/news"}},[_vm._v(_vm._s(_vm.$t('menu.news')))]),_c('v-tab',{staticClass:"tab-text",attrs:{"to":"/people"}},[_vm._v(_vm._s(_vm.$t('menu.people')))]),_c('v-tab',{staticClass:"tab-text",attrs:{"to":"/curriculum"}},[_vm._v(_vm._s(_vm.$t('menu.curriculum')))]),_c('v-tab',{staticClass:"tab-text",attrs:{"to":"/sharedtask"}},[_vm._v(_vm._s(_vm.$t('menu.sharedtask')))]),_c('v-tab',{staticClass:"tab-text",attrs:{"to":"/publication"}},[_vm._v(_vm._s(_vm.$t('menu.publication')))]),_c('v-tab',{staticClass:"tab-text",attrs:{"to":"/DISCOpen"}},[_vm._v(_vm._s(_vm.$t('menu.DISCOpen')))])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("translate")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.languageItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""}},[_c('v-list-item-content',{on:{"click":function($event){return _vm.switchLanguage(item)}}},[_vm._v(" "+_vm._s(item))])],1)}),1)],1),(!_vm.isLogin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":"/login"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-account")])],1)]}}],null,false,3895297171)},[_c('span',[_vm._v(_vm._s(_vm.$t('menu.login')))])]):_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"mx-3 elevation-3",attrs:{"size":"36"}},'v-avatar',attrs,false),on),[_c('v-img',{attrs:{"src":_vm.portrait_url}})],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.portraitItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""}},[_c('v-list-item-content',{on:{"click":function($event){return _vm.menuPortrait(item)}}},[_vm._v(" "+_vm._s(item))])],1)}),1)],1)],1),_c('v-navigation-drawer',{attrs:{"fixed":"","left":"","temporary":""},model:{value:(_vm.isShowDrawer),callback:function ($$v) {_vm.isShowDrawer=$$v},expression:"isShowDrawer"}},[_c('v-list',{attrs:{"nav":"","dense":""}},_vm._l((_vm.menu),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":"","to":item.link}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"pl-4 text-capitalize subtitle-1 "},[_vm._v(_vm._s(_vm.$t(item.name)))])],1)],1)}),1)],1),_c('v-main',[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }