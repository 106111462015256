<template>
  <v-container>
    <v-btn @click="initialize_cols">test</v-btn>
    <v-card>
      <v-card-title>未填名单</v-card-title>
      <v-card-text>
        <v-select :items="nameType" label="名单选项" v-model="selectNameType"></v-select>
        <v-textarea filled rows="2" label="输入名单" v-model="inputName1" dense>
        </v-textarea>
        <p>共 <span class="red--text">{{nameList[selectNameType].length}}</span> 人， <span class="red--text">{{nameList[selectNameType].length-notName.length}}</span>
          人已填报，<span class="red--text">{{notName.length}}</span> 人未填报</p>
        <div v-if="inputName1.length">
          <div v-for="(item, index) in notName" :key="index" class="caption">{{item}}</div>
        </div>

      </v-card-text>
    </v-card>

    <v-card class="mt-4">
      <v-card-title>信息查询</v-card-title>
      <v-card-text>
        TODO: <br>
        1. 定制列 <br>
        2. 根据列名进行信息检索 <br>
        3. 通过上传文件进行数据库信息修改 <br>
        <v-textarea filled rows="2" label="输入名单" v-model="inputName2" dense></v-textarea>
        快速名单选项
        分类别字段选择
        日期展示形式（选项）

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: "lchenInfo",
    data() {
      return {
        // 姓名显示
        selectNameType: "全班",
        nameType: ["全班", "党支部", "预备党员", "家困生"],
        nameList: {
          "全班": ["傅朝", "杨帆", "刘汉卿", "成广德", "李光曦", "王崇宇", "丁洁颖", "李湘怡", "郭保同", "何晓龙", "丁尧昕", "李诗怡", "曹琦瑜", "帅含语", "唐泽辉", "胡恒瑞", "兰如雷", "王昊舟", "朱宇轩", "刘世源", "陈润泽", "陆尧辰", "张其然", "徐沈炎", "张志昊", "黄邦熾", "林锐", "陈志军", "胡荣润", "王开元", "王文斌", "吴宗义", "戴天", "邓铭", "周汈", "蔡秉锜", "陈圣朋", "何皓轩", "胡雨航", "欧阳思远", "姚轶舟", "朱舸舟", "李夏", "罗一浦", "帅明坤", "罗超", "晏炀", "马波", "李熹涵", "满思琪", "张馨月", "王端端", "徐婧", "胡晓奕", "范佳晨", "石茂林", "卢雨婷", "孙晓晨", "房宸涛", "李鸿尧", "马元辰", "吴晴宇", "徐万顷", "杨壹程", "周耕平", "方景贤", "邓建锴", "刘智涵", "杨艺", "焦柯炜", "梅哲", "肖淏丰", "陈凌云", "徐天罡", "朱春名", "蒋冰瑶", "时文睿", "石轶群", "张佳韵", "吴林平", "马书涛", "杨舒"],
          "党支部": ["徐沈炎", "邓铭", "晏炀", "王端端", "陈润泽", "何皓轩", "朱舸舟", "帅明坤", "马波", "石茂林", "孙晓晨", "房宸涛", "李鸿尧", "徐万顷", "吴林平", "王开元", "卢雨婷", "马书涛", "杨帆", "帅含语", "胡荣润", "王文斌", "张馨月", "徐婧", "范佳晨", "梅哲", "时文睿", "张佳韵", "刘智涵", "陈凌云", "李熹涵", "傅朝", "周汈"],
          "预备党员": ["徐沈炎", "邓铭", "晏炀", "王端端", "陈润泽", "何皓轩", "朱舸舟", "帅明坤", "马波", "石茂林", "孙晓晨", "房宸涛", "李鸿尧", "徐万顷", "吴林平", "王开元", "卢雨婷", "马书涛", "杨帆", "帅含语", "胡荣润", "王文斌", "张馨月", "徐婧", "范佳晨", "梅哲", "时文睿", "张佳韵"],
          "家困生": ["杨帆", "刘世源", "陈志军", "王文斌", "邓铭", "李夏", "罗超", "马波", "张馨月", "石茂林", "刘智涵", "杨艺", "吴林平"],
        },
        inputName1: "",
        inputName2: "",
        inputNameList1: [],
        notName: [], // 不在范围内的名字
        // 定制列
        colsShow : [],
        colsHide: [],
        // 人员信息
        info: [],
        colNameDict: {},
      }
    },
    methods: {
      showNameNum: item => item + this.nameList[item],

      // 比较在数组2中不在数组1中的元素
      compare(arr1, arr2) {
        var notArr2 = [];
        for (let i = 0; i < arr2.length; i++) {
          let temp = arr2[i];
          if (arr1.indexOf(temp) < 0) {
            notArr2.push(temp)
          }
        }
        return notArr2;
      },

      // 获取所有列名
      initialize_cols(){
        this.$http.get("/apilchen/info/", {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
            console.log(response.data);
            this.info = response.data.info;
            this.colNameDict = response.data.col_name_dict;
            }
          )
      },
    },
    mounted() {
      this.notName = this.compare(this.inputNameList1, this.nameList[this.selectNameType]);
      // 初始化信息中所有列名
      // this.initialize_cols();
    },
    watch: {
      inputName1: {
        handler: function (val, oldVal) {
          this.inputNameList1 = val.replace(/^\s*|\s*$/g, "").split("\n");
          this.notName = this.compare(this.inputNameList1, this.nameList[this.selectNameType]);
        }
      },
      selectNameType: {
        handler: function (val, oldVal) {
          this.notName = this.compare(this.inputNameList1, this.nameList[val]);
        }
      }
    },
  }
</script>

<style scoped>

</style>