<template>
  <v-container>
    <!-- 无需确认的提示框 snackbar 控制变量为 isShowSnackbar -->
    <v-snackbar top text color="primary" :timeout="2000" v-model="isShowSnackbar">
      <v-row align="center">
        <v-col class="py-0" v-if="isSubmitSuccess">
          <v-icon small color="green">check_circle</v-icon>
        </v-col>
        <v-col class="py-0" v-else>
          <v-icon small color="red">cancel</v-icon>
        </v-col>
        <v-col cols="9" class="pa-0">
          <div class="caption" v-for="(message, i) in messages" :key="i">{{message}}</div>
        </v-col>
      </v-row>
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="isShowSnackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- 需要确认的提示框 dialog 控制变量为 isShowDialog-->
    <v-dialog v-model="isShowDialog" max-width="500px">
      <v-card clas="text-center">
        <v-card-title class="dialog-text justify-center">确认删除 {{editedItem.name_zh}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="closeDelete">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="deleteItemConfirm">确认</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 需要确认的提示框 dialog 控制变量为 isShowDialogRelease-->
    <v-dialog v-model="isShowDialogRelease" max-width="500px">
      <v-card clas="text-center">
        <v-card-title class="dialog-text justify-center">{{dialogMessage}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="closeRelease">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="releaseItemConfirm">确认</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 需要确认的提示框 dialog 控制变量为 isShowDialogEmphasize-->
    <v-dialog v-model="isShowDialogEmphasize" max-width="500px">
      <v-card clas="text-center">
        <v-card-title class="dialog-text justify-center">{{dialogMessage}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="closeEmphasize">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="emphasizeItemConfirm">确认</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 全部人员数据表格 -->
    <v-card>
      <v-card-text>
        <v-btn depressed class="mb-4" @click="addItem()">
          <v-icon left>add</v-icon>
          <span>添加新闻</span>
        </v-btn>
        <v-data-table :headers="headers" :items="items" :sort-by="['pid','type_num', 'type', 'uid']"
                      :sort-desc="[false, false, false]"
                      :page.sync="page" :items-per-page="itemsPerPage" class="elevation-1" hide-default-footer
                      @page-count="pageCount = $event" :loading="isLoading">

          <!-- 表格中的操作按钮 -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mx-1" v-bind="attrs" v-on="on" small @click="editItem(item)">mdi-pencil</v-icon>
              </template>
              <span>编辑</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mx-1" v-bind="attrs" v-on="on" small @click="deleteItem(item)">mdi-delete</v-icon>
              </template>
              <span>删除</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!item.release">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="red" class="mx-1" v-bind="attrs" v-on="on" small
                        @click="releaseItem(item)">launch
                </v-icon>
              </template>
              <span>发布</span>
            </v-tooltip>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="green" class="mx-1" v-bind="attrs" v-on="on" small
                        @click="unreleaseItem(item)">launch
                </v-icon>
              </template>
              <span>取消发布</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!item.emphasize">
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mx-1" v-bind="attrs" v-on="on" small
                        @click="emphasizeItem(item)">star
                </v-icon>
              </template>
              <span>突出</span>
            </v-tooltip>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mx-1" v-bind="attrs" v-on="on" small
                        @click="unemphasizeItem(item)">star_border
                </v-icon>
              </template>
              <span>取消突出</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <!-- 表格翻页按钮 -->
        <v-card flat class="text-center my-3">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
          <!-- <v-text-field :value="itemsPerPage" label="Items per page" type="number" min="0" max="12"
                        @input="itemsPerPage = parseInt($event, 10)"></v-text-field> -->
        </v-card>
      </v-card-text>
    </v-card>

    <!-- 新增/编辑新闻信息表单 -->
    <v-card v-if="isShowEditor" class="mt-3">
      <v-card-text>
        <!-- 表单首行 -->
        <v-row align="center">
          <v-card-title>
            <v-card flat>{{ formTitle }}</v-card>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="isShowEditor=false" class="mr-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider></v-divider>
        <!-- 表单填写部分 -->
        <v-form ref="form">
          <!-- 发布时间 -->
          <v-row class="mt-4">
            <v-col cols="8">
              <v-text-field filled label="新闻摘要 Abstract" value="" :rules="[rules.required]"
                            v-model="editedItem.abstract"></v-text-field>
            </v-col>
            <v-col cols="4" class="mt-2">
              <v-menu v-model="isPickDate" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="editedItem.date" label="新闻发布日期" prepend-icon="mdi-calendar"
                                readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="editedItem.date" locale="zh-cmn" first-day-of-week="1"
                               @input="isPickDate = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <!-- 新闻内容 (中文) -->
              <v-textarea filled label="HTML 文本 (中文)" value="" v-model="editedItem.content_zh"
                          rows="3"></v-textarea>
              <!-- 预览 (中文) -->
              <v-card align="left" flat class="text-news elevation-3 pa-4 mb-6" v-html="editedItem.content_zh"
                      style="height:100px; overflow-y: auto;"></v-card>
            </v-col>
            <v-col cols="6">
              <!-- 新闻内容 (英文) -->
              <v-textarea filled label="HTML 文本 (英文)" value="" v-model="editedItem.content_en"
                          rows="3"></v-textarea>
              <!-- 预览 (英文) -->
              <v-card align="left" flat class="text-news elevation-3 pa-4 mb-6" v-html="editedItem.content_en"
                      style="height:100px; overflow-y: auto;"></v-card>
            </v-col>
          </v-row>


        </v-form>
        <!-- 表单按钮区域 -->
        <v-row justify="center">
          <v-col md="2">
            <v-btn color="primary" dark block class="my-2" @click="submit" :loading="isSubmitting">保 存</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
  export default {
    name: "consoleNews",
    data() {
      return {
        // 页面状态控制变量
        isShowSnackbar: false,
        isShowDialog: false,
        isShowEditor: false,
        isShowDialogRelease: false,
        isShowDialogEmphasize: false,
        isLoading: false,
        isSubmitting: false,
        isSubmitSuccess: false,
        isPickDate: false, // 是否正在挑选时间
        messages: [],
        dialogMessage: "",

        // 表格数据
        page: 1, // 默认所在页数
        pageCount: 0,
        itemsPerPage: 10,
        loadingData: true,
        headers: [
          // {text: '序号', sortable: true, value: 'pid', width: "10%"},
          {text: '序号', value: 'order', width: "15%"},
          {text: '新闻摘要', value: 'abstract'},
          {text: '新闻日期', value: 'date', sortable: false, width: "25%"},
          {text: '操作', value: 'actions', sortable: false, align: 'center', width: "15%"},
        ],
        items: [], // 通过api获取初始化数据
        editedIndex: -1,

        // 输入限制
        rules: {
          required: value => !!value || '必填',
          min: v => v.length >= 8 || '最少8位密码',
          match: v => v == this.editedItem.password || '两次密码输入需保持一致',
        },

        // 当前编辑的数据
        editedItem: {
          nid: -1,
          date: new Date().toISOString().substr(0, 10),
          abstract: "",
          release: false,
          emphasize: false,
          content_zh: "",
          content_en: "",
        },
        // 默认的空白编辑数据, 便于编辑已有数据后再返回空白
        defaultItem: {
          nid: -1,
          date: new Date().toISOString().substr(0, 10),
          abstract: "",
          release: false,
          emphasize: false,
          content_zh: "",
          content_en: "",
        },
      }
    },

    methods: {
      // ----- 按钮功能 -----
      // 添加人员按钮功能
      addItem() {
        this.isShowEditor = true; // 显示编辑界面
        this.editedItem = Object.assign({}, this.defaultItem); // 初始化空编辑对象
      },

      // 编辑人员按钮功能
      editItem(item) {
        this.isShowEditor = true;
        this.editedItem = Object.assign({}, item);
      },

      // 删除人员按钮功能
      deleteItem(item) {
        this.isShowDialog = true;
        this.editedItem = Object.assign({}, item);
      },

      // 关闭删除界面
      closeDelete() {
        this.isShowDialog = false;
      },

      closeRelease() {
        this.isShowDialogRelease = false;
      },

      closeEmphasize() {
        this.isShowEmphasize = false;
      },

      // 发布按钮功能
      releaseItem(item) {
        this.dialogMessage = "确认发布";
        this.isShowDialogRelease = true;
        this.editedItem = Object.assign({}, item);
      },

      // 取消发布按钮功能
      unreleaseItem(item) {
        this.dialogMessage = "取消发布";
        this.isShowDialogRelease = true;
        this.editedItem = Object.assign({}, item);
      },

      // 突出按钮功能
      emphasizeItem(item) {
        this.dialogMessage = "确认突出";
        this.isShowDialogEmphasize = true;
        this.editedItem = Object.assign({}, item);
      },

      // 取消突出按钮功能
      unemphasizeItem(item) {
        this.dialogMessage = "取消突出";
        this.isShowDialogEmphasize = true;
        this.editedItem = Object.assign({}, item);
      },

      // ----- 后端交互功能 -----
      // 加载数据
      loadData() {
        this.isLoading = true;
        this.$http.get("/api/news/", {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
            this.items = response.data;
            console.log(this.items);
            var i = 0;
            var l = this.items.length
            this.items.forEach(function (item) {
              item.order = l - i;
              i++;
            })
            this.isLoading = false;
          })
      },

      // 提交数据
      submit() {
        if (this.$refs.form.validate()) { // 表单数据合格开始上传
          this.isSubmitting = true; // 显示按钮上传状态

          var formData = new FormData(); //创建表单数据
          for (let key in this.editedItem) {
            formData.append(key, this.editedItem[key])
          }
          if (this.editedItem.nid == -1) {
            // 对于新增的数据 使用POST请求
            this.$http.post("/api/news/", formData,
              {headers: {"Authorization": "Token " + localStorage.token, 'content-type': 'multipart/form-data'}})
              .then(response => {
                this.isSubmitting = false; // 收到响应关闭按钮上传状态
                if (response.data == "success") {
                  this.messages = ["保存成功"]
                  this.isSubmitSuccess = true; // 显示成功图标
                  this.isShowSnackbar = true; // 显示消息提示框
                  this.isShowEditor = false;  // 关闭编辑界面
                  this.loadData();     // 重新加载数据
                } else {
                  this.isSubmitSuccess = false; // 显示失败图标
                  this.messages = []; // 清空消息缓存
                  var errors = response.data;
                  for (let key in errors) {
                    this.messages.push(key + ": " + errors[key])
                  }
                  this.isShowSnackbar = true;
                }
              })
          } else {
            // 对于修改的数据 使用PUT请求
            this.$http.put("/api/news/?nid=" + this.editedItem.nid, formData,
              {headers: {"Authorization": "Token " + localStorage.token, 'content-type': 'multipart/form-data'}})
              .then(response => {
                this.isSubmitting = false; // 收到响应关闭按钮上传状态
                if (response.data == "success") {
                  this.messages = ["保存成功"]
                  this.isSubmitSuccess = true; // 显示成功图标
                  this.isShowSnackbar = true; // 显示消息提示框
                  this.isShowEditor = false;  // 关闭编辑界面
                  this.loadData();      // 重新加载数据
                } else {
                  this.isSubmitSuccess = false; // 显示失败图标
                  this.messages = [];   // 清空消息缓存
                  var errors = response.data;
                  for (let key in errors) {
                    this.messages.push(key + ": " + errors[key])
                  }
                  this.isShowSnackbar = true;
                }
              })
          }
        }
      },

      // 确认发布数据
      releaseItemConfirm() {
        this.editedItem.release = !this.editedItem.release;
        this.editedItem.releasestr = this.editedItem.release ? "已发布" : "未发布";
        var formData = new FormData(); //创建表单数据
        formData.append("release", this.editedItem.release);
        this.$http.put("/api/news/?nid=" + this.editedItem.nid, formData,
          {headers: {"Authorization": "Token " + localStorage.token, 'content-type': 'multipart/form-data'}})
          .then(response => {
            this.isSubmitting = false; // 收到响应关闭按钮上传状态
            console.log(response.data)
            if (response.data == "success") {
              this.messages = [this.dialogMessage + "成功"];
              this.isSubmitSuccess = true; // 显示成功图标
              this.isShowSnackbar = true; // 显示消息提示框
              this.isShowDialogRelease = false; // 关闭发布界面
              this.loadData();      // 重新加载数据
            }
          })
      },

      // 确认突出数据
      emphasizeItemConfirm() {
        this.editedItem.emphasize = !this.editedItem.emphasize;
        this.editedItem.emphasizestr = this.editedItem.emphasize ? "已突出" : "未突出";
        var formData = new FormData(); //创建表单数据
        formData.append("emphasize", this.editedItem.emphasize);
        this.$http.put("/api/news/?nid=" + this.editedItem.nid, formData,
          {headers: {"Authorization": "Token " + localStorage.token, 'content-type': 'multipart/form-data'}})
          .then(response => {
            this.isSubmitting = false; // 收到响应关闭按钮上传状态
            console.log(response.data)
            if (response.data == "success") {
              this.messages = [this.dialogMessage + "成功"];
              this.isSubmitSuccess = true; // 显示成功图标
              this.isShowSnackbar = true; // 显示消息提示框
              this.isShowDialogEmphasize = false; // 关闭发布界面
              this.loadData();      // 重新加载数据
            }
          })
      },

      // 确认删除数据
      deleteItemConfirm() {
        this.$http.delete("/api/news/?nid=" + this.editedItem.nid,
          {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
            this.messages = ["删除成功"];
            this.isSubmitSuccess = true; // 显示成功图标
            this.isShowDialog = false;  // 关闭删除提示框
            this.isShowSnackbar = true; // 进行消息提示
            this.loadData();            // 重新加载数据
          })
      },

      // 插件功能
      // 头像上传
      cropSuccess(imgDataUrl, field) {
        console.log('-------- crop success --------');
        this.editedItem.portrait_url = imgDataUrl;
        console.log(this.imgDataUrl);
      },
      cropUploadSuccess(jsonData, field) {
        console.log('-------- upload success --------');
        console.log(jsonData);
        console.log('field: ' + field);
      },
      cropUploadFail(status, field) {
        console.log('-------- upload fail --------');
        console.log(status);
        console.log('field: ' + field);
      },

    },
    computed: {
      formTitle() {
        return this.editedItem.nid === -1 ? '添加新闻' : '编辑新闻 ' + this.editedItem.abstract
      },
    },
    mounted() {
      this.loadData();
    },
  }
</script>

<style scoped>
  .text-news >>> a {
    text-decoration: none;
  }

</style>