<template>
  <v-app>
    <v-app-bar dense :app="navBarApp" fixed dark :flat="navBarFlat" :color="navBarColor">
      <!-- 隐藏式导航栏 -->
      <v-btn icon color="white" class="hidden-md-and-up" @click="isShowDrawer=!isShowDrawer">
        <v-icon small>menu</v-icon>
      </v-btn>

      <router-link to="/">
        <div class="nav-logo hidden-sm-and-down"
             :style="{backgroundImage:'url(' + navLogo + ')'}"></div>
      </router-link>

      <v-spacer></v-spacer>

      <!-- 导航栏 -->
      <v-tabs right slider-color="transparent" class="hidden-sm-and-down">
        <v-tab to="/" class="tab-text">{{$t('menu.home')}}</v-tab>
        <v-tab to="/news" class="tab-text">{{$t('menu.news')}}</v-tab>
        <v-tab to="/people" class="tab-text">{{$t('menu.people')}}</v-tab>
        <v-tab to="/curriculum" class="tab-text">{{$t('menu.curriculum')}}</v-tab>
        <v-tab to="/sharedtask" class="tab-text">{{$t('menu.sharedtask')}}</v-tab>
        <v-tab to="/publication" class="tab-text">{{$t('menu.publication')}}</v-tab>
        <v-tab to="/DISCOpen" class="tab-text">{{$t('menu.DISCOpen')}}</v-tab>
        <!--   <v-tab to="/blog" class="tab-text">博客</v-tab> -->
        <!--   <v-tab to="/demo" class="tab-text">DEMO</v-tab> -->
      </v-tabs>

      <!-- 切换语言 -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="white" v-bind="attrs" v-on="on">
            <v-icon small>translate</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-for="(item, index) in languageItems" :key="index" link>
            <v-list-item-content @click="switchLanguage(item)"> {{ item }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- 登录按钮 -->
      <v-tooltip bottom v-if="!isLogin">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" to="/login">
            <v-icon color="white">mdi-account</v-icon>
          </v-btn>
        </template>
        <span>{{$t('menu.login')}}</span>
      </v-tooltip>
      <!-- 后台管理按钮 -->
      <v-menu offset-y v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar size="36" v-bind="attrs" v-on="on" class="mx-3 elevation-3">
            <v-img :src="portrait_url"></v-img>
          </v-avatar>
        </template>
        <v-list dense>
          <v-list-item v-for="(item, index) in portraitItems" :key="index" link>
            <v-list-item-content @click="menuPortrait(item)"> {{ item }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="isShowDrawer" fixed left temporary>
      <v-list nav dense>
        <v-list-item v-for="(item, index) in menu" :key="index" link :to="item.link">
          <v-list-item-content>
            <v-list-item-title class="pl-4 text-capitalize subtitle-1 ">{{$t(item.name)}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  // import indexNav from "./indexNav";

  export default {
    name: "indexEntry",
    // components: {"nav-index": indexNav},
    data() {
      return {
        navLogo: "http://fudan-disc.com/resource/users/lchen/image/home-logo-fudan.png",
        // navLogo: require('@/assets/fudanlogo.png'),
        // 显示数据
        isShowDrawer: false,
        menu: [
          {link: "/", name: 'menu.home'},
          {link: "/news", name: 'menu.news'},
          {link: "/people", name: 'menu.people'},
          {link: "/curriculum", name: 'menu.curriculum'},
          {link: "/sharedtask", name: 'menu.sharedtask'},
          {link: "/publication", name: 'menu.publication'},
          {link: "/DISCOpen", name: 'menu.DISCOpen'},
        ],
        languageItems: ["English", "中文"],
        isLogin: false,
        portrait_url: "",


        // Nav控制变量
        navBarFlat: false,
        navBarColor: "transparent",
        navBarApp: true,
      }
    },
    methods: {
      // 切换语言
      switchLanguage(item) {
        if (item == "English") {
          this.$i18n.locale = "en";
        } else if (item == "中文") {
          this.$i18n.locale = "zh";
        }
      },
      // 前往后台管理
      menuPortrait(item) {
        if (item == "后台管理" | item == "Console") {
          window.open("/console");
        } else {
          localStorage.clear(); // 清空localstorage
          this.isLogin = false; // 修改登录状态
        }
      },
      // 根据是否为主页调整nav样式
      judgeNavBar() {
        if (this.$route.name == "indexHome") {
          this.showHomeNav(); // 显示主页nav
          window.addEventListener('scroll', this.handleScroll);
        } else {
          this.showOtherNav();
          window.removeEventListener('scroll', this.handleScroll);
        }
      },
      // 显示主页nav
      showHomeNav() {
        this.navBarApp = false;
        this.navBarFlat = true;
        this.navBarColor = "transparent";
      },
      showOtherNav() {
        this.navBarApp = true;
        this.navBarFlat = false;
        this.navBarColor = "#255cbf";
        // this.navBarColor = "#06436F";

      },
      // 处理主页滚动
      handleScroll(e) {
        // var scrollTop = document.documentElement.scrollTop;
        var scrollTop = window.pageYOffset;
        if (scrollTop >= Math.max(window.innerWidth/3, 200)) {
          this.navBarApp = false;
          this.navBarFlat = false;
          this.navBarColor = "#255cbf";
          // this.navBarColor = "#06436F";
        } else {
          this.navBarApp = false;
          this.navBarFlat = true;
          this.navBarColor = "transparent";
        }
      },
    },
    mounted() {
      console.log(this.$route.name);
      this.isLogin = localStorage.is_login;
      if (this.isLogin) {
        this.portrait_url = localStorage.portrait_url + "?" + new Date().getTime();
      }
      // 根据是否为主页调整nav样式
      this.judgeNavBar();
    },
    computed: {
      portraitItems() {
        if (this.$i18n.locale == "zh") {
          return ["后台管理", "退出登录"]
        } else {
          return ["Console", "Log out"]
        }
      }
    },
    watch: {
      $route: { // 监听路由变化
        handler: function (val, oldVal) {
          this.judgeNavBar();
        },
        deep: true,
      },
    },
  }
</script>

<style scoped>
  .tab-text {
    text-transform: capitalize;
  }

  .nav-logo {
    z-index: 999;
    width: 80px;
    height: 48px;
    object-fit: cover;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }

</style>