import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import wechatIcon from "@/assets/icon/wechat.svg"
import wechatIcon2 from "@/assets/icon/wechat2.svg"
import githubIcon from "@/assets/icon/github.svg"
import videoIcon from "@/assets/icon/video.svg"

const vuetifyOpts = {
  icons: {
    iconfont: "mdi",
    values: {
      wechat: {
        component: wechatIcon,
      },
      wechat2: {
        component: wechatIcon2,
      },
      github: {
        component: githubIcon,
      },
      video: {
        component: videoIcon,
      },
    }
  }
};

export default new Vuetify(vuetifyOpts);
