<template>
  <v-footer padless>

    <v-card width="100%" flat tile class="grey lighten-3 white--text text-center">
      <v-card width="100%" color="transparent" flat>


        <v-row justify="start">
          <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2" class="pb-0">
            <div class="d-flex justify-center justify-md-end mb-0 mt-2">
              <div>
                <v-img height="80" width="80" :src="footerWechat"></v-img>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="mt-1" v-bind="attrs" v-on="on">
                      <v-icon>$vuetify.icons.wechat2</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('footer.wechat')}}</span>
                </v-tooltip>
              </div>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10" class="pt-0 pt-md-3">

            <div class="black--text text-md-left body-2 mt-0 mt-md-2 pr-4 mb-2">
              {{$t('footer.name')}}
              <!--            {{ new Date().getFullYear() }}-->
            </div>

            <div class="text-md-left">
              <v-icon small color="grey" class="material-icons-outlined lighten-4">place</v-icon>
              <span class="caption grey--text font-weight-light ml-1"> {{$t('footer.location')}} </span>
            </div>

            <div class="text-md-left">
              <v-icon small color="grey" class="material-icons-outlined lighten-4">mail</v-icon>
              <span class="caption grey--text font-weight-light ml-1"> disclab@fudan.edu.cn </span>
            </div>

            <div class="text-md-left" v-if="$i18n.locale=='zh'">
              <v-icon small color="grey" class="material-icons-outlined lighten-4">star</v-icon>
              <span class="caption grey--text font-weight-light ml-1"> 沪ICP备20007385号 </span>
            </div>

          </v-col>
        </v-row>

      </v-card>
    </v-card>
  </v-footer>
</template>

<script>
  export default {
    name: "indexFooter",
    data(){
      return {
        // footerWechat: require('@/assets/img/wechat.png'),
        footerWechat: "/resource/users/lchen/image/home-footer-wechat.png",
      }
    }
  }
</script>

<style scoped>

</style>