<template>
    <v-container>
      <header class="header">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <h1 style="width:90%;font-size:40px"> DATA130008: 人工智能导论</h1>
            </div>
          </div>
        </div>
      </header>
    
        <div class="site-section">
            <div class="container">
                <div class="row middle">
                    <div class="col-lg-12" id="teacher">
                        <br>
                        <div class="section-title">
                            <h2>教师信息</h2>
                        </div>
    
                        <div class="trend-contents">
                          <table class="table table-bordered" style="width: 80%" align="center">
                            <tbody>
                                <tr> <td width="width:30%"><strong>授课教师</strong></td> <td width="width:50%">魏忠钰（副教授）</td></tr>
                                <tr> <td style="width:30%"><strong>电子邮箱</strong></td> <td style="width:50%">zywei@fudan.edu.cn</td></tr>
                                <tr> <td style="width:30%"><strong>办公室</strong></td> <td style="width:50%">新金博大厦 1407</td></tr>
                                <tr> <td style="width:30%"><strong>个人主页</strong></td> <td style="width:50%"><a href="http://www.sdspeople.fudan.edu.cn/zywei/">http://www.sdspeople.fudan.edu.cn/zywei/</a></td></tr>
                          </tbody>
                          </table>
                        </div>
                    </div>
                    <div class="col-lg-12" id="introduction">
                        <div class="section-title">
                            <br>
                            <h2 style="color: #1a1a1a">课程介绍</h2>
                        </div>
    
                        <div class="trend-entry d-flex">
                            <p>
                                本课程是计算机学科的核心课程，面向本科高年级学生。本课程的内容主要涵盖人工智能领域的几个基本模块，由简到繁、循序渐进的讲述智能体在解决现实问题过程中相关原理和技术，主要包括对抗搜索、强化学习和概率图模型三个模块的内容。第一个模块是搜索的基本方法，包括搜索问题的定义，无信息搜索方法，有信息搜索方法，对抗搜索以及约束满足问题的回溯搜索方法。第二个模块是强化学习，内容包括马尔可夫决策过程，模型无关的强化学习以及值近似的强化学习。第三个模块是概率图模型，内容包括贝叶斯网络的表示和推理，以及马尔可夫模型和隐马尔可夫模型。结合课程实践，希望为大家打下一个后续进一步学习人工智能的基础。
                            </p>
    
                        </div>
                    </div>
                    <div class="col-lg-12" id="chapters">
                        <br>
                        <div class="section-title">
                            <h2>课程内容</h2>
                        </div>
    
                        <div class="trend-contents">
                          <table class="table table-bordered" style="width: 80%" align="center">
                            <tbody>
                                <tr> <td width="200px"><strong>第零章</strong></td> <td width="450px">课程导引</td> <td width="200px"><a href="./slides/chapter0.pdf">课件</a></td></tr>
                                <tr> <td width="200px"><strong>第一章</strong></td> <td width="450px">无信息的搜索</td> <td width="200px"><a href="./slides/chapter1.pdf">课件</a></td></tr>
                                <tr> <td width="200px"><strong>第二章</strong></td> <td width="450px">有信息的搜索</td> <td width="200px"><a href="./slides/chapter2.pdf">课件</a></td></tr>
                                <tr> <td width="200px"><strong>第三章</strong></td> <td width="450px">对抗搜索</td> <td width="200px"><a href="./slides/chapter3.pdf">课件</a></td></tr>
                                <tr> <td width="200px"><strong>第四章</strong></td> <td width="450px">约束满足问题</td> <td width="200px"><a href="./slides/chapter4.pdf">课件</a></td></tr>
                                <tr> <td width="200px"><strong>第五章</strong></td> <td width="450px">强化学习的有模型方法</td> <td width="200px"><a href="./slides/chapter5.pdf">课件</a></td></tr>
                                <tr> <td width="200px"><strong>第六章</strong></td> <td width="450px">强化学习的无模型方法</td> <td width="200px"><a href="./slides/chapter6.pdf">课件</a></td></tr>
                                <tr> <td width="200px"><strong>第七章</strong></td> <td width="450px">强化学习的值近似方法</td> <td width="200px"><a href="./slides/chapter7.pdf">课件</a></td></tr>
                                <tr> <td width="200px"><strong>第八章</strong></td> <td width="450px">贝叶斯网络的表示</td> <td width="200px"><a href="./slides/chapter8.pdf">课件</a></td></tr>
                                <tr> <td width="200px"><strong>第九章</strong></td> <td width="450px">贝叶斯网络的推理</td> <td width="200px"><a href="./slides/chapter9.pdf">课件</a></td></tr>
                                <tr> <td width="200px"><strong>第十章</strong></td> <td width="450px">隐马尔可夫模型</td> <td width="200px"><a href="./slides/chapter10.pdf">课件</a></td></tr>
                          </tbody>
                          </table>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <a href="./slides/Intro-to-AI-slides.zip">课件打包下载</a>
                        </div>
    
    
                    </div>
                        <div class="col-lg-12" id="resources">
                            <br>
                            <div class="section-title">
                                <h2 style="color: #1a1a1a">课程视频资源</h2>
                            </div>
        
                            <div class="trend-contents" style="width: 80%">
                                <ul>
                                    <li>B站：<a href="https://space.bilibili.com/471559565?spm_id_from=333.788.0.0">复旦DISC魏忠钰</a> </li>
                                    <li>智慧树：<a href="https://coursehome.zhihuishu.com/courseHome/1000000860/144273/18">人工智能导论</a></li>
                                    <li>在线学堂：<a href="https://next-studio.xuetangx.com/pro/editcoursemanage/courseinfo/5334947">人工智能导论</a></li>
                                    <li>腾讯视频：<a href="https://v.qq.com/x/cover/mzc003buvimb69s/a3342rufsqs.html">数智说书人</a></li>
                                </ul>
                            </div>
                        </div>
    
                        <div class="col-lg-12" id="referrences">
                            <br>
                            <div class="section-title">
                                <h2 style="color: #1a1a1a">教材及参考书目</h2>
                            </div>
        
                            <div class="trend-contents">
                                <ul>
                                    <li>Stuart J. Russell, Peter Norvig (2009) Artificial Intelligence A Modern Approach, 3rd Edition.2009, Prentice Hall</li>
                                    <li>Stanford(CS221、CS234)和UCB(CS188)课程讲义</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
    <br>
    <br>
    
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         感谢2017年至2022年参与复旦大学大数据学院DATA130008课程的所有助教和选课学生对于课程内容的整理和建议。       <br><br>
    
        
    
    </v-container>
    </template>
    
    <script>
      export default {
        name: "pageIntro2ai"
      }
    </script>
    
    <style scoped>
    
    </style>