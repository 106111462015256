module.exports = {
  menu: {
    home: "home",
    news: "news",
    people: "people",
    publication: "publication",
    curriculum:"curriculum",
    sharedtask:"SharedTask",
    DISCOpen:"DISCOpen",
    login: "Login",
    language: "Language",
    portrait1: "Dashboard",
    portrait2: "Logout",
  },
  home: {
    about: "About Us",
    news: "News",
    seminar: "Seminar Upcoming",
    seminar_content: {
      speaker: "Speaker",
      host: "Host",
      time: "Time",
      id: " ID",
      pw: "Password",
      live_link: "Live Link",
    },
    works: "Highlight Work",
    works_annotation: {
      title: "Data Annotation",
      intro: "Fudan DISC Lab formulate several computational framework for interdisciplinary data, including reasoning QA, medical dialogue and information propagation to develop the application scenarios of NLP techniques. Corresponding dataset is constructed to promote data enpowerment. ",
    },
    works_discopen: {
      title: "DISCOpen Project",
      intro: "Based on the research work in the laboratory, DISCOpen takes the subject direction as the basic unit and conducts secondary development for research codes, aiming to provide a convenient experimental environment for academic research in related directions, and provide interface demonstration and application development for downstream enterprises.",
    }
  },
  footer:{
    name: "Data Intelligence and Social Computing Lab",
    location: "Xinkingbo, Yangpu district, Shanghai, China",
    wechat: "Follow our wechat account to receive updating news!",
  },
  content: {
    main: "this is content"
  },
  login: {
    home: "Back to homepage",
    title: "Fudan DISC",
    username: "Student/Staff ID",
    password: "Password",
    btn: "login",
    inexistence: "The user does not exist.",
    fail: "Incorrect password.",
    success: "Login successfully",
    forget: "Forget password?",
    forgetInfo: "Initial password is set as the pinyin of name (0 for the part less than 8 characters). If modified, please contact the website manager."
  },
  publication: {
    pdf: "PDF",
    slides: "Slides",
    dataset: "Dataset",
    code: "Code",
    video: "Video",
    wechat: "Wechat Article",
    filterArea: "Area",
  },
  people: {
    current: "Current Members",
    former: "Former",
    teacher: "Teachers",
    titles: {0:"PostDoc", 1:"PhD", 2:"Master", 3:"Undergraduate"},
    titlesFormer: {0:"PostDoc", 1:"PhD", 2:"Master", 3:"Undergraduate"},
    postdoc: "PostDoc",
    phd: "PhD",
    master: "Master",
    undergraduate: "Undergraduate"
  }
}