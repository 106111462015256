<template>
  <v-card flat color="transparent" class="fill-height" v-if="isLoaded">
    <v-card-title class="pt-0"><span class="title-type">{{$t('home.seminar')}}</span></v-card-title>
    <v-card class="mt-6">
      <v-card-title class="primary rounded-0 elevation-3 pa-2 pl-4">
        <h5 class="white--text font-weight-bold">{{seminarData.title}}</h5>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-row align="center" justify="space-around">
          <v-col cols="12" class="d-flex justify-center pb-0 pt-1">
            <v-avatar size="70" class="mx-3 elevation-6">
              <v-img :src="seminarData.image_url"></v-img>
            </v-avatar>
          </v-col>
          <v-col cols="12" class="px-1">
            <v-row class="py-1" justify="center">
              <v-col class="py-0 pr-0" cols="2">
                <v-icon color="grey" size="20" class="px-2">mdi-account</v-icon>
              </v-col>
              <v-col class="py-0 pl-0"><span>{{$t('home.seminar_content.speaker')}}: {{seminarData.speaker}} </span> <span class="caption" v-if="seminarData.ins">({{seminarData.ins}})</span>
              </v-col>
            </v-row>
            <v-row class="py-1">
              <v-col class="py-0 pr-0" cols="2">
                <v-icon color="grey" size="20" class="px-2">hdr_weak</v-icon>
              </v-col>
              <v-col class="py-0 pl-0"><span>{{$t('home.seminar_content.host')}}: {{seminarData.host}} </span> <span class="caption"> ({{seminarData.host_ins}})</span>
              </v-col>
            </v-row>
            <v-row class="py-1">
              <v-col class="py-0 pr-0" cols="2">
                <v-icon color="grey" size="20" class="px-2">mdi-clock-time-four-outline</v-icon>
              </v-col>
              <v-col class="py-0 pl-0"><span>{{$t('home.seminar_content.time')}}: {{seminarData.date}} {{seminarData.start}}-{{seminarData.end}}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="pt-0">
        <v-row>
          <v-col cols="12" class="py-1">
            {{seminarData.room_type}}{{$t('home.seminar_content.id')}}: {{seminarData.room_id}}
          </v-col>
          <v-col cols="12" class="py-1" v-if="seminarData.room_pw">
            {{$t('home.seminar_content.pw')}}: {{seminarData.room_pw}}
          </v-col>
        </v-row>
        <v-row class="py-1">
          <v-col class="py-0"><span>{{$t('home.seminar_content.live_link')}}: <a :href="seminarData.live_link"
                                             target="_blank">{{seminarData.live_link}}</a></span></v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>

</template>

<script>
  export default {
    name: "indexHomeSeminar",
    data() {
      return {
        isLoaded: false,
        dataRaw: {},
        seminarData: {},
        seminarDataZh: {},
        seminarDataEn: {},
      }
    },
    methods: {
      loadData() {
        this.$http.get("/api/read_seminar/?recent=1")
          .then(response => {
            this.dataRaw = response.data;
            this.dataRaw.image_url = this.dataRaw.image_url +'?'+new Date().getTime();
            var $this = this;
            // 将长度为0的字段切换为另一种语言的
            var convertField = ["title", "abstract", "speaker", "ins", "des", "host", "host_ins"];
            convertField.forEach(function (item) {
              let fieldZh = item + "_zh";
              let fieldEn = item + "_en";
              if ($this.dataRaw[fieldEn].length == 0) {
                $this.dataRaw[fieldEn] = $this.dataRaw[fieldZh];
              } else if ($this.dataRaw[fieldZh].length == 0) {
                $this.dataRaw[fieldZh] = $this.dataRaw[fieldEn];
              }
            })
            this.dataRaw.image_url = this.dataRaw.image_url + '?' + new Date().getTime();
            //统一中英文字段
            this.seminarDataZh = Object.assign({}, this.dataRaw);
            this.seminarDataEn = Object.assign({}, this.dataRaw);
            convertField.forEach(function (item) {
              let fieldZh = item + "_zh";
              let fieldEn = item + "_en";
              $this.seminarDataZh[item] = $this.dataRaw[fieldZh];
              $this.seminarDataEn[item] = $this.dataRaw[fieldEn];
            })
            if (this.seminarDataEn.room_type=="腾讯会议") {
              this.seminarDataEn.room_type="Tencent Meeting";
            } else if (this.seminarDataEn.room_type=="zoom会议") {
              this.seminarDataEn.room_type="zoom";
            }
            this.seminarData = this.seminarDataZh; // 默认是中文
            this.isLoaded = true;
          })
      },
    },
    mounted() {
      this.loadData();

    },
    watch: {
      '$i18n.locale': { // 监听路由变化
        handler: function (val, oldVal) {
          if (val=="zh") {
            this.seminarData = this.seminarDataZh;
          } else if (val=="en") {
            this.seminarData = this.seminarDataEn;
          }
        },
        deep: true,
      },
    }
  }
</script>

<style scoped>
  .title-type {
    border-bottom: 2px solid #255cbf;
  }

  a {
    text-decoration: none;
  }

</style>