import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from "./router";  // 配置路由
import VueI18n from "vue-i18n"; // 配置双语
import axios from 'axios'       // 配置后端请求访问
import store from './store'     // 配置全局状态管理

import ImageShadow from 'vue-image-shadow' // 配置图片阴影组件
import VueAwesomeSwiper from 'vue-awesome-swiper' // 配置首页swiper
import 'swiper/css/swiper.css' // 导入swiper样式

import VueMarkdownEditor from '@kangc/v-md-editor'; // 配置markdown编辑器
import '@kangc/v-md-editor/lib/style/base-editor.css'; // 导入markdown渲染样式
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'; // 导入markdown渲染样式
import githubTheme from '@kangc/v-md-editor/lib/theme/github'; // 导入markdown渲染样式 github
import createKatexPlugin from '@kangc/v-md-editor/lib/plugins/katex/cdn'; // 配置markdown编辑器 katex 公式语法
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index'; // 配置markdown编辑器 copy code
import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index'; // 配置markdown编辑器 emoji
// import createMermaidPlugin from '@kangc/v-md-editor/lib/plugins/mermaid/cdn'; // 配置markdown编辑器 流程图
import createTodoListPlugin from '@kangc/v-md-editor/lib/plugins/todo-list/index'; // 配置markdown编辑器 todo-list
// import createTipPlugin from '@kangc/v-md-editor/lib/plugins/tip/index'; // 配置markdown编辑器 tips
import Vuebar from 'vuebar'; // 美化scrollbar
import VueGtag from "vue-gtag"; // 访问量控制

Vue.config.productionTip = false
Vue.prototype.$http = axios; // 更改后端请求函数名

Vue.use(VueI18n);          // 配置双语
Vue.component('image-shadow', ImageShadow)  // 配置图片阴影组件
Vue.use(VueAwesomeSwiper); // 配置首页swiper组件
VueMarkdownEditor.use(vuepressTheme); // 配置markdown编辑器样式
VueMarkdownEditor.use(githubTheme); // 配置markdown编辑器样式
VueMarkdownEditor.use(createKatexPlugin());
VueMarkdownEditor.use(createCopyCodePlugin());
VueMarkdownEditor.use(createEmojiPlugin());
// VueMarkdownEditor.use(createMermaidPlugin());
VueMarkdownEditor.use(createTodoListPlugin());
// VueMarkdownEditor.use(createTipPlugin());
Vue.use(VueMarkdownEditor); // 配置markdown编辑器
Vue.use(Vuebar);

// 配置流量监控
Vue.use(VueGtag, {
  config: {
    id: "G-JMVX4CCKEM",
  }
});

const i18n = new VueI18n({
  locale: 'zh', // 默认中文语言标识
  messages: {
    'zh': require('./assets/lang/zh.js'),
    'en': require('./assets/lang/en.js')
  }
})

new Vue({
  router,  // 挂载路由工具
  store,   // 挂载状态管理工具
  vuetify, // 挂载美化工具vuetify
  i18n,    // 挂载双语工具
  render: h => h(App)
}).$mount('#app')
