import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

var myDate = new Date();
var thisYear = myDate.getFullYear();
var Section = thisYear - 2014;
var arrYear = [];
for (var i=0; i<=Section; i++) {
  arrYear.push(thisYear--)
}

const store = new Vuex.Store({
  state: {
    people: {
      typeAllZh: ["教授", "副教授", "青年研究员", "青年副研究员", "博士后", "博士生", "硕士生", "本科生", "曾经在组内"],
      typeAllEn: ["Professor", "Associate Professor", "Professor", "Assistant Professor", "Postdoc", "PhD", "Master", "Undergraduate", "Former"],
      areaAllZh: ["无", "社会计算", "知识建模与隐私计算", "人机交互与具身学习", "多模态与机器学习","计算论辩","智慧医疗","图模型"],
      areaAllEn: ["None", "Computational Sociology", "Knowledge Modeling", "Interactive System and Text Generation", "Cross Vision and Language Understanding"],
      entranceAll: arrYear,
      authorityAll: ["管理员", "普通用户"],
      linkTypes: ["不显示个人主页", "模板网页（需编辑 markdown 文档）", "静态html页面（需编辑 html 文本）", "已有网页（需填写网页链接）"],
      studentTypeAllZh: ["博士生", "硕士生", "本科生"],
      studentTypeAllEn: ["PhD", "Master", "Undergraduate"],
    },
    publication: {
      yearAll:arrYear,
      monthAll: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      typeAllZh: ["论文", "学术专著", "专利"],
    },
    article: {
      typeAllZh: ["讲座讯息","报告概要","组内热点","论文解读","技术教程"],
      typeAllEn: ["Seminar Upcoming","Seminar Report","Lab News","Paper Sharing","Technique Blog"],
    }
  },
  getters: {
    getLoginStatus(state) {
      return state.isLgoin;
    },
  },
  mutations: {
    addLoginInfo(state, data) {
      state.isLogin = true;
      console.log(data);
    },
  },
  actions: {},
  modules: {}
})

export default store
