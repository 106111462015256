<template>
  <!-- TODO 1.批量删除 2.懒加载 不断下拉加载更多图片 图片页码 3.管理重复图片 更改排序界面 -->
  <v-container>
    <!-- 无需确认的提示框 snackbar 控制变量为 isShowSnackbar -->
    <v-snackbar top text color="primary" :timeout="2000" v-model="isShowSnackbar">
      <v-row align="center" justify="space-between">
        <v-col cols="1" class="pl-2 py-0" v-if="isSubmitSuccess">
          <v-icon small color="green">check_circle</v-icon>
        </v-col>
        <v-col class="py-0" v-else>
          <v-icon small color="red">cancel</v-icon>
        </v-col>
        <v-col cols="9" class="pa-0">
          <div class="caption" v-for="(message, i) in messages" :key="i">{{message}}</div>
        </v-col>
      </v-row>
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="isShowSnackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- 需要确认的提示框 dialog 控制变量为 isShowDialog-->
    <v-dialog v-model="isShowDialog" max-width="500px">
      <v-card clas="text-center">
        <v-card-title class="dialog-text justify-center">{{dialogMessage}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="closeDelete">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="deleteItemConfirm">确认</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 卡片: 上传图片 -->
    <v-card>
      <v-card-text>
        <v-file-input class="mr-8" label="上传图片" filled multiple small-chips prepend-icon="mdi-camera"
                      accept="image/png, image/jpeg, image/bmp, image/jpg" v-model="images"
                      @change="previewImage"></v-file-input>
        <v-card flat class="d-flex flex-column justify-space-between" v-if="images.length">
          <v-row align="start" class="mx-5">
            <v-col v-for="(image, index) in images" :key="index" class="text-center" cols="2">
              <div>
                <v-img :src="preview(image)" contain></v-img>
              </div>
              <div class="image-name">{{image.name}}</div>
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on" @click="deletePreviewImage(image)">mdi-close</v-icon>
                  </template>
                  <span>取消上传</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <v-row justify="center">
          <v-btn color="primary" dark class="my-6" @click="uploadImage" :loading="isSubmitting" v-if="images.length">
            确认上传
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>


    <!-- 卡片: 图片预览 -->
    <v-card :loading="isLoading" class="my-2">
      <template slot="progress">
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
      </template>
      <v-card-text>
        <v-card flat class="d-flex flex-column justify-space-between">
          <v-row align="start" class="mx-6">
            <v-col v-for="(image, index) in imageSaved" :key="index" class="text-center" cols="2">
              <div>
                <v-img :src="image.url" contain></v-img>
              </div>
              <div class="mt-2">
                <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <a class="a-item " v-bind="attrs" v-on="on" :href="image.url" target="_blank">
                    {{image.filename}}
                  </a>
                </template>
                <span class="caption">打开链接</span>
              </v-tooltip>
              </div>
              <div class="caption grey--text font-weight-light">{{image.size_}}</div>
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on" @click="deleteImage(image)">mdi-delete</v-icon>
                  </template>
                  <span class="caption">删除照片</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card flat class="caption grey--text text-center" v-if="!imageSaved.length">无图片记录</v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: "consolePrivateImage",
    data() {
      return {
        // 页面状态控制变量
        isShowSnackbar: false,
        isShowDialog: false,
        isLoading: false,
        isSubmitting: false,
        isSubmitSuccess: false,
        dialogMessage: "", // dialog中显示的message
        messages: [], // snackbar中显示的message


        images: [], // 存放的上传图片
        urls: [],

        // 已有图片信息
        imageSaved: [],
        loadedState: false,

        // 当前编辑对象
        editedItem: null,
      }
    },
    methods: {
      // ----- 其他功能函数 -----
      previewImage() {
      },

      // 预览图片对象
      preview(image) {
        return URL.createObjectURL(image);
      },

      // 文件大小格式化
      formatSize(size, pointLength, units) {
        var unit;
        units = units || ['B', 'K', 'M', 'G', 'TB'];
        while ((unit = units.shift()) && size > 1024) {
          size = size / 1024;
        }
        return (unit === 'B' ? size : size.toFixed(pointLength === undefined ? 2 : pointLength)) + unit;
      },

      // 加载已有图片
      loadData() {
        this.loadedState = false;
        this.$http.get("/api/image/?username=" + localStorage.username,
          {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
            this.imageSaved = response.data;
            for (let i=0; i<this.imageSaved.length; i++) {
              this.imageSaved[i].size_ = this.formatSize(this.imageSaved[i].size, 1, ['B', 'K', 'M', 'G', 'TB'])
            }
          })
      },

      // 上传图片
      uploadImage() {
        let formData = new FormData();
        formData.append('username', localStorage.username)
        formData.append('screenname', localStorage.name_zh)
        for (var i = 0; i < this.images.length; i++) {
          formData.append('files[' + i + ']', this.images[i]);
        }
        this.$http.post("/api/image/", formData,
          {headers: {"Authorization": "Token " + localStorage.token, 'content-type': 'multipart/form-data'}})
          .then(response => {
            if (response.data == "success") {
              this.isSubmitSuccess = true; // 显示成功图标
              this.isShowSnackbar = true; // 显示消息提示框
              this.messages = ["图片上传成功"];
              this.images = [];     // 清空预览界面
              this.loadData(); // 重新加载图片
            } else {
              this.isSubmitSuccess = false; // 显示成功图标
              this.isShowSnackbar = true; // 显示消息提示框
              this.messages = ["出错啦"];
            }
          })
      },

      deletePreviewImage(image) {
        this.$delete(this.images, this.images.indexOf(image));
      },

      deleteItemConfirm() {
        this.$http.delete("/api/image/?iid=" + this.editedItem.iid,
        {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
            this.isShowDialog = false;
            if (response.data == "success") {
              this.isSubmitSuccess = true; // 显示成功图标
              this.isShowSnackbar = true; // 显示消息提示框
              this.messages = ["删除图片成功"];
              this.loadData();
            }
          })
      },

      // 界面按钮功能
      deleteImage(image) {
        this.dialogMessage = "确认删除";
        this.isShowDialog = true;
        this.editedItem = image;
      },

      closeNotice() {
        this.dialogNotice = false;
        this.messages = [];
      },

      closeDelete() {
        this.isShowDialog = false;
      },

    },

    mounted() {
      this.loadData();
    },
  }
</script>

<style scoped>
  .image-name {
    font-size: 12px;
    font-weight: 300;
    color: darkgray;
    line-height: 16px;
    margin-top: 4px;
  }

  .image-card {
    border-radius: 10px;
  }

  .a-item {
    font-size: 12px;
    text-decoration: none;
    color: rgb(176, 203, 216);
  }

  .a-item:hover {
    text-decoration: none;
    color: rgb(25, 118, 210);
  }

</style>