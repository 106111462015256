<template>
  <v-navigation-drawer v-model="currentDrawer" :fixed="currentMobile" :temporary="currentMobile"
                       :permanent="!currentMobile" app>
    <v-list nav dense class="mb-4">
      <v-list-item>
        <div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" href="/" target="_self">
                <v-icon>home</v-icon>
              </v-btn>
            </template>
            <span>回到首页</span>
          </v-tooltip>
        </div>
        <v-spacer></v-spacer>

        <div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" to="/console/#" target="_self">
                <v-icon>view_array</v-icon>
              </v-btn>
            </template>
            <span>后台面板</span>
          </v-tooltip>
        </div>

        <v-spacer></v-spacer>
        <div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="logout()">
                <v-icon>exit_to_app</v-icon>
              </v-btn>
            </template>
            <span>退出登录</span>
          </v-tooltip>
        </div>
      </v-list-item>
    </v-list>

    <!-- 头像及用户信息 -->
    <v-layout column align-center class="my-5 text-center">
      <v-flex>
        <v-avatar size="100" class="elevation-10">
          <v-img :src="portrait_url + '?' + new Date().getTime()"></v-img>
        </v-avatar>
        <p class="grey--text font-weight-boldsubtitle mt-6">
          {{ name }}
        </p>
        <p class="grey--text caption">
          {{ authority }}
        </p>
      </v-flex>
    </v-layout>
    <!-- 功能选项 -->
    <v-list nav dense>
      <v-list-item link to="/console/people" v-if="authority=='管理员'">
        <v-list-item-icon>
          <v-icon>mdi-account-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>人员管理</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/console/article">
        <v-list-item-icon>
          <v-icon>post_add</v-icon>
        </v-list-item-icon>
        <v-list-item-title>稿件管理</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/console/publication">
        <v-list-item-icon>
          <v-icon>insert_chart</v-icon>
        </v-list-item-icon>
        <v-list-item-title>发表管理</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/console/news" v-if="authority=='管理员'">
        <v-list-item-icon>
          <v-icon>campaign</v-icon>
        </v-list-item-icon>
        <v-list-item-title>新闻管理</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/console/seminar" v-if="authority=='管理员'">
        <v-list-item-icon>
          <v-icon>emoji_people</v-icon>
        </v-list-item-icon>
        <v-list-item-title>讲座管理</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/console/data">
        <v-list-item-icon>
          <v-icon>storage</v-icon>
        </v-list-item-icon>
        <v-list-item-title>数据集合</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/console/info">
        <v-list-item-icon>
          <v-icon>dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-title>常用信息</v-list-item-title>
      </v-list-item>

    </v-list>

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item link to="/console/profile">
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-title>个人信息</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/console/private_data">
        <v-list-item-icon>
          <v-icon>view_stream</v-icon>
        </v-list-item-icon>
        <v-list-item-title>个人数据</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/console/private_image">
        <v-list-item-icon>
          <v-icon>insert_photo</v-icon>
        </v-list-item-icon>
        <v-list-item-title>图床工具</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/console/private_time">
        <v-list-item-icon>
          <v-icon>today</v-icon>
        </v-list-item-icon>
        <v-list-item-title>时间管理</v-list-item-title>
      </v-list-item>
    </v-list>
    <!-- 放到末尾的 -->
    <template v-slot:append>
    </template>

  </v-navigation-drawer>
</template>

<script>
  export default {
    name: "consoleNav",
    props: ["isMobile", "isChangeDrawer"],
    data() {
      return {
        name: "匿名用户",
        authority: "普通用户",
        portrait_url: "/resource/public/portrait/default.png",
        currentDrawer: false,
        currentMobile: this.isMobile,
      }
    },
    methods: {
      logout() {
        localStorage.clear();
        window.open("/", "_self");
      },
      changeDrawer() {
        this.$emit('changeDrawer', this.currentDrawer)
      }
    },
    mounted() {
      if (localStorage.is_login) {
        this.name = localStorage.name_zh;
        this.authority = localStorage.authority;
        this.portrait_url = localStorage.portrait_url;
      }
    },
    watch: {
      isChangeDrawer: {
        handler: function (val, oldVal) {
          console.log(val);
          this.currentDrawer = !this.currentDrawer;
        },
      },
      isMobile: {
        handler: function (val, oldVal) {
          console.log("change mobile", this.isMobile);
          this.currentMobile = !this.currentMobile;
        },
        deep: true,
      },
      currentDrawer: {
        handler: function (val, oldVal) {
          console.log("change drawer", this.currentDrawer);
        },
        deep: true,
      }
    },
  }
</script>

<style scoped>

</style>