<template>
  <v-container>
    <!-- 无需确认的提示框 snackbar 控制变量为 isShowSnackbar -->
    <v-snackbar top text color="primary" :timeout="2000" v-model="isShowSnackbar">
      <v-row align="center" justify="space-between">
        <v-col cols="1" class="pl-2 py-0" v-if="isSubmitSuccess">
          <v-icon small color="green">check_circle</v-icon>
        </v-col>
        <v-col class="py-0" v-else>
          <v-icon small color="red">cancel</v-icon>
        </v-col>
        <v-col cols="9" class="pa-0">
          <div class="caption" v-for="(message, i) in messages" :key="i">{{message}}</div>
        </v-col>
      </v-row>
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="isShowSnackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- 需要确认的提示框 dialog 控制变量为 isShowDialog-->
    <v-dialog v-model="isShowDialog" max-width="500px">
      <v-card clas="text-center">
        <v-card-title class="dialog-text justify-center">{{dialogMessage}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="closeDelete">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="deleteItemConfirm">确认</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 需要确认的提示框 dialog 控制变量为 isShowDialogRelease-->
    <v-dialog v-model="isShowDialogRelease" max-width="500px">
      <v-card clas="text-center">
        <v-card-title class="dialog-text justify-center">{{dialogMessage}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="closeRelease">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="releaseItemConfirm">确认</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 图片上传对话框 -->
    <my-upload field="img" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess"
               @crop-upload-fail="cropUploadFail" v-model="isShowUpload" noSquare noCircle
               :width="800" :height="450" url="" :params="imgParams" :headers="imgHeaders" img-format="png">
    </my-upload>

    <!-- 全部人员数据表格 -->
    <v-card>
      <v-card-text>
        <v-btn depressed class="mb-4" @click="addItem()">
          <v-icon left>add</v-icon>
          <span>添加稿件</span>
        </v-btn>
        <v-data-table :headers="headers" :items="items" :sort-by="['aid']"
                      :sort-desc="[true]"
                      :page.sync="page" :items-per-page="itemsPerPage" class="elevation-1" hide-default-footer
                      @page-count="pageCount = $event" :loading="isLoading">

          <!-- 表格中的操作按钮 -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mx-1" v-bind="attrs" v-on="on" small @click="editItem(item)">mdi-pencil</v-icon>
              </template>
              <span>编辑</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mx-1" v-bind="attrs" v-on="on" small @click="deleteItem(item)">mdi-delete</v-icon>
              </template>
              <span>删除</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!item.release">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="red" class="mx-1" v-bind="attrs" v-on="on" small
                        @click="releaseItem(item)">launch
                </v-icon>
              </template>
              <span>发布</span>
            </v-tooltip>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="light-green" class="mx-1" v-bind="attrs" v-on="on" small
                        @click="unreleaseItem(item)">launch
                </v-icon>
              </template>
              <span>取消发布</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <!-- 表格翻页按钮 -->
        <v-card flat class="text-center my-3">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
          <!-- <v-text-field :value="itemsPerPage" label="Items per page" type="number" min="0" max="12"
                        @input="itemsPerPage = parseInt($event, 10)"></v-text-field> -->
        </v-card>
      </v-card-text>
    </v-card>

    <!-- 新增/编辑人员信息表单 -->
    <v-card v-if="isShowEditor" class="mt-3">
      <v-card-text>
        <!-- 表单首行 -->
        <v-row align="center">
          <v-card-title>
            <v-card flat>{{ formTitle }}</v-card>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="isShowEditor=false" class="mr-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider class="my-4"></v-divider>
        <!-- 表单填写部分 -->
        <v-form ref="form">
          <!-- 文章标题简介 -->
          <v-text-field filled label="标题 Title" :rules="[rules.required]" value=""
                        v-model="editedItem.title"></v-text-field>
          <v-row justify="space-between">
            <v-col cols="7" class="py-0">
              <v-textarea filled label="摘要 Abstract" value="" :rules="[rules.required]" v-model="editedItem.abstract"
                          rows="6"></v-textarea>
            </v-col>
            <v-col cols="5" class="py-0">
              <v-row class="fill-height" justify="center">
                <v-card class="text-center" height="192" width="342" @click="isShowUpload=true">
                  <v-row class="fill-height" justify="center" align="center">
                    <v-col class="py-0" v-if="!isCropSuccess & !editedItem.image_url">
                      <div class="subtitle-1 grey--text">
                        <v-icon left>upload</v-icon>
                        <span>上传封面</span>
                      </div>
                      <div class="caption grey--text">
                        ( 图片长宽至少为 800*450 像素 )
                      </div>
                    </v-col>
                    <v-col class="py-0" v-else>
                      <v-img height="192" class="d-flex justify-end image-article"
                             :src="editedItem.image_url" v-if="isCropSuccess"></v-img>
                      <v-img height="192" class="d-flex justify-end image-article"
                             :src="editedItem.image_url+'/?'+new Date().getTime()" v-else></v-img>
                    </v-col>
                  </v-row>
                </v-card>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="my-4"></v-divider>
          <!-- 表单选项区域 -->
          <v-row style="margin-top: -20px; z-index: 999;">
            <v-col md="4">
              <v-select offset-y v-model="editedItem.type" :items="typeAll" label="类型 Type">
                <template v-slot:selection="{ item }">
                  <v-chip small dark class="primary">
                    <span>{{ item }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col md="4">
              <v-select multiple offset-y v-model="editedItem.authors" :items="people" item-text="name_zh"
                        label="供稿人 Authors">
                <template v-slot:selection="{ item }">
                  <v-chip small dark class="primary">
                    <span>{{ item.name_zh }}</span>
                  </v-chip>
                </template>
              </v-select>
              <!--              <v-select :options="people" :reduce="item => item.name_zh" label="供稿人 Authors"></v-select>-->
            </v-col>
            <v-col md="4">
              <v-select multiple disabled offset-y v-model="editedItem.editors" :items="people" item-text="name_zh"
                        label="编辑 Editors">
                <template v-slot:selection="{ item }">
                  <v-chip small dark class="primary">
                    <span>{{ item.name_zh }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>


          </v-row>
          <!-- 时间显示界面 -->
          <v-row>
            <v-col cols="4">
              <v-text-field disabled append-icon="mdi-clock-time-four-outline" label="创建时间 Establish Time" value=""
                            v-model="editedItem.timestr1"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field disabled append-icon="mdi-clock-time-four-outline" label="最近编辑时间 Edit Time" value=""
                            v-model="editedItem.timestr2"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field disabled append-icon="mdi-clock-time-four-outline" label="最近发布时间 Announce Time" value=""
                            v-model="editedItem.timestr3"></v-text-field>
            </v-col>
          </v-row>
          <!-- markdown 编辑界面 -->
          <v-card flat class="mt-10" id="editor-main" height="600">
            <v-md-editor v-model="editedItem.markdown" height="600px" :left-toolbar="leftToolbar"></v-md-editor>
          </v-card>
        </v-form>
        <!-- 表单按钮区域 -->
        <v-row justify="center">
          <v-col md="2">
            <v-btn color="primary" dark block class="my-2" @click="submit" :loading="isSubmitting">保 存</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import myUpload from 'vue-image-crop-upload';

  export default {
    name: "consoleArticle",
    components: {"my-upload": myUpload},
    data() {
      return {
        // 页面状态控制变量
        isShowSnackbar: false,
        isShowDialog: false,
        isShowDialogRelease: false,
        isShowEditor: false,
        isLoading: false,
        isSubmitting: false,
        isSubmitSuccess: false,
        isCropSuccess: false, // 图片剪切是否成功
        dialogMessage: "", // dialog中显示的message
        messages: [], // snackbar中显示的message
        people: [], // 选项内可选人员
        peopleArray: [], // 人员index数组

        // 表格数据
        page: 1, // 默认所在页数
        pageCount: 0,
        itemsPerPage: 10,
        loadingData: true,
        headers: [
          // {text: '序号', sortable: true, value: 'pid', width: "10%"},
          {text: '类型', value: 'type'},
          {text: '创建时间', value: 'timestr1'},
          {text: '标题', value: 'title', width: "35%"},
          {text: '供稿人', value: 'authors'},
          // {text: '英文名', value: 'name_en'},
          {text: '编辑', value: 'editors'},
          {text: '状态', value: 'releasestr'},
          {text: '操作', value: 'actions', sortable: false, align: 'center', width: "15%"},
        ],
        items: [], // 通过api获取初始化数据
        editedIndex: -1,

        // 表单数据选项
        typeAll: this.$store.state.article.typeAllZh,
        authorAll: Array.from({length: 10}, (v, k) => k),
        editorAll: [],

        // 表单密码输入区域
        pwShow: false,
        pwShow2: false,
        rules: {
          required: value => !!value || '必填',
          min: v => v.length >= 8 || '最少8位密码',
          match: v => v == this.editedItem.password || '两次密码输入需保持一致',
        },

        // 表单图片上传
        isShowUpload: false,
        imgParams: {token: '123456798', name: 'avatar'},
        imgHeaders: {smail: '*_~'},

        // 当前编辑的数据
        editedItem: {},
        // 默认的空白编辑数据, 便于编辑已有数据后再返回空白
        defaultItem: {
          aid: -1,
          release: false,
          type: "",
          type_num: -1,
          title: "",
          abstract: "",
          image_url: "",
          authors_index: [],
          authors: [],
          editors: [],
          markdown: "",
          time1: 0, // 创建时间戳
          time2: 0, // 编辑时间戳
          time3: 0, // 发布时间戳
          timestr1: null,
          timestr2: null,
          timestr3: null,
        },
        // markdown区域参数
        leftToolbar: "undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image code | emoji todo-list tip",
      }
    },

    methods: {
      test() {
        console.log(this.editedItem);
      },

      convertTime(timeStamp) {
        if (timeStamp) {
          var timeStampObj = new Date(timeStamp)
          return timeStampObj.toLocaleDateString().replace(/\//g, "-") + " " + timeStampObj.toTimeString().substr(0, 8);
        } else {
          return null;
        }
      },

      // ----- 按钮功能 -----
      // 添加按钮功能
      addItem() {
        this.isShowEditor = true; // 显示编辑界面
        this.editedItem = Object.assign({}, this.defaultItem); // 初始化空编辑对象
      },

      // 编辑按钮功能
      editItem(item) {
        this.isShowEditor = true;
        this.editedItem = Object.assign({}, item);
      },

      // 删除按钮功能
      deleteItem(item) {
        this.dialogMessage = "确认删除";
        this.isShowDialog = true;
        this.editedItem = Object.assign({}, item);
      },

      // 发布按钮功能
      releaseItem(item) {
        this.dialogMessage = "确认发布";
        this.isShowDialogRelease = true;
        this.editedItem = Object.assign({}, item);
      },

      // 取消发布按钮功能
      unreleaseItem(item) {
        this.dialogMessage = "取消发布";
        this.isShowDialogRelease = true;
        this.editedItem = Object.assign({}, item);
      },

      // 关闭删除界面
      closeDelete() {
        this.isShowDialog = false;
      },

      closeRelease() {
        this.isShowDialogRelease = false;
      },

      // ----- 后端交互功能 -----
      // 加载数据
      loadData() {
        this.isLoading = true;
        this.$http.get("/api/read_people/?onlylist=1")
          .then(response => {
            this.people = response.data;
            for (let i = 0; i < this.people.length; i++) {
              this.people[i].order = i;
            }
            this.peopleArray = Array.from({length: this.people.length}, (v, k) => k);
          })
        this.$http.get("/api/article/", {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
              this.items = response.data;
              for (let i = 0; i < this.items.length; i++) {
                this.items[i].timestr1 = this.convertTime(this.items[i].time1);
                this.items[i].timestr2 = this.convertTime(this.items[i].time2);
                this.items[i].timestr3 = this.convertTime(this.items[i].time3);
                this.items[i].releasestr = this.items[i].release ? "已发布" : "未发布";
              }
              this.isLoading = false;
            }
          )
      },

      // 提交数据
      submit() {
        if (this.$refs.form.validate()) { // 表单数据合格开始上传
          this.isSubmitting = true; // 显示按钮上传状态
          if (this.editedItem.editors.indexOf(localStorage.name_zh) == -1) { // 若不存在则，加入编辑名单中
            this.editedItem.editors.push(localStorage.name_zh);
          }
          this.editedItem.type_num = this.typeAll.indexOf(this.editedItem.type); // 得到type_num的值 (便于排序)
          if (this.editedItem.time1 == 0) {
            this.editedItem.time1 = new Date().getTime(); // 创建时间
          }
          this.editedItem.time2 = new Date().getTime(); // 记录编辑时间
          console.log(this.editedItem);

          var formData = new FormData(); //创建表单数据
          for (let key in this.editedItem) {
            formData.append(key, this.editedItem[key])
          }
          console.log(this.editedItem);
          if (this.editedItem.aid == -1) {
            // 对于新增的数据 使用POST请求

            this.$http.post("/api/article/", formData,
              {headers: {"Authorization": "Token " + localStorage.token, 'content-type': 'multipart/form-data'}})
              .then(response => {
                console.log(response.data);
                this.isSubmitting = false; // 收到响应关闭按钮上传状态
                if (response.data == "success") {
                  this.messages = ["保存成功"]
                  this.isSubmitSuccess = true; // 显示成功图标
                  this.isShowSnackbar = true; // 显示消息提示框
                  this.isShowEditor = false;  // 关闭编辑界面
                  this.loadData();     // 重新加载数据
                } else {
                  this.isSubmitSuccess = false; // 显示失败图标
                  this.messages = []; // 清空消息缓存
                  var errors = response.data;
                  for (let key in errors) {
                    this.messages.push(key + ": " + errors[key])
                  }
                  this.isShowSnackbar = true;
                }
              })
          } else {
            // 对于修改的数据 使用PUT请求
            this.$http.put("/api/article/?aid=" + this.editedItem.aid, formData,
              {headers: {"Authorization": "Token " + localStorage.token, 'content-type': 'multipart/form-data'}})
              .then(response => {
                this.isSubmitting = false; // 收到响应关闭按钮上传状态
                console.log(response.data)
                if (response.data == "success") {
                  this.messages = ["保存成功"]
                  this.isSubmitSuccess = true; // 显示成功图标
                  this.isShowSnackbar = true; // 显示消息提示框
                  this.isShowEditor = false;  // 关闭编辑界面
                  this.loadData();      // 重新加载数据
                } else {
                  this.isSubmitSuccess = false; // 显示失败图标
                  this.messages = [];   // 清空消息缓存
                  var errors = response.data;
                  for (let key in errors) {
                    this.messages.push(key + ": " + errors[key])
                  }
                  this.isShowSnackbar = true;
                }
              })
          }
        }
      },

      // 确认删除数据
      deleteItemConfirm() {
        this.$http.delete("/api/article/?aid=" + this.editedItem.aid,
          {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
            this.messages = ["删除成功"];
            this.isSubmitSuccess = true; // 显示成功图标
            this.isShowDialog = false;  // 关闭删除提示框
            this.isShowSnackbar = true; // 进行消息提示
            this.loadData();            // 重新加载数据
          })
      },

      // 确认发布数据
      releaseItemConfirm() {
        this.editedItem.release = !this.editedItem.release;
        this.editedItem.releasestr = this.editedItem.release ? "已发布" : "未发布";
        if (this.editedItem.release) {
          this.editedItem.time3 = new Date().getTime();
        }
        var formData = new FormData(); //创建表单数据
        formData.append("release", this.editedItem.release);
        formData.append("time3", this.editedItem.time3);
        this.$http.put("/api/article/?aid=" + this.editedItem.aid, formData,
          {headers: {"Authorization": "Token " + localStorage.token, 'content-type': 'multipart/form-data'}})
          .then(response => {
            this.isSubmitting = false; // 收到响应关闭按钮上传状态
            console.log(response.data)
            if (response.data == "success") {
              this.messages = [this.dialogMessage + "成功"];
              this.isSubmitSuccess = true; // 显示成功图标
              this.isShowSnackbar = true; // 显示消息提示框
              this.isShowDialogRelease = false; // 关闭发布界面
              this.loadData();      // 重新加载数据
            }
          })
      },

      // 插件功能
      // 图片上传
      cropSuccess(imgDataUrl, field) {
        console.log('-------- crop success --------');
        this.isCropSuccess = true;
        this.editedItem.image_url = imgDataUrl;
      },
      cropUploadSuccess(jsonData, field) {
        console.log('-------- upload success --------');
        console.log(jsonData);
        console.log('field: ' + field);
      },
      cropUploadFail(status, field) {
        console.log('-------- upload fail --------');
        console.log(status);
        console.log('field: ' + field);
      },

    }
    ,
    computed: {
      formTitle() {
        return this.editedItem.pid === -1 ? '添加稿件' : '编辑稿件 ' + this.editedItem.title;
      }
      ,
      computePortraitUrl() {
        if (this.editedItem.image_url[0] == "/") {
          return this.editedItem.image_url + "?" + new Date().getTime();
        } else {
          return this.editedItem.image_url;
        }
      }
    }
    ,
    mounted() {
      this.loadData();
      // console.log(Array.from({length: 10}, (v, k) => k));
    }
    ,
  }
</script>

<style scoped>
  .image-article {
    /*position: absolute;*/
    /*z-index: -100;*/
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

</style>