<template>
  <v-container>
    <v-card-title class="py-md-6 pt-1 pb-0">
                  DISCOpen 开源项目
    </v-card-title>
    <v-card-text>
      DISCOpen 是由复旦大学数据智能与社会计算实验室（Fudan-DISC）发起的开源项目。本项目在实验室研究工作的基础上，以课题方向作为基本单位，针对研究型代码进行二次开发，旨在为相关方向的学术研究提供便利的实验环境，为下游企业的应用研发提供接口演示和原型代码。希望在鞭笞课题组进步的同时，可以为学术社区做一些微薄的贡献，助力产学研结合的发展。
      <br/>
    </v-card-text>
    <v-list dense>
      <v-list-item  class="py-5" >
        <v-hover  v-slot="{ hover }">
          <v-card href="http://www.fudan-disc.com/news/26" width="100%" rounded="0" :elevation="hover ? 16 : 4" :class="{'on-hover': hover, 'transition-swing':true}"  target="_blank">
          <v-row>
                <v-col class="py-0" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                  <v-img contain :src="'/resource/public/project/OpenMedBox.png'"></v-img>
                </v-col>
                <v-col class="py-0" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-card-title class="py-md-6 pt-1 pb-0">
                    对话诊断强化学习框架 OpenMedBox
                  </v-card-title>
                  <v-card-text>
                    针对自动医疗诊断领域目前各方法均未提供可复现源码，成型数据集难以寻找的现状。复旦大学数据智能与社会计算实验室（Fudan DISC）推出自动医疗诊断强化学习开源项目--OpenMedBox-DialoDiagnosis，旨在帮助进行相关研究的同行更容易的对该领域工作进行复现和扩展。
                    <br/><br/>
                    项目 Github 地址：<a href="https://github.com/FudanDISC/DISCOpen-MedBox">https://github.com/FudanDISC/DISCOpen-MedBox</a>
                  </v-card-text>
                </v-col>
              </v-row>
        </v-card>
        </v-hover>
      </v-list-item>
      <v-list-item  class="py-5" >
        <v-hover  v-slot="{ hover }">
          <v-card href="http://www.fudan-disc.com/news/25" width="100%" rounded="0" :elevation="hover ? 16 : 4" :class="{'on-hover': hover, 'transition-swing':true}"  target="_blank">
          <v-row>
                <v-col class="py-0" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                  <v-img contain :src="'/resource/public/project/OpenLanVis-MVPTR.png'"></v-img>
                </v-col>
                <v-col class="py-0" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-card-title class="py-md-6 pt-1 pb-0">
                    视觉语言预训练模型 OpenLanVis-MVPTR
                  </v-card-title>
                  <v-card-text>
                    基于多层次语义对齐的多阶段视觉-语言预训练模型 MVPTR 能为图片-文本对产生泛化性的表征以用于下游任务。之前的视觉语言预训练模型在使用时大多需要使用者理解背后复杂的数据预处理、输入构建等逻辑，为了方便下游任务的使用，我们从不同角度出发，提供了简化且易用的数据预处理工具，以及可以作为黑盒使用的整合 pipeline ，仅需两到三行代码就可以将 MVPTR 应用于视觉语言任务。
                    <br/><br/>
                    项目 Github 地址：<a href="https://github.com/FudanDISC/DISCOpen-MVPTR">https://github.com/FudanDISC/DISCOpen-MVPTR</a>
                  </v-card-text>


                </v-col>
              </v-row>
        </v-card>
        </v-hover>
      </v-list-item>
  </v-list>
  </v-container>
</template>

<script>
  export default {
    name: "indexDISCOpen"
  }
</script>

<style scoped>
  .v-card {
    transition: opacity .8s ease-in-out;
  }

  .v-card:not(.on-hover) {
    opacity: 0.7;
  }

</style>