module.exports = {
  menu: {
    home: "首页",
    news: "讯息",
    people: "成员",
    publication: "发表",
    curriculum:"课程",
    sharedtask:"评测",
    DISCOpen:"DISCOpen",
    login: "登录",
    language: "切换语言",
    portrait1: "后台管理",
    portrait2: "退出登录",
  },
  home: {
    about: "课题组简介",
    news: "最新动态",
    seminar: "讲座讯息",
    seminar_content: {
      speaker: "讲者",
      host: "主持人",
      time: "时间",
      id: "号",
      pw: "入会密码",
      live_link: "直播地址",
    },
    works: "亮点工作",
    works_annotation: {
      title: "数据标注",
      intro: "Fudan DISC 借助交叉学科优势，考虑自然语言处理技术的众多应用场景，对问答推理、医疗对话、舆情传播相关的领域数据构建了可计算化框架，完成了相应数据标注，进一步推动数据赋能。",
    },
    works_discopen: {
      title: "DISCOpen 开源项目",
      intro: "DISCOpen 在实验室研究工作的基础上，以课题方向作为基本单位，针对研究型代码进行二次开发，旨在为相关方向的学术研究提供便利的实验环境，为下游企业的应用研发提供接口演示和原型代码。",
    }
  },
  footer:{
    name: "复旦大学数据智能与社会计算实验室",
    location: "上海市杨浦区新金博大厦",
    wechat: "扫码关注Fudan DISC公众号",
  },
  content: {
    main: "这里是内容"
  },
  login: {
    home: "回到首页",
    title: "Fudan DISC",
    username: "学工号",
    password: "密码",
    btn: "登 录",
    inexistence: "该用户不存在",
    fail: "密码错误",
    success: "登录成功",
    forget: "忘记密码？",
    forgetInfo: "初始密码为姓名拼音 (不足 8 位的部分用 0 补齐)，如已更改密码，请联系管理员。",
  },
  publication: {
    pdf: "PDF",
    slides: "演示文稿",
    dataset: "数据集",
    code: "代码",
    video: "视频讲解",
    wechat: "微信文章",
    filterArea: "研究方向",
  },
  people: {
    current: "在组成员",
    former: "曾经在组",
    teacher: "教师团队",
    titles: {0:"博士后", 1:"博士生", 2:"硕士生", 3:"本科生"},
    titlesFormer: {0:"博士后", 1:"博士生", 2:"硕士在组", 3:"本科在组"},
    postdoc: "博士后",
    phd: "博士生",
    master: "硕士生",
    undergraduate: "本科生"
  }
}