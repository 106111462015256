<template>
<v-container>
  <v-list>
    <v-list-item  class="py-5" >
      <v-hover v-slot="{ hover }">
        <v-card href="http://fudan-disc.com/sharedtask/AIDebater22/index.html" width="100%" rounded="0" :elevation="hover ? 16 : 4" :class="{'on-hover': hover, 'transition-swing':true}"  target="_blank">
      <v-row>
              <v-col class="py-0" cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
              </v-col>  
              <v-col class="py-0" cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                <v-card-title class="py-md-6 pt-1 pb-0">
                  第二届智慧论辩评测比赛（中国情感计算大会）
                </v-card-title>
                <v-card-text>
                  AI-Debater Shared Tasks, CCAC 2022 
                </v-card-text>
              </v-col>
              <v-col class="py-0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                <v-card-title right class="py-md-6 mt-md-1 pt-0 pb-1 caption font-weight-light">
                  2022-6-7至2022-8-20
                </v-card-title>
              </v-col>
      </v-row>
      </v-card>
      </v-hover>
    </v-list-item>

    <v-list-item  class="py-5" >
      <v-hover  v-slot="{ hover }">
        <v-card href="http://fudan-disc.com/sharedtask/social22" width="100%" rounded="0" :elevation="hover ? 16 : 4" :class="{'on-hover': hover, 'transition-swing':true}" target="_blank">
      <v-row>              
              <v-col class="py-0" cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
              </v-col>  
              <v-col class="py-0" cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                <v-card-title class="py-md-6 pt-1 pb-0">
                  上海-香港跨学科社交媒体分析联合评测 
                </v-card-title>
                <v-card-text>
                  Shanghai-HK Interdisciplinary Shared Tasks, 2022 
                </v-card-text>
              </v-col>
              <v-col class="py-0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                <v-card-title right class="py-md-6 mt-md-1 pt-0 pb-1 caption font-weight-light">
                  2022-3-7至2022-6-8
                </v-card-title>
              </v-col>
            </v-row>
      </v-card>
      </v-hover>
    </v-list-item>


  </v-list>
</v-container>
</template>

<script>
  export default {
    name: "indexSharedTask",
}
</script>

<style scoped>
  .v-card {
    transition: opacity .8s ease-in-out;
  }

  .v-card:not(.on-hover) {
    opacity: 0.7;
  }

</style>