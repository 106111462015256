import Vue from 'vue'
import VueRouter from 'vue-router'
import indexEntry from "./components/index/indexEntry";
import indexHome from "./components/index/indexHome";
import indexNews from "./components/index/indexNews";
import indexPeople from "./components/index/indexPeople";
import indexPublication from "./components/index/indexPublication";
import indexDISCOpen from "./components/index/indexDISCOpen";
import indexCurriculum from "./components/index/indexCurriculum";
import indexSharedTask from "./components/index/indexSharedTask";


import pagePeople from "./components/index/pagePeople";
import pageArticle from "./components/index/pageArticle";
import pageBlog from "./components/index/pageBlog";
import pageIntro2ai from "./components/index/pageIntro2ai";
import pageIntro2nlp from "./components/index/pageIntro2nlp";

import consoleEntry from "./components/console/consoleEntry";
import consoleHome from "./components/console/consoleHome";
import consoleArticle from "./components/console/consoleArticle";
import consolePeople from "./components/console/consolePeople";
import consoleData from "./components/console/consoleData";
import consoleNews from "./components/console/consoleNews";
import consoleSeminar from "./components/console/consoleSeminar";
import consoleInfo from "./components/console/consoleInfo";
import consolePublication from "./components/console/consolePublication";
import consolePrivateProfile from "./components/console/consolePrivateProfile";
import consolePrivateImage from "./components/console/consolePrivateImage";
import consolePrivateData from "./components/console/consolePrivateData";
import consolePrivateTime from "./components/console/consolePrivateTime";

import login from "./components/login/login";

import lchenEntry from "./components/lchen/lchenEntry";
import lchenPingan from "./components/lchen/lchenPingan";
import lchenInfo from "./components/lchen/lchenInfo";

Vue.use(VueRouter)

const routes = [
  {
    path: "/", component: indexEntry,
    children: [
      {path: "", name: "indexHome", component: indexHome},
      {path: "news", name: "indexNews", component: indexNews},
      {path: "people", name: "indexPeople", component: indexPeople},
      {path: "publication", name: "indexPublication", component: indexPublication},
      {path: "DISCOpen", name: "indexDISCOpen", component: indexDISCOpen},
      {path: "curriculum", name: "indexCurriculum", component: indexCurriculum},
      {path: "SharedTask", name: "indexSharedTask", component: indexSharedTask},

      {path: "/curriculum/Intro-to-AI", name: "pageIntro2ai", component: pageIntro2ai},
      {path: "/curriculum/Intro-to-NLP", name: "pageIntro2nlp", component: pageIntro2nlp},
      {path: "/people/(\\w+)", name: "pagePeople", component: pagePeople},
      {path: "/news/(\\w+)", name: "pageArticle", component: pageArticle},
      {path: "/blog/(\\w+)", name: "pageBlog", component: pageBlog},
    ]
  },
  {path: "/login", name: "login", component: login},
  {
    path: "/console", component: consoleEntry,
    children: [
      {path: "", name: "consoleHome", component: consoleHome},
      {path: "people", name: "consolePeople", component: consolePeople},
      {path: "article", name: "consoleArticle", component: consoleArticle},
      {path: "publication", name: "consolePublication", component: consolePublication},
      {path: "data", name: "consoleData", component: consoleData},
      {path: "news", name: "consoleNews", component: consoleNews},
      {path: "seminar", name: "consoleSeminar", component: consoleSeminar},
      {path: "info", name: "consoleInfo", component: consoleInfo},
      {path: "profile", name: "consolePrivateProfile", component: consolePrivateProfile},
      {path: "private_image", name: "consolePrivateImage", component: consolePrivateImage},
      {path: "private_data", name: "consolePrivateData", component: consolePrivateData},
      {path: "private_time", name: "consolePrivateTime", component: consolePrivateTime},
    ],
    meta: {requireAuth: true, title: "Fudan DISC 后台管理"}, // 判断是否需要登录
  },
  {
    path:"/lchen", component: lchenEntry,
    children: [
      {path: "pingan", name: "lchenPingan", component: lchenPingan},
      {path: "info", name: "lchenInfo", component: lchenInfo},
    ],
    meta: {requireAuthlchen:true},
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 判断该路由是否需要登录权限
  if (to.matched.some(record => record.meta.requireAuth)) {
    console.log('需要登录');
    if (localStorage.is_login) { // 判断当前的token是否存在 ； 登录存入的token
      next();
    } else {
      next({
        path: '/login',
        query: {redirect: to.fullPath} // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  } else {
    next();
  }
  // 判断该路由是否需要登录权限
  if (to.matched.some(record => record.meta.requireAuthlchen)) {
    console.log('需要陈蕾登录');
    if (localStorage.username=="lchen") { // 判断当前的token是否存在 ； 登录存入的token
      next();
    } else {
      next({
        path: '/login',
        query: {redirect: to.fullPath} // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  } else {
    next();
  }
  // 判断是否需要更改标题
  if (to.matched.some(record => record.meta.title)) {
    document.title = to.matched[0].meta.title;
    next();
  } else {
    next();
  }
});

export default router