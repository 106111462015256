<template>
  <v-container>
    <!-- tab 界面 筛选可选数据 -->
    <v-card flat v-if="isLoaded">
      <v-card-text>
        <v-row align="center" justify="start">
          <v-col cols="12" xs="12" sm="12" md="1" xl="1" lg="1" class="text-center text-md-right text-xl-right text-lg-right font-weight-bold px-0">
            {{$t('publication.filterArea')}}
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="11" xl="11" lg="11">
            <v-btn :class="'mx-2 my-1 px-1 btn-filter-active-'+item.active" depressed v-for="(item, index) in areaBtns"
                   :key="index" @click="filter(item)">
              <span class="text-capitalize caption">{{computeAreaName(item.num)}}</span>
              <!--              <span class="text-capitalize">{{$store.state.people.areaAllZh[item.num]}}</span>-->
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>
    <!-- 发表物列表 -->
    <v-card :loading="isLoading">
      <v-card-text v-if="isLoaded">
        <v-list>
          <v-list-item v-for="(item, index) in itemsShow" :key="index" class="mb-6">
            <v-row align="start" justify="space-between">
              <v-col cols="12" xs="12" sm="12" md="1" xl="1" lg="1" class="py-0">
                <v-card flat class="subtitle-1 py-1 primary--text text-center text-md-right text-xl-right text-lg-right ">{{item.order}}</v-card>
              </v-col>
              <v-col cols="11" xs="11" sm="11" md="8" xl="8" lg="8" class="px-0 py-0">
                <v-card flat class="subtitle-1 py-1 font-weight-bold list-item-btn transition-swing" :href="item.link1"
                        target="_blank">
                  {{item.title}}
                </v-card>
                <div class="caption">{{item.author}}</div>
                <div class="caption grey--text lighten-3" v-if="$i18n.locale=='en'">{{ item.year}}, {{item.source}},
                  {{
                  $store.state.people.areaAllEn[item.area_num]}}
                </div>
                <div class="caption grey--text lighten-3" v-else>{{ item.year}}, {{item.source}}, {{
                  $store.state.people.areaAllZh[item.area_num]}}
                </div>
              </v-col>
              <v-spacer></v-spacer>

              <v-col cols="1" xs="1" sm="1" md="2" xl="2" lg="2" class="pl-md-10 pl-xl-10 pl-lg-10 pr-0 py-0">
                <v-row justify="start">
                  <v-tooltip top v-if="item.file1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on"
                             :href="'/resource/public/publication/'+item.pid+'/'+item.file1" target="_blank">
                        <v-icon small color="grey lighten-1">mdi-file</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t('publication.pdf')}}</span>
                  </v-tooltip>

                  <v-tooltip top v-if="item.file2">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on"
                             :href="'/resource/public/publication/'+item.pid+'/'+item.file2" target="_blank" >
                        <v-icon small color="red lighten-3">slideshow</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t('publication.slides')}}</span>
                  </v-tooltip>

                  <v-tooltip top v-if="item.file3">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on"
                             :href="'/resource/public/publication/'+item.pid+'/'+item.file3" target="_blank">
                        <v-icon small color="primary">view_module</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t('publication.dataset')}}</span>
                  </v-tooltip>

                  <v-tooltip top v-if="item.link3">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon class="wechat-logo" v-bind="attrs" v-on="on"
                             :href="item.link3" target="_blank" >
                        <v-icon>$vuetify.icons.wechat</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t('publication.wechat')}}</span>
                  </v-tooltip>

                  <v-tooltip top v-if="item.link2">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on"
                             :href="item.link2" target="_blank">
                        <v-icon>$vuetify.icons.github</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t('publication.code')}}</span>
                  </v-tooltip>

                  <v-tooltip top v-if="item.link4">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on"
                             :href="item.link4" target="_blank">
                        <v-icon>$vuetify.icons.video</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t('publication.video')}}</span>
                  </v-tooltip>
                </v-row>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
  export default {
    name: "indexPublication",
    data() {
      return {
        // 页面状态控制变量
        isLoading: false,      // 控制数据加载
        isLoaded: false, // 数据是否加载完全
        tab: 0, // 默认数据

        items: [],
        itemsShow: [],

        areaBtns: [{num: -1, active: true}],
        filterConditions: {
          area: [],
          source: [],
          year: [],
        },

      }

    },
    methods: {
      // ----- 按钮功能 -----
      filter(item) {
        item.active = !item.active;
        // 如果点击激活全部则将其他的都变为false
        if (item.active == true & item.num == -1) {
          for (let i = 1; i < this.areaBtns.length; i++) {
            this.areaBtns[i].active = false;
          }
        }
        this.filterShow();
      },

      filterShow() {
        // 更改按钮显示效果，如果除第1个元素其他都没有，则把all active改为true，否则改为false
        var filterArray = [];
        for (let i = 1; i < this.areaBtns.length; i++) {
          if (this.areaBtns[i].active) {
            filterArray.push(this.areaBtns[i].num)
          }
          if (filterArray.length) {
            this.areaBtns[0].active = false;
          } else {
            this.areaBtns[0].active = true;
          }
        }
        // 更改筛选条件
        var itemsTemp = [];
        if (filterArray.length) {
          for (let i = 0; i < this.items.length; i++) {
            if (filterArray.indexOf(this.items[i].area_num) != -1) {
              itemsTemp.push(this.items[i])
            }
          }
          for (let i = 0; i < itemsTemp.length; i++) {
            itemsTemp[i].order = itemsTemp.length - i;
          }
        } else {
          itemsTemp = this.items;
          for (let i = 0; i < itemsTemp.length; i++) {
            itemsTemp[i].order = itemsTemp.length - i;
          }
        }

        this.itemsShow = itemsTemp;
        console.log(this.itemsShow);
      },

      // ----- 后端交互功能 -----
      // 加载数据
      loadData() {
        var areaNumArray = [];
        this.isLoading = true;
        this.$http.get("/api/read_publication/")
          .then(response => {
            this.items = response.data;
            for (let i = 0; i < this.items.length; i++) {
              this.items[i].order = this.items.length - i;
              if (areaNumArray.indexOf(this.items[i].area_num) == -1) {
                areaNumArray.push(this.items[i].area_num);
              }
            }
            areaNumArray = areaNumArray.sort();
            for (let i = 0; i < areaNumArray.length; i++) {
              this.areaBtns.push({num: areaNumArray[i], active: false});
            }
            this.itemsShow = this.items; // 初始化显示数据为全部数据
            this.isLoading = false;
            this.isLoaded = true;
          })
      },

      // ----- 其他功能 -----
      computeAreaName(num) {
        if (num == -1) {
          if (this.$i18n.locale == 'en') {
            return "All"
          } else {
            return "全部"
          }
        } else {
          if (this.$i18n.locale == 'en') {
            return this.$store.state.people.areaAllEn[num];
          } else {
            return this.$store.state.people.areaAllZh[num];
          }
        }
      }

    },
    mounted() {
      this.loadData();
    },
  }
</script>

<style scoped>
  .list-item-btn {
    text-decoration: none;
    color: black;
    margin-right: 0rem;
    border-radius: 0 50px 50px 0;
  }

  .list-item-btn:hover {
    color: #113374;
    background-color: rgba(56, 191, 192, 0.1);
  }

  .btn-filter-active-true {
    background-color: rgba(161, 200, 216, 0.71) !important;
    color: white;
  }

  .btn-filter-active-false {
    background-color: #f5f5f5 !important;
  }


</style>