<template>
  <router-view/>
</template>

<script>

  export default {
    name: 'App',

    components: {},

    data: () => ({
      //
    }),
    methods: {
      judgeLoginStatus() {
        if (localStorage.loginTime) {
          console.log("Login for", (new Date().getTime() / 1000 - localStorage.loginTime), "s")
          if (new Date().getTime() / 1000 - localStorage.loginTime > 60 * 60 * 24 * 7) {
            localStorage.clear(); // 一周后清除登录状态
          }
        }
      },
    },
    mounted() {
      this.judgeLoginStatus(); // 判断页面产生时是否超过登录时间
      // 判断页面初始化的语言设置
      if ((navigator.language == "zh-CN") | (navigator.language == "zh-cn")) {
        this.$i18n.locale = "zh";
      } else {
        this.$i18n.locale = "en";
      }
    }
  };
</script>
