<template>
  <v-container>
    <!-- 无需确认的提示框 snackbar 控制变量为 isShowSnackbar -->
    <v-snackbar top text color="primary" :timeout="2000" v-model="isShowSnackbar">
      <v-row align="center">
        <v-col class="py-0" v-if="isSubmitSuccess">
          <v-icon small color="green">check_circle</v-icon>
        </v-col>
        <v-col class="py-0" v-else>
          <v-icon small color="red">cancel</v-icon>
        </v-col>
        <v-col cols="8" class="pa-0">
          <div class="caption" v-for="(message, i) in messages" :key="i">{{message}}</div>
        </v-col>
      </v-row>
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="isShowSnackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- 需要确认的提示框 dialog 控制变量为 isShowDialog-->
    <v-dialog v-model="isShowDialog" max-width="500px">
      <v-card clas="text-center">
        <v-card-title class="dialog-text justify-center">确认删除 {{editedItem.name_zh}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="closeDelete">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="deleteItemConfirm">确认</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 全部发表物数据表格 -->
    <v-card>
      <v-card-text>
        <v-btn depressed class="mb-4" @click="addItem()">
          <v-icon left>add</v-icon>
          <span>添加发表物</span>
        </v-btn>
        <v-data-table :headers="headers" :items="items" :sort-by="['order']"
                      :sort-desc="[true]"
                      :page.sync="page" :items-per-page="itemsPerPage" class="elevation-1" hide-default-footer
                      @page-count="pageCount = $event" :loading="isLoading">

          <!-- 表格中的操作按钮 -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
              </template>
              <span>编辑</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small @click="deleteItem(item)">mdi-delete</v-icon>
              </template>
              <span>删除</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <!-- 表格翻页按钮 -->
        <v-card flat class="text-center my-3">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
          <!-- <v-text-field :value="itemsPerPage" label="Items per page" type="number" min="0" max="12"
                        @input="itemsPerPage = parseInt($event, 10)"></v-text-field> -->
        </v-card>
      </v-card-text>
    </v-card>

    <!-- 新增/编辑发表物信息表单 -->
    <v-card v-if="isShowEditor" class="mt-3">
      <v-card-text>
        <!-- 表单首行 -->
        <v-row align="center">
          <v-card-title>
            <v-card flat>{{ formTitle }}</v-card>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="isShowEditor=false" class="mr-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider></v-divider>
        <!-- 表单填写部分 -->
        <v-form ref="form">
          <!-- 表单输入区域 -->
          <v-text-field filled label="标题 Title" value="" v-model="editedItem.title"
                        :rules="[rules.required]"></v-text-field>
          <v-row>
            <v-col md="8">
              <v-text-field filled label="作者列表 Author List" value="" v-model="editedItem.author"
                            :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col md="4">
              <v-text-field filled label="发表平台 Platform" value="" v-model="editedItem.source"
                            :rules="[rules.required]"></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mb-4">
            <v-col md="3">
              <v-select v-model="editedItem.type" :items="typeAll" label="发表物类型 Type">
                <template v-slot:selection="{ item }">
                  <v-chip small dark class="primary">
                    <span>{{ item }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col md="3">
              <v-select v-model="editedItem.area" :items="areaAll" label="研究领域 Area">
                <template v-slot:selection="{ item }">
                  <v-chip small dark class="primary">
                    <span>{{ item }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col md="3">
              <v-select v-model="editedItem.year" :items="yearAll" label="发表年份 Year">
                <template v-slot:selection="{ item }">
                  <v-chip small dark class="primary">
                    <span>{{ item }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col md="3">
              <v-select v-model="editedItem.month" :items="monthAll" label="发表月份 Month">
                <template v-slot:selection="{ item }">
                  <v-chip small dark class="primary">
                    <span>{{ item }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>

          </v-row>

          <v-text-field filled label="发表链接 Link to Official" value="" v-model="editedItem.link1"></v-text-field>
          <v-text-field filled label="代码链接 Link to Github" value="" v-model="editedItem.link2"></v-text-field>
          <v-text-field filled label="微信链接 Link to WeChat" value="" v-model="editedItem.link3"></v-text-field>
          <v-text-field filled label="视频链接 Link to Video" value="" v-model="editedItem.link4"></v-text-field>

        </v-form>
        <v-row align="start" justify="center">
          <v-col md="4" v-for="(i, index) in 3" :key="index">
            <v-card flat class="mb-10" v-if="editedItem['file'+i+'_exist']">
              <v-card-title class="body-2 pb-2 pt-0">{{fileinputAll[i-1]}}</v-card-title>
              <v-card flat class="d-flex align-start">
                <v-btn icon @click="refreshUpload(i)">
                  <v-icon>refresh</v-icon>
                </v-btn>
                <v-card class="caption pa-2 ml-2"
                        :href="'/resource/public/publication/'+editedItem.pid+'/'+editedItem['file'+i]"
                        target="_blank">
                  {{editedItem['file'+i]}}
                </v-card>
              </v-card>
            </v-card>
            <v-card flat v-else>
              <v-file-input class="mt-7" show-size :label="fileinputAll[i-1]" filled small-chips
                            v-model="editedItem['file'+i+'_temp']"></v-file-input>
            </v-card>
          </v-col>
        </v-row>
        <!-- 表单按钮区域 -->
        <v-row justify="center">
          <v-col md="2">
            <v-btn color="primary" dark block class="my-2" @click="submit" :loading="isSubmitting">保 存</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: "consolePublication",
    data() {
      return {
        // 页面状态控制变量
        isShowSnackbar: false,
        isShowDialog: false,
        isShowEditor: false,
        isLoading: false,
        isSubmitting: false,
        isSubmitSuccess: false,
        messages: [],

        // 表格数据
        page: 1, // 默认所在页数
        pageCount: 0,
        itemsPerPage: 10,
        loadingData: true,
        headers: [
          {text: '序号', value: 'order'},
          {text: '发表时间', value: 'year'},
          {text: '发表平台', value: 'source'},
          {text: '发表物名称', value: 'title', sortable: false, width:"40%"},
          {text: '研究领域', value: 'area'},
          {text: '操作', value: 'actions', sortable: false, align: 'center', width: "10%"},
        ],
        items: [], // 通过api获取初始化数据
        editedIndex: -1,

        // 表单数据选项
        typeAll: this.$store.state.publication.typeAllZh,
        areaAll: this.$store.state.people.areaAllZh,
        yearAll: this.$store.state.publication.yearAll,
        monthAll: this.$store.state.publication.monthAll,
        fileinputAll: ["发表物文件 Publication", "演示文稿 Slides", "数据集 Dataset"],

        // 表单密码输入区域
        pwShow: false,
        pwShow2: false,
        rules: {
          required: value => !!value || '必填',
          min: v => v.length >= 8 || '最少8位密码',
          match: v => v == this.editedItem.password || '两次密码输入需保持一致',
        },

        // 表单图片上传
        isShowUpload: false,
        imgParams: {
          token: '123456798',
          name: 'avatar'
        },
        imgHeaders: {
          smail: '*_~'
        },

        // 当前编辑的数据
        editedItem: {},
        // 默认的空白编辑数据, 便于编辑已有数据后再返回空白
        defaultItem: {
          pid: -1,
          type: "",
          area: "",
          area_num: -1,
          title: "",
          author: "",
          year: this.$store.state.publication.yearAll[0],
          month: this.$store.state.publication.monthAll[0],
          source: "",
          file1: null,
          file2: null,
          file3: null,
          link1: "",
          link2: "",
          link3: "",
          link4: "",
          file1_temp: null, // 临时的数据和编辑区域绑定
          file2_temp: null, // 临时的数据和编辑区域绑定
          file3_temp: null, // 临时的数据和编辑区域绑定
        },
      }
    },

    methods: {
      // ----- 按钮功能 -----
      // 添加按钮功能
      addItem() {
        this.isShowEditor = true; // 显示编辑界面
        this.editedItem = Object.assign({}, this.defaultItem); // 初始化空编辑对象
      },

      // 编辑按钮功能
      editItem(item) {
        this.isShowEditor = true;
        this.editedItem = Object.assign({}, item);
      },

      // 删除按钮功能
      deleteItem(item) {
        this.isShowDialog = true;
        this.editedItem = Object.assign({}, item);
      },

      // 重新上传功能
      refreshUpload(i) {
        this.editedItem['file' + i + '_exist'] = false;
      },

      // 关闭删除界面
      closeDelete() {
        this.isShowDialog = false;
      },

      // ----- 后端交互功能 -----
      // 加载数据
      loadData() {
        this.isLoading = true;
        this.$http.get("/api/publication/", {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
            this.items = response.data;
            var i = 1;
            this.items.forEach(function (item) {
              item.file1_exist = !!(item.file1 != "null" && item.file1 != null && item.file1); // !!转换为bool值
              item.file2_exist = !!(item.file2 != "null" && item.file2 != null && item.file2);
              item.file3_exist = !!(item.file3 != "null" && item.file3 != null && item.file3);
              item.file1_temp = null;
              item.file2_temp = null;
              item.file3_temp = null;
              item.order = i;
              i++;
            })
            this.isLoading = false;
          })
      },

      // 提交数据
      submit() {
        if (this.$refs.form.validate()) { // 表单数据合格开始上传
          // this.isSubmitting = true; // 显示按钮上传状态
          this.editedItem.type_num = this.typeAll.indexOf(this.editedItem.type); // 得到type_num的值 (便于排序)
          this.editedItem.area_num = this.areaAll.indexOf(this.editedItem.area); // 得到area_num的值 (便于排序)
          // 对文件进行转换 如果缓存里有fileobj的 则转移到真实字段中
          for (var i = 1; i <= 3; i++) {
            if (this.editedItem["file" + i + "_temp"]!=null) { // 如果有新的文件挂载上来则进行更新
              this.editedItem["file" + i] = this.editedItem["file" + i + "_temp"];
            } else if (!this.editedItem["file" + i + "_exist"]) { // 如果将文件清空了的话
              this.editedItem["file" + i] = "";
            }
          }
          var formData = new FormData(); //创建表单数据
          for (let key in this.editedItem) {
            formData.append(key, this.editedItem[key])
          }
          if (this.editedItem.pid == -1) {
            // 对于新增的数据 使用POST请求
            this.$http.post("/api/publication/", formData,
              {headers: {"Authorization": "Token " + localStorage.token, 'content-type': 'multipart/form-data'}})
              .then(response => {
                this.isSubmitting = false; // 收到响应关闭按钮上传状态
                if (response.data == "success") {
                  this.messages = ["保存成功"];
                  this.isSubmitSuccess = true; // 显示成功图标
                  this.isShowSnackbar = true; // 显示消息提示框
                  this.isShowEditor = false;  // 关闭编辑界面
                  this.loadData();     // 重新加载数据
                } else {
                  this.messages = []; // 清空消息缓存
                  this.isSubmitSuccess = false; // 显示失败图标
                  var self = this;
                  var errors = response.data;
                  for (let key in errors) {
                    errors[key].forEach(function (item) {
                      self.messages.push(key + ": " + item);
                    })
                  }
                  this.isShowSnackbar = true;
                }
              })
          } else {
            // 对于修改的数据 使用PUT请求
            console.log(this.editedItem);
            this.$http.put("/api/publication/?pid=" + this.editedItem.pid, formData,
              {headers: {"Authorization": "Token " + localStorage.token, 'content-type': 'multipart/form-data'}})
              .then(response => {
                this.isSubmitting = false; // 收到响应关闭按钮上传状态
                if (response.data == "success") {
                  this.messages = ["保存成功"];
                  this.isSubmitSuccess = true; // 显示成功图标
                  this.isShowSnackbar = true; // 显示消息提示框
                  // this.isShowEditor = false;  // 关闭编辑界面
                  this.loadData();      // 重新加载数据
                } else {
                  this.messages = [];   // 清空消息缓存
                  this.isSubmitSuccess = false; // 显示失败图标
                  var self = this;
                  var errors = response.data;
                  for (let key in errors) {
                    errors[key].forEach(function (item) {
                      self.messages.push(key + ": " + item);
                    })
                  }
                  this.isShowSnackbar = true;
                }
              })
          }
        }
      },

      // 确认删除数据
      deleteItemConfirm() {
        this.$http.delete("/api/publication/?pid=" + this.editedItem.pid,
          {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
            this.messages = ["删除成功"];
            this.isSubmitSuccess = true; // 显示成功图标
            this.isShowDialog = false;  // 关闭删除提示框
            this.isShowSnackbar = true; // 进行消息提示
            this.loadData();            // 重新加载数据
          })
      },
    },
    computed: {
      formTitle() {
        return this.editedItem.pid === -1 ? '添加发表物' : '编辑发表物 ' + this.editedItem.title
      },
      computePortraitUrl() {
        if (this.editedItem.portrait_url[0] == "/") {
          return this.editedItem.portrait_url + "?" + new Date().getTime();
        } else {
          return this.editedItem.portrait_url;
        }
      }
    },
    mounted() {
      this.loadData();
    },
  }
</script>

<style scoped>

</style>