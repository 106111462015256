<template>
  <v-container>
    <v-card flat>
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-btn block class="primary" @click="loadDataToday" :loading="isLoadingToday">今日提醒</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn block class="primary" @click="loadDataYesterday" :loading="isLoadingYesterday">昨日零报告</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card :loading="isLoading">
        <v-card-text>
          <v-col cols="12" class="py-0 pl-0">
            <div v-if="isRefreshCookie" class="red--text">更新cookie</div>
            <div v-else class="blue--text">cookie可用</div>
          </v-col>
          <v-row align="center">
            <v-col cols="12" class="py-0">
              <v-text-field label="cookie" v-model="cookie">
              </v-text-field>
            </v-col>
          </v-row>
          <v-btn block class="gray hidden-md-and-up mb-2" @click="isShowConfig=!isShowConfig">{{ formConfig }}</v-btn>
          <v-card flat v-if="isShowConfig">
            <v-row>
              <v-col cols="6" md="3" lg="3" xl="3">
                <v-text-field label="第二针总数" v-model="report.dez">
                </v-text-field>
              </v-col>
              <v-col cols="6" md="3" lg="3" xl="3">
                <v-text-field label="自行第二针（自行填写）" v-model="report.selfdez">
                </v-text-field>
              </v-col>
              <v-col cols="6" md="3" lg="3" xl="3">
                <v-text-field label="第一针总数" v-model="report.dyz">
                </v-text-field>
              </v-col>
              <v-col cols="6" md="3" lg="3" xl="3">
                <v-text-field label="自行第一针（自行填写）" v-model="report.selfdyz">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" md="3" lg="3" xl="3">
                <v-text-field label="总人数" v-model="report.zxrs">
                </v-text-field>
              </v-col>
              <v-col cols="6" md="3" lg="3" xl="3">
                <v-text-field label="校外住宿人数（自行填写）" v-model="report.xwzs">
                </v-text-field>
              </v-col>
              <v-col cols="6" md="3" lg="3" xl="3">
                <v-text-field label="昨日离校人数" v-model="report.lixiao">
                </v-text-field>
              </v-col>
              <v-col cols="6" md="3" lg="3" xl="3">
                <v-text-field label="昨日返校人数" v-model="report.fanxiao">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" md="3" lg="3" xl="3">
                <v-menu v-model="isPickDate2" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="pickDate2" label="离校日期" prepend-icon="mdi-calendar"
                                  readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="pickDate2" locale="zh-cmn" first-day-of-week="1"
                                 @input="isPickDate2 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" md="3" lg="3" xl="3">
                <v-text-field label="离校人数" v-model="dateLixiao">
                </v-text-field>
              </v-col>
              <v-col cols="6" md="3" lg="3" xl="3">
                <v-menu v-model="isPickDate1" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="pickDate1" label="返校日期" prepend-icon="mdi-calendar"
                                  readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="pickDate1" locale="zh-cmn" first-day-of-week="1"
                                 @input="isPickDate1 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" md="3" lg="3" xl="3">
                <v-text-field label="返校人数" v-model="dateFanxiao">
                </v-text-field>
              </v-col>
            </v-row>
            <v-btn color="primary" block @click="saveConfig()" :loading="isSavingConfig">保存</v-btn>
            <v-row>
              <v-col cols="12" md="6" lg="6" xl="6">
                <v-card-text>近1月离校人数</v-card-text>
                <v-data-table :headers="headers_lifan" :items="lixiao_month" :sort-by="['date']" :sort-desc="[false]"
                              class="elevation-1" hide-default-footer :loading="isLoading">
                </v-data-table>
              </v-col>
              <v-col cols="12" md="6" lg="6" xl="6">
                <v-card-text>近1月返校人数</v-card-text>
                <v-data-table :headers="headers_lifan" :items="fanxiao_month" :sort-by="['date']" :sort-desc="[false]"
                              class="elevation-1" hide-default-footer :loading="isLoading">
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>

      <v-card-title class="mt-4" v-show="isLoadedToday">今日提醒 {{date}}</v-card-title>
      <v-card v-show="isLoadedToday">
        <v-card-text>
          <v-data-table :headers="headers_" :items="weishangbao" :sort-by="['xgh']" :sort-desc="[false]"
                        class="elevation-1" :loading="isLoadingToday" :items-per-page="5">
          </v-data-table>
          <v-card width="100%" min-height="300" flat id="fig" class="mt-5"></v-card>
          <v-btn block color="primary" @click="saveRemind()" :loading="isSavingRemind">记录</v-btn>
        </v-card-text>
      </v-card>

      <v-card-title class="mt-4" v-if="isLoadedYesterday">零报告 {{date_yes}}</v-card-title>
      <v-card v-if="isLoadedYesterday">
        <v-card-text>
          截止到{{this.date_yes_month}}月{{this.date_yes_day}}日24点，材料科学系2018级本科生：<br>
          1.在校学生<span class="red--text">{{report.zxrs}}</span>人（其中校外住宿走读<span class="red--text">{{report.xwzs}}</span>人），返校<span
            class="red--text">{{report.fanxiao}}</span>人、离校<span
            class="red--text">{{report.lixiao}}</span>人，在校学生无特殊情况。<br>
          2.在沪非在校学生无特殊情况。<br>
          3.无中高风险地区所在县（区、市）学生。<br>
          4.在境外（含港澳台）学生<span class="red--text">1</span>人，新增0人、减少0人，无特殊情况。<br>
          5.在境内其他地区学生无特殊情况。<br>
          6.已经查看本院系昨日平安复旦填报，<span class="red--text">{{report.num_weishangbao}}</span>人未填报，已确认无特殊情况。<br>
          7.本院系累计完成两针疫苗接种<span class="red--text">{{ report.dez }}</span>人（其中自行接种<span class="red--text">{{report.selfdez}}</span>
          人），只进行过第一针<span class="red--text">{{report.dyz}}</span>人（其中自行接种<span
            class="red--text">{{report.selfdyz}}</span>人）。

          <v-card-title> 昨日未填报</v-card-title>

          <v-data-table :headers="headers" :items="weishangbao_yes" :sort-by="['xgh']" :sort-desc="[false]"
                        class="elevation-1" hide-default-footer :loading="isLoadingYesterday">
          </v-data-table>

          <v-card-title> 昨日不正常</v-card-title>

          <v-data-table :headers="headers" :items="buzhengchang_yes" :sort-by="['xgh']" :sort-desc="[false]"
                        class="elevation-1" hide-default-footer :loading="isLoadingYesterday">
          </v-data-table>
        </v-card-text>
      </v-card>

    </v-card>
  </v-container>
</template>

<script>
  import * as echarts from 'echarts';

  export default {
    name: "lchenPingan",
    data() {
      return {
        isLoading: false,
        isLoadingToday: false,
        isLoadingYesterday: false,
        isLoaded: false,
        isLoadedToday: false,
        isLoadedYesterday: false,
        isSavingConfig: false,
        isSavingRemind: false,
        isPickDate1: false,
        isPickDate2: false,
        isShowConfig: true,

        isRefreshCookie: false,

        date: "",
        date_yes: "",
        pickDate1: "",
        pickDate2: "",
        dateFanxiao: "",
        dateLixiao: "",
        fanxiao: {},
        lixiao: {},
        lixiao_month: [],
        fanxiao_month: [],
        cookie: "",
        // 报告内数据
        report: {
          zrrs: 0,
          xwzs: 0,
          num_weishangbao: 0,
          lixiao: 0,
          fanxiao: 0,
          dez: 0,
          dyz: 0,
          selfdez: 0,
          selfdyz: 0,
        },
        // 离校返校数据
        headers_lifan: [
          {text: '日期', value: 'date', width: "50%"},
          {text: '人数', value: 'num', width: "50%"},
        ],

        // 表格数据
        headers: [
          {text: '姓名', value: 'realname', width: "25%"},
          {text: '学号', value: 'xgh', width: "25%"},
          {text: '上报状态', value: 'uptime', width: "25%"},
          {text: '身体状态', value: 'status', width: "25%"},
        ],
        headers_: [
          {text: '姓名', value: 'realname', width: "25%"},
          {text: '学号', value: 'xgh', width: "25%"},
          {text: '上报状态', value: 'uptime', width: "25%"},
        ],
        remind_history: [], // 提醒记录
        weishangbao: [],
        weishangbao_yes: [],
        buzhengchang_yes: [],
        weishangbao_history: [], // 历史记录中的未上报
        weishangbao_fig: [], // 做图用的未上报
      }
    },
    methods: {
      // 加载平安复旦
      loadData() {
        this.isLoading = true;
        this.$http.get("/apilchen/pingan/?date=" + this.date + "&date_yes=" + this.date_yes + "&mount=1", {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
              console.log(response.data);
              var data = response.data;
              var date = this.date;
              this.isRefreshCookie = data.refresh_cookie;
              this.cookie = data.cookie;
              this.remind_history = data.remind;
              this.report.zrs = data.zrs;
              this.report.xwzs = data.xwzs;
              this.report.selfdez = data.selfdez;
              this.report.selfdyz = data.selfdyz;
              this.lixiao = data.lixiao;
              this.fanxiao = data.fanxiao;
              this.lixiao_month = data.lixiao_month;
              this.fanxiao_month = data.fanxiao_month;
              this.dateLixiao = this.lixiao[this.pickDate2];
              this.dateFanxiao = this.fanxiao[this.pickDate1];
              this.report.lixiao = this.lixiao[this.date_yes];
              this.report.fanxiao = this.fanxiao[this.date_yes];
              this.report.zxrs = data.zxrs;
              if (!this.report.lixiao) {
                this.report.lixiao = 0
              }
              if (!this.report.fanxiao) {
                this.report.fanxiao = 0
              }
              this.isLoading = false;
              this.isLoaded = true;
            }
          )
      },

      // 加载今日数据
      loadDataToday() {
        this.isLoadingToday = true;
        this.$http.get("/apilchen/pingan/?date=" + this.date + "&date_yes=" + this.date_yes + "&mount=2", {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
              var data = response.data;
              this.weishangbao = data.weishangbao;
              this.weishangbao_name = data.weishangbao_name;
              this.isLoadingToday = false;
              this.isLoadedToday = true;
              this.weishangbao_fig = [];
              this.$nextTick(() => {
                this.formFigData();
                this.plotFig();
              });
            }
          )
      },

      // 加载昨日数据
      loadDataYesterday() {
        this.isLoadingYesterday = true;
        this.$http.get("/apilchen/pingan/?date=" + this.date + "&date_yes=" + this.date_yes + "&mount=3", {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
              var data = response.data;
              this.report.num_weishangbao = data.num_weishangbao;
              this.report.dez = data.dez;
              this.report.dyz = data.dyz;
              this.weishangbao_yes = data.weishangbao_yes;
              this.buzhengchang_yes = data.buzhengchang_yes;
              this.isLoadingYesterday = false;
              this.isLoadedYesterday = true;
            }
          )
      },

      // 保存参数
      saveConfig() {
        this.isSavingConfig = true;
        this.lixiao[this.pickDate2] = this.dateLixiao;
        this.fanxiao[this.pickDate1] = this.dateFanxiao;
        var formData = new FormData(); //创建表单数据
        formData.append("cookie", this.cookie);
        formData.append("selfdez", this.report.selfdez);
        formData.append("selfdyz", this.report.selfdyz);
        formData.append("xwzs", this.report.xwzs);
        formData.append("lixiao_temp", [this.pickDate2, this.dateLixiao]);
        formData.append("fanxiao_temp", [this.pickDate1, this.dateFanxiao]);
        this.$http.put("/apilchen/pingan/?remind=0", formData, {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
              this.isSavingConfig = false;
              this.loadData();
            }
          )
      },

      // 保存提醒
      saveRemind() {
        this.isSavingRemind = true;
        var formData = new FormData(); //创建表单数
        formData.append("remind", this.weishangbao_name[this.date]);
        this.$http.put("/apilchen/pingan/?remind=1&date=" + this.date, formData, {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
              this.isSavingRemind = false;
              console.log(response.data);
            }
          )
      },

      // 形成做图数据
      formFigData() {
        if (this.remind_history[this.date] == undefined) { // 如果今日数据还未被记录
          var temp = this.weishangbao_name[this.date];
          for (let i = 0; i < temp.length; i++) {
            this.weishangbao_fig.push(temp[i])
          }
        }
        for (let key in this.remind_history) {
          let temp = this.remind_history[key]
          for (let i = 0; i < temp.length; i++) {
            this.weishangbao_fig.push(temp[i])
          }
        }
      },

      // 绘图
      plotFig() {
        // 准备x y 轴数据
        var arr = this.weishangbao_fig;
        var obj = {};
        var objArr = [];
        for (let i = 0; i < arr.length; i++) {
          key = arr[i];
          if (obj[key]) {
            obj[key]++;  //对象中有这个字母
          } else {
            obj[key] = 1;//对象中没有这个字母,把字母加到对象中
          }
        }

        for (var key in obj) {//遍历这个对象
          let item = {label: key, value: obj[key]};
          objArr.push(item);
        }
        objArr = objArr.sort((a, b) => {
          return b.value - a.value;
        });

        var top10 = objArr.slice(0, 10);
        var x = top10.map(function (value, index, arr) {
          return value.label;
        })
        var y = top10.map(function (value, index, arr) {
          return value.value;
        })

        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('fig'));
        // 绘制图表
        myChart.setOption({
          title: {
            text: '累计提醒次数'
          },
          tooltip: {},
          xAxis: {
            data: x,
          },
          yAxis: {},
          series: [{
            name: '未填次数',
            type: 'bar',
            data: y,
          }]
        });
      }
    },
    mounted() {

      Date.prototype.Format = function (fmt) {
        var o = {
          "M+": this.getMonth() + 1, //月份
          "d+": this.getDate(), //日
          "h+": this.getHours(), //小时
          "m+": this.getMinutes(), //分
          "s+": this.getSeconds(), //秒
          "q+": Math.floor((this.getMonth() + 3) / 3), //季度
          "S": this.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o) {
          if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
          }
        }
        return fmt;
      }
      this.date = (new Date()).Format("yyyy-MM-dd");
      this.date_yes = new Date((new Date() - 3600 * 24 * 1000)).Format("yyyy-MM-dd");
      this.date_yes_month = new Date((new Date() - 3600 * 24 * 1000)).Format("M");
      this.date_yes_day = new Date((new Date() - 3600 * 24 * 1000)).Format("d");
      this.pickDate1 = this.date_yes;
      this.pickDate2 = this.date_yes;
      this.loadData();
    },
    watch: {
      pickDate1: { // 监听路由变化
        handler: function (val, oldVal) {
          this.dateFanxiao = this.fanxiao[this.pickDate1];
        },
      },
      pickDate2: { // 监听路由变化
        handler: function (val, oldVal) {
          this.dateLixiao = this.lixiao[this.pickDate2];
        },
      },
    },
    computed: {
      formConfig() {
        if (this.isShowConfig) {
          return "隐藏参数";
        } else {
          return "显示参数";
        }
      }
    }
  }
</script>

<style scoped>

</style>