<template>
  <v-container>
    <!-- 无需确认的提示框 snackbar 控制变量为 isShowSnackbar -->
    <v-snackbar top text color="primary" :timeout="2000" v-model="isShowSnackbar">
      <v-row align="center">
        <v-col class="py-0" v-if="isSubmitSuccess">
          <v-icon small color="green">check_circle</v-icon>
        </v-col>
        <v-col class="py-0" v-else>
          <v-icon small color="red">cancel</v-icon>
        </v-col>
        <v-col cols="9" class="pa-0">
          <div class="caption" v-for="(message, i) in messages" :key="i">{{message}}</div>
        </v-col>
      </v-row>
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="isShowSnackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- 需要确认的提示框 dialog 控制变量为 isShowDialog-->
    <v-dialog v-model="isShowDialog" max-width="500px">
      <v-card clas="text-center">
        <v-card-title class="dialog-text justify-center">确认删除 {{editedItem.title_zh}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="closeDelete">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="deleteItemConfirm">确认</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 图片上传对话框 -->
    <my-upload field="img" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess"
               @crop-upload-fail="cropUploadFail" v-model="isShowUpload" noSquare noCircle
               :width="400" :height="400" url="" :params="imgParams" :headers="imgHeaders" img-format="png">
    </my-upload>

    <!-- 全部人员数据表格 -->
    <v-card>
      <v-card-text>
        <v-btn depressed class="mb-4" @click="addItem()">
          <v-icon left>add</v-icon>
          <span>添加讲座</span>
        </v-btn>
        <v-data-table :headers="headers" :items="items" :sort-by="['date']"
                      :sort-desc="[true]"
                      :page.sync="page" :items-per-page="itemsPerPage" class="elevation-1" hide-default-footer
                      @page-count="pageCount = $event" :loading="isLoading">

          <!-- 表格中的操作按钮 -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mx-1" v-bind="attrs" v-on="on" small @click="editItem(item)">mdi-pencil</v-icon>
              </template>
              <span>编辑</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mx-1" v-bind="attrs" v-on="on" small @click="deleteItem(item)">mdi-delete</v-icon>
              </template>
              <span>删除</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <!-- 表格翻页按钮 -->
        <v-card flat class="text-center my-3">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
          <!-- <v-text-field :value="itemsPerPage" label="Items per page" type="number" min="0" max="12"
                        @input="itemsPerPage = parseInt($event, 10)"></v-text-field> -->
        </v-card>
      </v-card-text>
    </v-card>



    <!-- 新增/编辑新闻信息表单 -->
    <v-card v-if="isShowEditor" class="mt-3">
      <v-card-text>
        <!-- 表单首行 -->
        <v-row align="center">
          <v-card-title>
            <v-card flat>{{ formTitle }}</v-card>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="isShowEditor=false" class="mr-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider></v-divider>
        <!-- 表单填写部分 -->
        <v-form ref="form">
          <!-- 讲座名称 -->
          <v-row>
            <v-col cols="6">
              <v-text-field filled label="讲座标题" value="" :rules="[rules.required]"
                            v-model="editedItem.title_zh"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field filled label="Seminar Title" value=""
                            v-model="editedItem.title_en"></v-text-field>
            </v-col>
          </v-row>

          <!-- 讲座摘要 -->
          <v-row>
            <v-col cols="6">
              <!-- 新闻内容 (中文) -->
              <v-textarea filled label="讲座摘要" value="" v-model="editedItem.abstract_zh"
                          rows="3"></v-textarea>
            </v-col>
            <v-col cols="6">
              <!-- 新闻内容 (英文) -->
              <v-textarea filled label="Seminar Abstract" value="" v-model="editedItem.abstract_en"
                          rows="3"></v-textarea>
            </v-col>
          </v-row>

          <!-- 讲者姓名 -->
          <v-row>
            <v-col cols="6">
              <v-text-field filled label="讲者姓名" value=""
                            v-model="editedItem.speaker_zh"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field filled label="Speaker Name" value=""
                            v-model="editedItem.speaker_en"></v-text-field>
            </v-col>
          </v-row>
          <!-- 讲者机构 -->
          <v-row>
            <v-col cols="6">
              <v-text-field filled label="讲者机构" value=""
                            v-model="editedItem.ins_zh"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field filled label="Speaker Institute" value=""
                            v-model="editedItem.ins_en"></v-text-field>
            </v-col>
          </v-row>
          <!-- 讲者介绍 -->
          <v-row>
            <v-col cols="6">
              <!-- 新闻内容 (中文) -->
              <v-textarea filled label="讲者介绍" value="" v-model="editedItem.des_zh"
                          rows="2"></v-textarea>
            </v-col>
            <v-col cols="6">
              <!-- 新闻内容 (英文) -->
              <v-textarea filled label="Speaker Introduction" value="" v-model="editedItem.des_en"
                          rows="2"></v-textarea>
            </v-col>
          </v-row>

          <!-- 主持人姓名 -->
          <v-row>
            <v-col cols="6">
              <v-text-field filled label="主持人" value=""
                            v-model="editedItem.host_zh"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field filled label="Host" value=""
                            v-model="editedItem.host_en"></v-text-field>
            </v-col>
          </v-row>

          <!-- 主持人单位 -->
          <v-row>
            <v-col cols="6">
              <v-text-field filled label="主持人单位" value=""
                            v-model="editedItem.host_ins_zh"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field filled label="Host Institute" value=""
                            v-model="editedItem.host_ins_en"></v-text-field>
            </v-col>
          </v-row>

          <!-- 讲座图片 -->
          <v-row justify="space-around" class="mb-4">
            <v-col cols="5" class="py-0">
              <v-row class="fill-height" justify="center">
                <v-card class="text-center" height="300" width="300" @click="isShowUpload=true">
                  <v-row class="fill-height" justify="center" align="center">
                    <v-col class="py-0" v-if="!isCropSuccess & !editedItem.image_url">
                      <div class="subtitle-1 grey--text">
                        <v-icon left>upload</v-icon>
                        <span>上传讲者照片</span>
                      </div>
                      <div class="caption grey--text">
                        ( 图片长宽至少为 400*400 像素 )
                      </div>
                    </v-col>
                    <v-col class="py-0" v-else>
                      <v-img height="300" class="d-flex justify-end image-article"
                             :src="editedItem.image_url" v-if="isCropSuccess"></v-img>
                      <v-img height="300" class="d-flex justify-end image-article"
                             :src="editedItem.image_url +'?'+ mountTime" v-else></v-img>
                    </v-col>
                  </v-row>
                </v-card>
              </v-row>
            </v-col>
          </v-row>

          <!-- 讲座时间 -->
          <v-row class="mt-4">
            <v-col cols="4">
              <v-menu v-model="isPickDate" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="editedItem.date" label="讲座日期" prepend-icon="mdi-calendar"
                                readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="editedItem.date" locale="zh-cmn" first-day-of-week="1"
                               @input="isPickDate = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-menu ref="menu1" v-model="isPickStartTime" :close-on-content-click="false" :nudge-right="40"
                      :return-value.sync="editedItem.start"
                      transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="editedItem.start" label="开始时间" prepend-icon="mdi-clock-time-four-outline"
                                readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-time-picker format="24hr" v-if="isPickStartTime" v-model="editedItem.start" full-width
                               @click:minute="$refs.menu1.save(editedItem.start)"></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-menu ref="menu2" v-model="isPickEndTime" :close-on-content-click="false" :nudge-right="40"
                      :return-value.sync="editedItem.end"
                      transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="editedItem.end" label="结束时间" prepend-icon="mdi-clock-time-four-outline"
                                readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-time-picker format="24hr" v-if="isPickEndTime" v-model="editedItem.end" full-width
                               @click:minute="$refs.menu2.save(editedItem.end)"></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>

          <!-- 会议地址 -->
          <v-row>
            <v-col cols="4">
              <v-select v-model="editedItem.room_type" :items="roomAll" label="会议类型 Room Type">
                <template v-slot:selection="{ item }">
                  <v-chip small dark class="primary">
                    <span>{{ item }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field label="会议号 Room ID" value=""
                            v-model="editedItem.room_id"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field label="会议密码 Room Password" value=""
                            v-model="editedItem.room_pw"></v-text-field>
            </v-col>
          </v-row>

          <!-- 直播地址 -->
          <v-row>
            <v-col cols="12">
              <v-text-field label="直播地址 Live Link" value=""
                            v-model="editedItem.live_link"></v-text-field>
            </v-col>
          </v-row>





        </v-form>
        <!-- 表单按钮区域 -->
        <v-row justify="center">
          <v-col cols="2">
            <v-btn color="primary" dark block class="my-2" @click="submit" :loading="isSubmitting">保 存</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
  import myUpload from 'vue-image-crop-upload';

  export default {
    name: "consoleSeminar",
    components: {"my-upload": myUpload},
    data() {
      return {
        // 页面状态控制变量
        isShowSnackbar: false, // 是否显示提示对话框
        isShowDialog: false, // 是否显示确认对话框
        isShowEditor: false, // 是否显示编辑区域

        isLoading: false,
        isSubmitting: false,
        isSubmitSuccess: false,
        isPickDate: false, // 是否正在挑选日期
        isPickStartTime: false, // 是否正在挑选时间
        isPickEndTime: false, // 是否正在挑选时间
        messages: [],
        dialogMessage: "",
        mountTime: new Date().getTime(),

        // 表单图片上传
        isShowUpload: false, // 是否显示图片上传区域
        isCropSuccess: false, // 图片剪切是否成功
        imgParams: {token: '123456798', name: 'avatar'},
        imgHeaders: {smail: '*_~'},

        // 表单候选项
        roomAll: ["腾讯会议", "zoom会议"],

        // 表格数据
        page: 1, // 默认所在页数
        pageCount: 0,
        itemsPerPage: 10,
        loadingData: true,
        headers: [
          // {text: '序号', sortable: true, value: 'pid', width: "10%"},
          {text: '序号', value: 'order', width: "10%"},
          {text: '讲座日期', value: 'date', sortable: false, width: "15%"},
          {text: '讲者姓名', value: 'speaker_zh', sortable: false, width: "15%"},
          {text: '讲座名称', value: 'title_zh'},
          {text: '操作', value: 'actions', sortable: false, align: 'center', width: "15%"},
        ],
        items: [], // 通过api获取初始化数据
        editedIndex: -1,

        // 输入限制
        rules: {
          required: value => !!value || '必填',
          min: v => v.length >= 8 || '最少8位密码',
          match: v => v == this.editedItem.password || '两次密码输入需保持一致',
        },

        // 当前编辑的数据
        editedItem: {
          sid: -1,
          speaker_zh: "",
          title_zh: "",
          ins_zh: "",
          des_zh: "",
          abstract_zh: "",
          host_zh: "",
          host_ins_zh: "",
          speaker_en: "",
          title_en: "",
          ins_en: "",
          des_en: "",
          host_en: "",
          host_ins_en: "",
          abstract_en: "",
          room_type: "腾讯会议",
          room_id: "",
          room_pw: "",
          live_link: "",
          date: new Date().toISOString().substr(0, 10),
          start: "14:00",
          end: "15:30",
          image_url: "",
        },
        // 默认的空白编辑数据, 便于编辑已有数据后再返回空白
        defaultItem: {
          sid: -1,
          speaker_zh: "",
          title_zh: "",
          ins_zh: "",
          des_zh: "",
          abstract_zh: "",
          host_zh: "",
          host_ins_zh: "",
          speaker_en: "",
          title_en: "",
          ins_en: "",
          des_en: "",
          host_en: "",
          host_ins_en: "",
          abstract_en: "",
          room_type: "腾讯会议",
          room_id: "",
          room_pw: "",
          live_link: "",
          date: new Date().toISOString().substr(0, 10),
          start: "14:00",
          end: "15:30",
          image_url: "",
        },
      }
    },

    methods: {
      // ----- 按钮功能 -----
      // 添加人员按钮功能
      addItem() {
        this.isShowEditor = true; // 显示编辑界面
        this.editedItem = Object.assign({}, this.defaultItem); // 初始化空编辑对象
      },

      // 编辑人员按钮功能
      editItem(item) {
        this.isShowEditor = true;
        this.editedItem = Object.assign({}, item);
      },

      // 删除人员按钮功能
      deleteItem(item) {
        this.isShowDialog = true;
        this.editedItem = Object.assign({}, item);
      },

      // 关闭删除界面
      closeDelete() {
        this.isShowDialog = false;
      },

      closeRelease() {
        this.isShowDialogRelease = false;
      },

      closeEmphasize() {
        this.isShowEmphasize = false;
      },

      // 发布按钮功能
      releaseItem(item) {
        this.dialogMessage = "确认发布";
        this.isShowDialogRelease = true;
        this.editedItem = Object.assign({}, item);
      },

      // 取消发布按钮功能
      unreleaseItem(item) {
        this.dialogMessage = "取消发布";
        this.isShowDialogRelease = true;
        this.editedItem = Object.assign({}, item);
      },

      // 突出按钮功能
      emphasizeItem(item) {
        this.dialogMessage = "确认突出";
        this.isShowDialogEmphasize = true;
        this.editedItem = Object.assign({}, item);
      },

      // 取消突出按钮功能
      unemphasizeItem(item) {
        this.dialogMessage = "取消突出";
        this.isShowDialogEmphasize = true;
        this.editedItem = Object.assign({}, item);
      },

      // ----- 后端交互功能 -----
      // 加载数据
      loadData() {
        this.isLoading = true;
        this.$http.get("/api/seminar/", {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
            this.items = response.data;
            console.log(this.items);
            var i = 0;
            var l = this.items.length
            this.items.forEach(function (item) {
              item.order = l - i;
              i++;
            })
            this.isLoading = false;
          })
      },

      // 提交数据
      submit() {
        if (this.$refs.form.validate()) { // 表单数据合格开始上传
          this.isSubmitting = true; // 显示按钮上传状态
          var formData = new FormData(); //创建表单数据
          console.log(this.editedItem);
          for (let key in this.editedItem) {
            formData.append(key, this.editedItem[key])
          }
          console.log(formData);
          if (this.editedItem.sid == -1) {
            // 对于新增的数据 使用POST请求
            this.$http.post("/api/seminar/", formData,
              {headers: {"Authorization": "Token " + localStorage.token, 'content-type': 'multipart/form-data'}})
              .then(response => {
                this.isSubmitting = false; // 收到响应关闭按钮上传状态
                if (response.data == "success") {
                  this.messages = ["保存成功"]
                  this.isSubmitSuccess = true; // 显示成功图标
                  this.isShowSnackbar = true; // 显示消息提示框
                  this.isShowEditor = false;  // 关闭编辑界面
                  this.loadData();     // 重新加载数据
                } else {
                  this.isSubmitSuccess = false; // 显示失败图标
                  this.messages = []; // 清空消息缓存
                  var errors = response.data;
                  for (let key in errors) {
                    this.messages.push(key + ": " + errors[key])
                  }
                  this.isShowSnackbar = true;
                }
              })
          } else {
            // 对于修改的数据 使用PUT请求
            this.$http.put("/api/seminar/?sid=" + this.editedItem.sid, formData,
              {headers: {"Authorization": "Token " + localStorage.token, 'content-type': 'multipart/form-data'}})
              .then(response => {
                this.isSubmitting = false; // 收到响应关闭按钮上传状态
                if (response.data == "success") {
                  this.messages = ["保存成功"]
                  this.isSubmitSuccess = true; // 显示成功图标
                  this.isShowSnackbar = true; // 显示消息提示框
                  this.isShowEditor = false;  // 关闭编辑界面
                  this.loadData();      // 重新加载数据
                } else {
                  this.isSubmitSuccess = false; // 显示失败图标
                  this.messages = [];   // 清空消息缓存
                  var errors = response.data;
                  for (let key in errors) {
                    this.messages.push(key + ": " + errors[key])
                  }
                  this.isShowSnackbar = true;
                }
              })
          }
        }
      },

      // 确认删除数据
      deleteItemConfirm() {
        this.$http.delete("/api/seminar/?sid=" + this.editedItem.sid,
          {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
            this.messages = ["删除成功"];
            this.isSubmitSuccess = true; // 显示成功图标
            this.isShowDialog = false;  // 关闭删除提示框
            this.isShowSnackbar = true; // 进行消息提示
            this.loadData();            // 重新加载数据
          })
      },

      // 插件功能
      // 图片上传
      cropSuccess(imgDataUrl, field) {
        console.log('-------- crop success --------');
        this.isCropSuccess = true;
        this.editedItem.image_url = imgDataUrl;
        console.log(this.imgDataUrl);
      },
      cropUploadSuccess(jsonData, field) {
        console.log('-------- upload success --------');
        console.log(jsonData);
        console.log('field: ' + field);
      },
      cropUploadFail(status, field) {
        console.log('-------- upload fail --------');
        console.log(status);
        console.log('field: ' + field);
      },

    },
    computed: {
      formTitle() {
        return this.editedItem.sid === -1 ? '添加讲座' : '编辑讲座 ' + this.editedItem.title_zh;
      },
    },
    mounted() {
      this.loadData();
    },
  }
</script>

<style scoped>

</style>