<template>
  <v-container ref="container">
    <v-tabs color="primary" fixed-tabs>
      <v-tab>{{$t('people.current')}}</v-tab>
      <v-tab>{{$t('people.former')}}</v-tab>
      <!-- 在组成员 -->
      <v-tab-item>
        <v-card flat class="d-flex flex-column justify-space-between" v-if="isLoaded">
          <!-- 老师列表 -->
          <v-card flat>
            <v-card-title class="justify-center mt-10 mb-2">
              <span class="title-type">{{$t('people.teacher')}}</span>
            </v-card-title>
            <v-row class="mx-3 justify-center align-center" v-for="(item, index2) in itemsTeacher" :key="index2">
              <v-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3" class="py-0">
                <v-card class="pt-2" flat :href="item.link" target="_blank">
                  <v-layout column align-center>
                    <v-flex class="my-2  text-center">
                      <v-card flat color="transparent" class="d-flex justify-center">
                        <image-shadow class="justify-space-around" :src="item.portrait_url"
                                      :shadow-hover="item.link_type>0"
                                      :width="imageWidth" :shadow-radius="imageRadius"></image-shadow>
                      </v-card>
                      <v-card flat color="transparent" class="my-5" v-if="$i18n.locale=='zh'">
                        <div class="text-wrap">
                          <p class="subtitle-2 mb-0">{{item.name_zh}}</p>
                          <p class="caption grey--text  mb-0">{{ $store.state.people.typeAllZh[item.type_num] }} </p>
                        </div>
                      </v-card>
                      <v-card flat color="transparent" class="my-4" v-else>
                        <div class="text-wrap">
                          <p class="subtitle-2 mb-0">{{item.name_en}}</p>
                          <p class="caption grey--text mb-0">{{ $store.state.people.typeAllEn[item.type_num] }} </p>
                        </div>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="8" xl="8" class="mb-2 py-0">
                <v-card class="elevation-5 mb-2">
                  <v-card-text v-if="$i18n.locale=='zh'">
                    {{ item.description_zh }}
                  </v-card-text>
                  <v-card-text v-if="$i18n.locale=='en'">
                    {{ item.description_en }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
          <!-- 学生列表 -->
          <v-card flat v-for="(itemsTemp, index1) in [itemsPostdoc, itemsPhd, itemsMaster, itemsUndergraduate]"
                  :key="index1">
            <v-card-title class="justify-center mt-10 pt-5" v-if="itemsTemp.length">
              <span class="title-type">{{ $t('people.titles.'+index1) }}</span>
            </v-card-title>
            <v-row class="mx-3" v-if="itemsTemp.length">
              <v-col class="py-0" cols="6" xs="6" sm="6" md="3" lg="3" xl="3" v-for="(item, index2) in itemsTemp"
                     :key="index2">
                <v-card class="pt-2" flat :href="item.link" target="_blank">
                  <v-layout column align-center>
                    <v-flex class="my-1  text-center">
                      <v-card flat color="transparent" class="d-flex justify-center">
                        <image-shadow class="justify-space-around" :src="item.portrait_url"
                                      :shadow-hover="item.link_type>0"
                                      :width="imageWidth" :shadow-radius="imageRadius"></image-shadow>
                      </v-card>
                      <v-card flat color="transparent" class="my-5 px-4" v-if="$i18n.locale=='zh'">
                        <div class="text-wrap">
                          <p class="subtitle-2 mb-0">{{item.name_zh}}</p>
                          <p class="caption grey--text mb-0">{{ item.entrance }}级 </p>
                          <p class="caption grey--text mb-0">{{ $store.state.people.areaAllZh[item.area_num] }} </p>
                        </div>
                      </v-card>
                      <v-card flat color="transparent" class="my-4 px-4" v-else>
                        <div class="text-wrap">
                          <p class="subtitle-2 mb-0">{{item.name_en}}</p>
                          <p class="caption grey--text mb-0"> ( From {{ item.entrance }} ) </p>
                          <p class="caption grey--text mb-0">{{ $store.state.people.areaAllEn[item.area_num] }} </p>
                        </div>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-tab-item>
      <!-- 曾经在组成员 -->
      <v-tab-item>
        <v-card flat
                v-for="(itemsTemp, index1) in [itemsPostdocFormer, itemsPhdFormer, itemsMasterFormer, itemsUndergraduateFormer]"
                :key="index1">
          <v-card-title class="justify-center mt-10 pt-5" v-if="itemsTemp.length">
            <span class="title-type">{{ $t('people.titlesFormer.'+index1) }}</span>
          </v-card-title>
          <v-row class="mx-3" v-if="itemsTemp.length">
                <v-col class="py-0" cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-for="(item, index2) in itemsTemp"
                       :key="index2">
                  <v-card class="mt-md-4" color="transparent" flat :href="item.link" target="_blank">
                    <v-row align="start">
                      <v-col cols="3">
                        <v-card flat color="transparent" class="d-flex justify-end">
                          <image-shadow class="justify-space-around" :src="item.portrait_url"
                                        :shadow-hover="item.link_type>0"
                                        :width="imageWidth/2" :shadow-radius="imageRadius/2"></image-shadow>
                        </v-card>
                      </v-col>
                      <v-col cols="9">
                        <v-card flat color="transparent" class="px-md-4" v-if="$i18n.locale=='zh'">
                          <div class="text-wrap">
                            <p class="subtitle-2 mb-0">{{item.name_zh}}</p>
                            <p class="caption grey--text mb-0">{{ item.description_zh }} </p>
                          </div>
                        </v-card>
                        <v-card flat color="transparent" class="px-md-4" v-else>
                          <div class="text-wrap">
                            <p class="subtitle-2 mb-0">{{item.name_en}}</p>
                            <p class="caption grey--text mb-0">{{ item.description_en }} </p>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs>

  </v-container>
</template>

<script>
  export default {
    name: "indexPeople",
    data() {
      return {
        // 页面状态控制变量
        isLoaded: false, // 数据是否加载完全
        imageWidth: 150,
        imageRadius: 75,

        // 页面显示相关数据
        items: [],
        itemsTeacher: [],
        itemsPostdoc: [],
        itemsPhd: [],
        itemsMaster: [],
        itemsUndergraduate: [],
        itemsFormer: [],
        itemsTeacherFormer: [],
        itemsPostdocFormer: [],
        itemsPhdFormer: [],
        itemsMasterFormer: [],
        itemsUndergraduateFormer: [],
      }
    },
    methods: {
      showSize() {
        // console.log(this.$vuetify.breakpoint.name);
      },
      // ----- 后端交互功能 -----
      // 加载数据
      loadData() {
        this.$http.get("/api/read_people/")
          .then(response => {
            this.items = response.data;
            // 遍历数据进行整改
            var self = this;
            this.items.forEach(function (item) {
              // item.portrait_url = item.portrait_url + '?' + new Date().getTime(); // 更改图片链接避免缓存
              if (item.type_num <= 3) {
                if (item.status) {
                  self.itemsTeacher.push(item);
                } else {
                  self.itemsTeacherFormer.push(item);
                }
              } else if (item.type_num == 4) {
                if (item.status) {
                  self.itemsPostdoc.push(item);
                } else {
                  self.itemsPostdocFormer.push(item);
                }
              } else if (item.type_num == 5) {
                if (item.status) {
                  self.itemsPhd.push(item);
                } else {
                  self.itemsPhdFormer.push(item);
                }
              } else if (item.type_num == 6) {
                if (item.status) {
                  self.itemsMaster.push(item);
                } else {
                  self.itemsMasterFormer.push(item);
                }
              } else if (item.type_num == 7) {
                if (item.status) {
                  self.itemsUndergraduate.push(item);
                } else {
                  self.itemsUndergraduateFormer.push(item);
                }
              } else if (item.type_num == 8) {
                if (item.status) {
                  self.itemsFormer.push(item);
                } else {
                  self.itemsFormer.push(item);
                }
              }
              if (item.link_type == 0) {
                item.link = "";
              }
            })

            this.isLoaded = true;
            // console.log(this.itemsPostDocFrmero)
          })
      },
    },
    mounted() {
      // 根据页面大小设置图片大小
      if (this.$refs.container.clientWidth < 600) {
        this.imageWidth = this.$refs.container.clientWidth / 4;
        this.imageRadius = this.$refs.container.clientWidth / 8;
      } else {
        this.imageWidth = this.$refs.container.clientWidth / 8;
        this.imageRadius = this.$refs.container.clientWidth / 16;
      }
      // 加载数据
      this.loadData();
    },
  }
</script>

<style scoped>
  .title-type {
    border-bottom: 1px solid #255cbf;
  }

</style>