<template>
  <v-container fluid class="px-0 py-0">
    <!--轮播图-->
    <swiper ref="mySwiperRef" class="swiper" :options="swiperOption">
      <swiper-slide v-for="(item, index) in swiperData" :key="index">
        <v-sheet :height="carouselHeight">
          <v-sheet height="100%" class="parallax-bg" :style="{backgroundImage:'url(' + item.img + ')'}">
            <v-row class="fill-height parallax-top" align="center" justify="center">
              <!--              <div class=" display-4 white&#45;&#45;text">Welcome to DISC</div>-->
            </v-row>
          </v-sheet>
        </v-sheet>
      </swiper-slide>
      <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
      <!--      <div class="swiper-button-prev swiper-button-white" slot="button-prev"-->
      <!--           @click="$refs.mySwiperRef.$swiper.slidePrev()"></div>-->
      <!--      <div class="swiper-button-next swiper-button-white" slot="button-next"-->
      <!--           @click="$refs.mySwiperRef.$swiper.slideNext()"></div>-->
    </swiper>
    <!-- 实验室简介 -->
    <v-card flat rounded="0" class="text-center card-lab-intro pt-md-6 pt-4"
            :style="{backgroundImage:'url(' + homeBg + ')'}">
      <div class="d-flex justify-center">
        <Roller class="roller hidden-md-and-down my-3"
                style="padding-left:0px !important; font-family:Trebuchet MS;font-size: 2em; font-weight: bold;"
                text="Fudan DISC" :transition="1.5"
                :charList='["F","u","d","a","n","D","I","S","C",]'></Roller>
      </div>
      <div class="font-weight-light display-1" v-if="$i18n.locale=='zh'">复旦大学数据智能与社会计算实验室</div>
      <div class="font-weight-light subtitle-2">Data Intelligence and Social Computing Lab</div>
      <div class="font-weight-light caption grey--text" v-if="$i18n.locale=='en'">Fudan University, Shanghai,
        China
      </div>
      <v-container>
        <v-card-text class="text-left body-1 black--text" v-if="$i18n.locale=='zh'">
          复旦大学数据智能与社会计算实验室研究原创的自然语言处理和机器学习的模型和算法，实现真实环境中的人机协同和交互的应用，具体的研究课题包括跨视觉语言模态的语义理解和内容生成、论辩性文本的理解与生成以及数据驱动的知识建模与推理。另外，实验室旨在推动交叉学科的发展，面向非计算学科，包括社会科学、政治科学、金融、医疗等探索智能化技术应用，设计实现服务于目标领域的计算工具。
        </v-card-text>
        <v-card-text class="text-left body-1" v-else>
          Fudan Data Intelligence and Social Computing (Fudan DISC) Lab aims to develop novel natural language
          processing and machine learning models and algorithms, and explores applications of human-computer
          collaboration and interaction in real environments. Research topics include semantic understanding and content
          generation cross visual and language modalities, argumentative text comprehension and generation, and
          data-driven knowledge modeling and reasoning. In addition, our lab focuses on interdisciplinary studies
          bridging artificitial intelligence and other areas inlcuding social science, political science, finance,
          medicine and so on. We aim to develop intelligent tools for the target areas.
        </v-card-text>
      </v-container>
      <!-- 下拉动图 -->
      <index-home-gif></index-home-gif>
    </v-card>

    <v-container>
      <v-card-text>
        <v-row>
          <!-- 新闻列表 -->
          <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8" class="pr-md-8">
            <index-home-news></index-home-news>
          </v-col>
          <!-- 讲座讯息 -->
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <index-home-seminar></index-home-seminar>
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>

    <v-card flat class="d-flex justify-center mt-10">
      <v-card-title class="pt-0 text-center" width="100%"><span class="title-type">{{$t('home.works')}}</span></v-card-title>
    </v-card>

    <v-container fluid class="px-0 py-0 mt-10">
      <index-home-discopen :annotate-width="windowWidth"></index-home-discopen>
      <!-- 数据标注 -->
      <index-home-annotation :annotate-width="windowWidth"></index-home-annotation>
    </v-container>

<!--    <v-card flat height="100"></v-card>-->

    <!-- Footer -->
    <index-footer></index-footer>
  </v-container>
</template>

<script>
  import indexFooter from "./indexFooter";
  import Roller from "vue-roller";
  import indexHomeNews from "../elements/indexHomeNews";
  import indexHomeGif from "../elements/indexHomeGif";
  import indexHomeSeminar from "../elements/indexHomeSeminar";
  import indexHomeAnnotation from "./elements/indexHomeAnnotation";
  import indexHomeDISCOpen from "./elements/indexHomeDISCOpen";

  export default {
    name: "indexHome",
    components: {
      "index-footer": indexFooter,
      Roller,
      "index-home-news":indexHomeNews,
      "index-home-gif":indexHomeGif,
      "index-home-seminar":indexHomeSeminar,
      "index-home-annotation": indexHomeAnnotation,
      "index-home-discopen": indexHomeDISCOpen,
    },
    data() {
      return {
        // homeBg: require('@/assets/img/backgroud.jpg'),
        homeBg: "/resource/users/lchen/image/home-title-bg.jpg",
        windowWidth: window.innerWidth,
        carouselHeight: window.innerWidth * 1 / 3,
        // images: [require('@/assets/img/合照.jpg'), require('@/assets/img/home1.jpg'), require('@/assets/img/home2.jpg')],
        swiperOption: {
          centeredSlides: true,
          autoplay: {delay: 5000, stopOnLastSlide: false, disableOnInteraction: false},
          speed: 2000, //控制切换的速度
          loop: true,
          effect: 'fade',
          fadeEffect: {crossFade: true},
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            //dynamicBullets: true, // 小圆点的大小
          },
          paginationClickable: true,
          spaceBetween: 30,
          // keyboardControl: true,
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
          preventInteractionOnTransition: false,
        },
        swiperData: [
          {img: "/resource/users/lchen/image/home-swiper-disc.jpg",},
          {img: "/resource/users/lchen/image/home-swiper-zibinyuan.jpg",},
        ],
      }
    },
    methods: {
      onResize() {
        this.isMobile = window.innerWidth < 600;
        this.windowWidth = window.innerWidth;
        if (window.innerWidth < 600) {
          this.carouselHeight = 200;
        } else {
          this.carouselHeight = window.innerWidth * 1 / 3;
        }
      },
    }
    ,
    beforeDestroy() {
      if (typeof window === 'undefined') return
      window.removeEventListener('resize', this.onResize, {passive: true})
    },
    mounted() {
      this.onResize()
      window.addEventListener('resize', this.onResize, {passive: true})
    },
  }
</script>

<style lang="scss" scoped>

  .title-type {
    border-bottom: 2px solid #255cbf;
  }

  * ul {
    padding-left: 0px;
  }

  .v-application ul, .v-application ol {
    padding-left: 0px !important;
  }

  .card-lab-intro {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .parallax-bg {
    position: absolute;
    /*z-index: -100;*/
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .parallax-top {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 40%);
  }

  .footer {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
  }


  .swiper {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 30%);

    .swiper-slide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      box-sizing: border-box;
      padding: 0 80px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
      /*background-color: transparent;*/
      z-index: 10;

      .text {
        max-width: 430px;
        line-height: 1.32;
      }
    }
  }
</style>