<template>
  <v-app>
    <v-app-bar dense app fixed>
      <!-- 隐藏式导航栏 -->
      <v-btn icon color="primary" @click="isShowDrawer=!isShowDrawer">
        <v-icon small>menu</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="isShowDrawer" fixed temporary>
      <v-list nav dense class="mb-4">
        <v-list-item>
          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" href="/" target="_self">
                  <v-icon>home</v-icon>
                </v-btn>
              </template>
              <span>回到首页</span>
            </v-tooltip>
          </div>
          <v-spacer></v-spacer>

          <v-spacer></v-spacer>
          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="logout()">
                  <v-icon>exit_to_app</v-icon>
                </v-btn>
              </template>
              <span>退出登录</span>
            </v-tooltip>
          </div>
        </v-list-item>
      </v-list>
      <!-- 头像及用户信息 -->
      <v-layout column align-center class="my-5 text-center">
        <v-flex>
          <v-avatar size="100" class="elevation-10">
            <v-img :src="portrait_url + '?' + new Date().getTime()"></v-img>
          </v-avatar>
          <p class="grey--text font-weight-boldsubtitle mt-6">
            {{ name }}
          </p>
          <p class="grey--text caption">
            {{ authority }}
          </p>
        </v-flex>
      </v-layout>
      <!-- 功能选项 -->
      <v-list nav dense>
        <v-list-item link to="/lchen/pingan">
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>平安复旦</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/lchen/info" >
        <v-list-item-icon>
          <v-icon>mdi-account-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>班级信息</v-list-item-title>
      </v-list-item>

      </v-list>

      <!-- 放到末尾的 -->
      <template v-slot:append>
      </template>

    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

  export default {
    name: "lchenEntry",
    data() {
      return {
        isShowDrawer: false,
        isMobile: false,

        name: "",
        authority: "",
        portrait_url: "",
      }
    },
    methods: {
      onResize() {
        console.log(window.innerWidth);
        this.isMobile = window.innerWidth < 400
      },
    },
    beforeDestroy() {
      if (typeof window === 'undefined') return
      window.removeEventListener('resize', this.onResize, {passive: true})
    },
    mounted() {
      if (localStorage.is_login) {
        this.name = localStorage.name_zh;
        this.authority = localStorage.authority;
        this.portrait_url = localStorage.portrait_url;
      }
      this.onResize();
      window.addEventListener('resize', this.onResize, {passive: true});
    },
  }
</script>

<style scoped>

</style>