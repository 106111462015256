<template>
  <v-app>
    <!-- 导航栏 -->
    <v-app-bar app dense>
      <!-- 回到首页按钮 -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" to="/">
            <v-icon>home</v-icon>
          </v-btn>
        </template>
        <span>{{$t('login.home')}}</span>
      </v-tooltip>
      <!-- 切换语言菜单 -->
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="grey" v-bind="attrs" v-on="on">
            <v-icon small>translate</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-for="(item, index) in languageItems" :key="index" link>
            <v-list-item-content @click="switchLanguage(item)"> {{ item }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <!-- 消息提示框 -->
      <v-snackbar class="caption" absolute top text color="primary" :timeout="2000" v-model="isShowSnackbar">
        <v-row align="center">
          <v-col class="py-0" v-if="isSubmitSuccess">
            <v-icon small color="green">check_circle</v-icon>
          </v-col>
          <v-col class="py-0" v-else>
            <v-icon small color="red">cancel</v-icon>
          </v-col>
          <v-col cols="9" class="pa-0">
            <div class="caption" v-for="(message, i) in messages" :key="i">{{message}}</div>
          </v-col>
        </v-row>
        <template v-slot:action="{ attrs }">
          <v-btn icon text v-bind="attrs" @click="isShowSnackbar = false">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-container fill-height>
        <!-- 登录表单填写 -->
        <v-row align="center" justify="center">
          <v-col xs="10" sm="10" md="8" lg="7" xl="6">
            <v-card class="elevation-4 rounded-lg pa-md-10" min-height="30rem">
              <v-row class="fill-height" align="center">
                <v-col>
                  <v-card-text class="pa-10">
                    <v-card-title class="login-title">
                      <h4>{{ $t('login.title') }}</h4>
                    </v-card-title>
                    <v-text-field class="my-4 input-login" v-model="user.username" prepend-icon="person" name="username"
                                  :label="$t('login.username')"></v-text-field>
                    <v-text-field class="my-4 input-login" v-model="user.password" prepend-icon="lock" name="password"
                                  :label="$t('login.password')"
                                  :append-icon="pwShow ? 'mdi-eye' : 'mdi-eye-off'" :type="pwShow ? 'text' : 'password'"
                                  @click:append="pwShow = !pwShow"></v-text-field>
                    <v-row>
                      <v-btn plain depressed color="transparent" class="caption grey--text mx-4" @click="isShowForget=true"> {{
                        $t('login.forget') }}
                      </v-btn>
                    </v-row>
                    <v-row>
                      <v-card-text v-show="isShowForget" class="mx-4 caption primary--text">{{ $t('login.forgetInfo')
                        }}
                      </v-card-text>
                    </v-row>
                    <v-btn dark rounded block color="primary" @click="login" :loading="isLogining" class="my-10">
                      <span class="mx-4 text-capitalize">{{ $t('login.btn') }}</span>
                    </v-btn>


                  </v-card-text>
                </v-col>

              </v-row>

            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>

</template>

<script>
  export default {
    name: "login",
    data() {
      return {
        from: null, // 来自的页面
        languageItems: ["English", "中文"],
        isShowSnackbar: false, // 提示框状态
        isShowForget: false, // 密码提示信息
        isSubmitSuccess: false,
        dialogNotice: false, // 提示框状态
        messages: "", // 提示框信息
        pwShow: false,
        timer: null,
        // 登录信息
        isLogining: false,
        user: {
          username: "",
          password: "",
        },
      }
    },
    methods: {
      keyDown(e) {
        if (e.keyCode == 13) {
          this.login();
        }
      },

      switchLanguage(item) {
        if (item == "English") {
          this.$i18n.locale = "en";
        } else if (item == "中文") {
          this.$i18n.locale = "zh";
        }
      },
      login() {
        this.isLogining = true;
        var userData = JSON.stringify(this.user);
        var self = this;
        console.log(this.csrfToken);
        this.$http.post("/api/login/", userData, {
          headers: {
            'X-CSRFToken': this.csrfToken,
            'content-type': 'application/json'
          }
        })
          .then(response => {
            var data = response.data;
            console.log(response.data);
            if (data.loginStatus == "success") {
              // 在localStorage中添加用户信息 (除非被清除, 否则永久保存, 避免使用)
              localStorage.clear()
              localStorage.setItem('is_login', true);
              localStorage.setItem('token', data.token);
              localStorage.setItem('loginTime', data.loginTime);
              localStorage.setItem('name_zh', data.userInfo.name_zh);
              localStorage.setItem('name_en', data.userInfo.name_en);
              localStorage.setItem('uid', data.userInfo.uid);
              localStorage.setItem('username', data.userInfo.username);
              localStorage.setItem('authority', data.userInfo.authority);
              localStorage.setItem('portrait_url', data.userInfo.portrait_url);
              this.isLogining = false;
              this.isSubmitSuccess = true;
              this.isShowSnackbar = true;
              this.messages = [this.$t('login.success')];
              this.timer = setInterval(() => {
                // 跳转到选人加分界面
                clearInterval(this.timer)
                this.timer = null
                if (this.from) {
                  this.$router.push(this.from)
                } else {
                  this.$router.push('/')
                }
              }, 500)
            } else {
              this.isSubmitSuccess = false;
              if (data.loginStatus == "fail") {
                this.messages = [this.$t('login.fail')];
              } else {
                this.messages = [this.$t('login.inexistence')];
              }
            }
            this.isLogining = false;
            this.isShowSnackbar = true;
          })
      },

      // 关闭提示信息界面
      closeNotice() {
        this.dialogNotice = false;
        this.messages = [];
      },
    }
    ,
    mounted() {
      window.addEventListener('keydown', this.keyDown);
      this.from = this.$router.history.current.query.redirect;
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.keyDown, false);
    }
  }
</script>

<style scoped>
  /*.input-login {*/
  /*  line-height: 3rem;*/
  /*  font-size: 14px;*/
  /*}*/

  /*.login-title {*/
  /*  border-right: thick double #255cbf;*/
  /*}*/

</style>