<template>
  <v-container fluid>
    <!-- 导航界面 -->
    <v-navigation-drawer fixed class="pt-11 hidden-sm-and-down" style="z-index: 1;" permanent>
        <v-list dense>
          <v-list-item dense light :class="'caption anchor-'+anchor.indent"
                       v-for="(anchor,i) in titles" :key="i"
                       :style="{ padding: `0px 10px 0px ${(anchor.indent+1) * 10}px` }"
                       @click="handleAnchorClick(anchor)">
            {{ anchor.title }}
          </v-list-item>
        </v-list>
    </v-navigation-drawer>
    <v-row>
      <v-col class="pa-0 hidden-sm-and-down" style="flex-grow: 0;">
        <div style="width: 256px;"></div>
      </v-col>
      <v-col class="pa-0">
        <v-container>
          <v-card elevation="4">
            <v-card-text>
              <div flat class="title-article mb-4" v-if="isLoaded">
                <v-card-title class="py-0 black--text">{{info.title}}</v-card-title>
                <v-card-title class="caption py-0"> {{convertTime(info.time3)}}</v-card-title>
                <v-card-title class="caption py-0"> 供稿 丨 {{info.authors.join(" ")}}</v-card-title>
                <v-card-title class="caption py-0"> 编辑 丨 {{info.editors.join(" ")}}</v-card-title>
              </div>

              <v-md-editor mode="preview" ref="editor" height="100%" v-model="content" class="px-md-5"
                           @copy-code-success="handleCopyCodeSuccess"></v-md-editor>

            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
  const text = `
# heading 1
contentcontentcontent
contentcontentcontent
contentcontentcontent
contentcontentcontent
contentcontentcontent

## heading 2
contentcontentcontent
contentcontentcontent
contentcontentcontent
contentcontentcontent
contentcontentcontent

### heading 3
contentcontentcontent
contentcontentcontent
contentcontentcontent
contentcontentcontent
contentcontentcontent

## heading 2
contentcontentcontent
contentcontentcontent
contentcontentcontent
contentcontentcontent
contentcontentcontent

### heading 3
contentcontentcontent
contentcontentcontent
contentcontentcontent
contentcontentcontent
contentcontentcontent
`;

  export default {
    name: "pageBlog",
    data() {
      return {
        isLoading: false,
        isLoaded: false,
        text: text,


        peopleInfo: {}, // 用户信息
        linkType: 0, // 链接类别
        content: "", // 显示内容
        hljsCss: 'agate', // markdown主题

        items: [
          ['mdi-email', 'Inbox'],
          ['mdi-account-supervisor-circle', 'Supervisors'],
          ['mdi-clock-start', 'Clock-in'],
        ],

        titles: [],
        menuItem: {index: -1, text: "", children: []},
        menuItemParent: {index: -1, text: "", children: []},
        menuItemParentParent: {index: -1, text: "", children: []},
        menuItemSib: [],
      }
    },
    methods: {
      test() {

      },
      // 加载标题
      locateTitle() {
        const anchors = this.$refs.editor.$el.querySelectorAll(
          '.v-md-editor-preview h1,h2,h3,h4,h5,h6'
        );
        console.log(anchors);
        const titles = Array.from(anchors).filter((title) => !!title.innerText.trim());
        if (!titles.length) {
          this.titles = [];
          return;
        }
        console.log(titles);
        const hTags = Array.from(new Set(titles.map((title) => title.tagName))).sort(); // 对页面存在的h类型进行排序
        const menuItems = titles.map((el) => ({
          title: el.innerText,
          lineIndex: el.getAttribute('data-v-md-line'),
          indent: hTags.indexOf(el.tagName),
        }));
        console.log(menuItems);
        this.titles = menuItems;
        // const titles = Array.from(anchors).filter((title) => !!title.innerText.trim());
        // if (!titles.length) {
        //   this.titles = [];
        //   return;
        // }
        // console.log(titles);
        //   const titles = Array.from(anchors).filter((title) => !!title.innerText.trim());
        //
        //   if (!titles.length) {
        //     this.titles = [];
        //     return;
        //   }
        //
        //   console.log(this.titles);
        //
        //   const hTags = Array.from(new Set(titles.map((title) => title.tagName))).sort();
        //
        //   this.titles = titles.map((el) => ({
        //     title: el.innerText,
        //     lineIndex: el.getAttribute('data-v-md-line'),
        //     indent: hTags.indexOf(el.tagName),
        //   }));
      },

      // 加载数据
      loadData() {
        this.aid = this.$route.params.pathMatch;
        this.$http.get("/api/read_article/?aid=" + this.aid)
          .then(response => {
            this.info = response.data;
            this.content = this.info.markdown;
            this.isLoaded = true;
            this.$nextTick(() => {
              this.locateTitle();
            });
          })
      },

      // 转换时间
      convertTime(timeStamp) {
        if (timeStamp) {
          var timeStampObj = new Date(timeStamp)
          return timeStampObj.toLocaleDateString().replace(/\//g, "-") + " " + timeStampObj.toTimeString().substr(0, 8);
        } else {
          return null;
        }
      },

      // 复制markdown中代码块
      handleCopyCodeSuccess(code) {
      },

      // 处理markdown中的title
      handleAnchorClick(anchor) {
        const {editor} = this.$refs;
        const {lineIndex} = anchor;

        const heading = editor.$el.querySelector(
          `.v-md-editor-preview [data-v-md-line="${lineIndex}"]`
        );

        if (heading) {
          editor.previewScrollToTarget({
            target: heading,
            scrollContainer: window,
            top: 60,
          });
        }
      },

    },

    mounted() {
      this.loadData();

    },
    updated() {

    }

  }
</script>

<style scoped>

  .title-article {
    border-left: 4px solid #255cbf;
  }

  .anchor-0 {
    font-weight: bold;
  }

  .anchor-1 {
    font-weight: normal;
  }




</style>