<template>
  <v-container>
    <!-- html页面 -->
    <v-card flat v-if="isLoaded & linkType==1">
      <v-card flat >
        <v-row >
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <v-layout column align-center >
              <v-flex class="my-2  text-center" :class="{'fixedCard': $vuetify.breakpoint.mdAndUp}">
                <div class="d-flex justify-center">
                  <image-shadow :src="peopleInfo.portrait_url" :width="150" :shadow-radius="75"></image-shadow>
                </div>
                <div class="mt-5 mb-2 text-wrap" v-if="$i18n.locale=='zh'">
                  <p class="subtitle-1 mb-0 font-weight-bold">{{peopleInfo.name_zh}}</p>
                  <p class="subtitle-2 mb-0 grey--text text-wrap">{{ $store.state.people.areaAllZh[peopleInfo.area_num]
                    }}</p>
                  <p class="caption grey--text mb-0">{{ $store.state.people.typeAllZh[peopleInfo.type_num] }} </p>
                </div>
                <div class="mt-10 mb-4 text-wrap" v-else>
                  <p class="subtitle-1 mb-0 font-weight-bold">{{peopleInfo.name_en}}</p>
                  <p class="subtitle-2 mb-0 grey--text text-wrap">{{ $store.state.people.areaAllEn[peopleInfo.area_num]
                    }}</p>
                  <p class="caption grey--text mb-0">{{ $store.state.people.typeAllEn[peopleInfo.type_num] }} </p>
                </div>
                <div v-if="peopleInfo.email">
                  <v-icon small color="grey" class="material-icons-outlined lighten-4">mail</v-icon>
                  <span class="caption grey--text ml-2">{{ peopleInfo.email }}</span>
                </div>
                <div v-if="peopleInfo.location_zh && $i18n.locale=='zh'">
                  <v-icon small color="grey" class="material-icons-outlined lighten-4">place</v-icon>
                  <span class="caption grey--text ml-2">{{ peopleInfo.location_zh }}</span>
                </div>
                <div v-if="peopleInfo.location_en && $i18n.locale=='en'">
                  <v-icon small color="grey" class="material-icons-outlined lighten-4">place</v-icon>
                  <span class="caption grey--text ml-2">{{ peopleInfo.location_en }}</span>
                </div>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
      </v-card>
      <v-row align="start">
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"></v-col>

        <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
          <v-md-editor mode="preview" height="100%" v-model="content" class="px-md-5"></v-md-editor>
        </v-col>
      </v-row>
    </v-card>
    <v-card flat align="center" v-html="content" v-if="isLoaded & linkType==2"></v-card>
  </v-container>
</template>

<script>
  export default {
    name: "pagePeople",
    data() {
      return {
        isLoading: false,
        isLoaded: false,
        peopleInfo: {}, // 用户信息
        linkType: 0, // 链接类别
        content: "", // 显示内容
        hljsCss: 'agate', // markdown主题

      }
    },
    methods: {
      test() {

      },
      loadData() {
        this.username = this.$route.params.pathMatch;
        this.$http.get("/api/read_people/?username=" + this.username)
          .then(response => {
            this.peopleInfo = response.data;
            document.title = "Fudan DISC | " + this.peopleInfo.name_en + "'s Homepage";
            this.linkType = this.peopleInfo.link_type;
            this.content = this.peopleInfo["link" + this.linkType];
            this.isLoaded = true;
            console.log(response.data);
          })
      },
    },

    mounted() {
      this.loadData();
    },
    computed: {}
  }
</script>

<style scoped>
  .fixedCard {
    position: fixed;
  }

</style>