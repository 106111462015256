<template>
  <v-app>
    <v-app-bar dense app fixed v-if="isMobile">
      <!-- 隐藏式导航栏 -->
      <v-btn icon color="primary" @click="isChangeDrawer=!isChangeDrawer">
        <v-icon small>menu</v-icon>
      </v-btn>
    </v-app-bar>
      <nav-console :is-mobile="isMobile" :is-change-drawer="isChangeDrawer" ></nav-console>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  import consoleNav from "./consoleNav";
  export default {
    name: "consoleEntry",
    components: {"nav-console": consoleNav},
    data(){
      return {
        isMobile: false,  // 初始化
        isChangeMobile: false, // 监控变化
        isChangeDrawer: false,  // 监控变化
      }
    },
    methods: {
      onResize() {
        console.log(window.innerWidth);
        this.isMobile = window.innerWidth < 800
      },
    },
    beforeDestroy() {
      if (typeof window === 'undefined') return
      window.removeEventListener('resize', this.onResize, {passive: true})
    },
    mounted() {
      this.onResize();
      window.addEventListener('resize', this.onResize, {passive: true});
    },
  }
</script>

<style scoped>

</style>