<template>
  <v-container>
    <v-list dense v-if="isLoaded">
      <v-list-item  class="py-5" v-for="(item, index) in items" :key="index">
        <v-hover v-slot="{ hover }">
          <v-card width="100%" rounded="0" :elevation="hover ? 16 : 4" :class="{'on-hover': hover, 'transition-swing':true}" :to="'/news/'+item.aid" target="_blank">
            <v-row>
              <v-col class="py-0" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                <v-img contain :src="item.image_url"></v-img>
              </v-col>
              <v-col class="py-0" cols="12" xs="12" sm="12" md="7" lg="7" xl="7">
                <v-card-title class="py-md-6 pt-1 pb-0">
                  {{item.title}}
                </v-card-title>
              </v-col>
              <v-col class="py-0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                <v-card-title right class="py-md-6 mt-md-1 pt-0 pb-1 caption font-weight-light">
                  {{convertTime(item.time3)}}
                </v-card-title>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
  export default {
    name: "indexNews",
    data() {
      return {
        // 页面状态控制变量
        isLoaded: false, // 数据是否加载完全

        // 页面显示相关数据
        items: [],
      }
    },
    methods: {
      // 时间转换
      convertTime(timeStamp) {
        if (timeStamp) {
          var timeStampObj = new Date(timeStamp)
          // return timeStampObj.toLocaleDateString().replace(/\//g, "-") + " " + timeStampObj.toTimeString().substr(0, 8);
          return timeStampObj.toLocaleDateString().replace(/\//g, "-");
        } else {
          return null;
        }
      },

      // 加载数据
      loadData() {
        this.$http.get("/api/read_article/")
          .then(response => {
            this.items = response.data;
            this.isLoaded = true;
            // console.log(this.items);
          })
      },


    },
    mounted() {
      this.loadData();
    }
  }
</script>

<style scoped>
  .v-card {
    transition: opacity .8s ease-in-out;
  }

  .v-card:not(.on-hover) {
    opacity: 0.7;
  }

</style>