<template>
  <v-container>
    <!-- 无需确认的提示框 snackbar 控制变量为 isShowSnackbar -->
    <v-snackbar top text color="primary" :timeout="2000" v-model="isShowSnackbar">
      <v-row align="center">
        <v-col class="py-0" v-if="isSubmitSuccess">
          <v-icon small color="green">check_circle</v-icon>
        </v-col>
        <v-col class="py-0" v-else>
          <v-icon small color="red">cancel</v-icon>
        </v-col>
        <v-col cols="9" class="pa-0">
          <div class="caption" v-for="(message, i) in messages" :key="i">{{message}}</div>
        </v-col>
      </v-row>
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="isShowSnackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- 需要确认的提示框 dialog 控制变量为 isShowDialog-->
    <v-dialog v-model="isShowDialog" max-width="500px">
      <v-card clas="text-center">
        <v-card-title class="dialog-text justify-center">确认删除 {{editedItem.name_zh}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="closeDelete">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="deleteItemConfirm">确认</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 需要确认的提示框 dialog 控制变量为 isShowDialogStatus-->
    <v-dialog v-model="isShowDialogStatus" max-width="500px">
      <v-card clas="text-center">
        <v-card-title class="dialog-text justify-center">{{dialogMessage}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="isShowDialogStatus=false">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="statusItemConfirm">确认</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 图片上传对话框 -->
    <my-upload field="img" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess"
               @crop-upload-fail="cropUploadFail" v-model="isShowUpload"
               :width="600" :height="600" url="" :params="imgParams" :headers="imgHeaders" img-format="png">
    </my-upload>

    <!-- 全部人员数据表格 -->
    <v-card>
      <v-card-text>
        <v-btn depressed class="mb-4" @click="addItem()">
          <v-icon left>add</v-icon>
          <span>添加人员</span>
        </v-btn>
        <v-data-table :headers="headers" :items="items" :sort-by="['pid','type_num', 'type', 'uid']"
                      :sort-desc="[false, false, false]"
                      :page.sync="page" :items-per-page="itemsPerPage" class="elevation-1" hide-default-footer
                      @page-count="pageCount = $event" :loading="isLoading">

          <!-- 表格中的操作按钮 -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="mx-1" small @click="editItem(item)">mdi-pencil</v-icon>
              </template>
              <span>编辑</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="mx-1" small @click="deleteItem(item)">mdi-delete</v-icon>
              </template>
              <span>删除</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.status">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="light-green" class="mx-1" v-bind="attrs" v-on="on" small
                        @click="statusToFormer(item)">task_alt
                </v-icon>
              </template>
              <span>切换离组</span>
            </v-tooltip>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="red" class="mx-1" v-bind="attrs" v-on="on" small
                        @click="statusToIn(item)">logout
                </v-icon>
              </template>
              <span>切换在组</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <!-- 表格翻页按钮 -->
        <v-card flat class="text-center my-3">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
          <!-- <v-text-field :value="itemsPerPage" label="Items per page" type="number" min="0" max="12"
                        @input="itemsPerPage = parseInt($event, 10)"></v-text-field> -->
        </v-card>
      </v-card-text>
    </v-card>

    <!-- 新增/编辑人员信息表单 -->
    <v-card v-if="isShowEditor" class="mt-3">
      <v-card-text>
        <!-- 表单首行 -->
        <v-row align="center">
          <v-card-title>
            <v-card flat>{{ formTitle }}</v-card>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="isShowEditor=false" class="mr-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider></v-divider>
        <!-- 表单填写部分 -->
        <v-form ref="form">
          <v-row>
            <v-col md="6">
              <v-text-field filled label="学工号 ID" value="" :rules="[rules.required]"
                            v-model="editedItem.uid"></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field filled label="用户名 Username" value="" :rules="[rules.required]"
                            v-model="editedItem.username"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <v-text-field filled label="姓名 Name (zh)" value="" :rules="[rules.required]"
                            v-model="editedItem.name_zh"></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field filled label="英文名 Name (en)" value="" :rules="[rules.required]"
                            v-model="editedItem.name_en"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <v-text-field filled label="密码 Password" v-model="editedItem.password"
                            :append-icon="pwShow ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]" :type="pwShow ? 'text' : 'password'"
                            name="input-10-1" hint="至少 8 位" counter
                            @click:append="pwShow = !pwShow"></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field filled label="再次输入密码 Password Confirm" v-model="editedItem.password2"
                            :append-icon="pwShow2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.match]" :type="pwShow2 ? 'text' : 'password'"
                            name="input-10-1" hint="至少 8 位" counter
                            @click:append="pwShow2 = !pwShow2"></v-text-field>
            </v-col>
          </v-row>

          <!-- 表单选项区域 -->
          <v-row style="margin-top: -20px; z-index: 999;">
            <v-col md="3">
              <v-select offset-y v-model="editedItem.type" :items="typeAll" label="类型 Type">
                <template v-slot:selection="{ item }">
                  <v-chip small dark class="primary">
                    <span>{{ item }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col md="3">
              <v-select v-model="editedItem.entrance" :items="entranceAll" label="入学年份 Entrance">
                <template v-slot:selection="{ item }">
                  <v-chip small dark class="primary">
                    <span>{{ item }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col md="3">
              <v-select v-model="editedItem.area" :items="areaAll" label="研究方向 Area">
                <template v-slot:selection="{ item }">
                  <v-chip small dark class="primary">
                    <span>{{ item }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col md="3">
              <v-select v-model="editedItem.authority" :items="authorityAll" label="权限 Authority">
                <template v-slot:selection="{ item }">
                  <v-chip small dark class="primary">
                    <span>{{ item }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn depressed rounded class="my-3" @click="isShowUpload=true">上传头像</v-btn>
            <v-spacer></v-spacer>
          </v-row>
          <v-row align-content="center" justify="center" class="my-4">
            <v-avatar size="200" class="elevation-10">
              <v-img :src="computePortraitUrl" ref="imageForm"></v-img>
            </v-avatar>
          </v-row>
        </v-form>
        <!-- 表单按钮区域 -->
        <v-row justify="center">
          <v-col md="2">
            <v-btn color="primary" dark block class="my-2" @click="submit" :loading="isSubmitting">保 存</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import myUpload from 'vue-image-crop-upload';

  export default {
    name: "consolePeople",
    components: {"my-upload": myUpload},
    data() {
      return {
        // 页面状态控制变量
        isShowSnackbar: false,
        isShowDialog: false,
        isShowDialogStatus: false,
        isShowEditor: false,
        isLoading: false,
        isSubmitting: false,
        isSubmitSuccess: false,
        messages: [],
        dialogMessage: "",

        // 表格数据
        page: 1, // 默认所在页数
        pageCount: 0,
        itemsPerPage: 10,
        loadingData: true,
        headers: [
          // {text: '序号', sortable: true, value: 'pid', width: "10%"},
          {text: '类型', value: 'type'},
          {text: '学工号', value: 'uid'},
          {text: '用户名', value: 'username', sortable: false},
          {text: '姓名', value: 'name_zh'},
          // {text: '英文名', value: 'name_en'},
          {text: '研究方向', value: 'area'},
          {text: '操作', value: 'actions', sortable: false, align: 'center', width: "15%"},
        ],
        items: [], // 通过api获取初始化数据
        editedIndex: -1,

        // 表单数据选项
        typeAll: this.$store.state.people.typeAllZh,
        areaAll: this.$store.state.people.areaAllZh,
        entranceAll: this.$store.state.people.entranceAll,
        authorityAll: this.$store.state.people.authorityAll,

        // 表单密码输入区域
        pwShow: false,
        pwShow2: false,
        rules: {
          required: value => !!value || '必填',
          min: v => v.length >= 8 || '最少8位密码',
          match: v => v == this.editedItem.password || '两次密码输入需保持一致',
        },

        // 表单图片上传
        isShowUpload: false,
        imgParams: {
          token: '123456798',
          name: 'avatar'
        },
        imgHeaders: {
          smail: '*_~'
        },

        // 当前编辑的数据
        editedItem: {},
        // 默认的空白编辑数据, 便于编辑已有数据后再返回空白
        defaultItem: {
          pid: -1,
          uid: "",
          username: "",
          status: true,
          name_zh: "",
          name_en: "",
          password: "",
          password2: "",
          type: "",
          type_num: -1,
          area: "",
          area_num: -1,
          entrance: "",
          authority: "",
          portrait_url: "/resource/public/portrait/default.png",
        },
      }
    },

    methods: {
      // ----- 按钮功能 -----
      // 添加人员按钮功能
      addItem() {
        this.isShowEditor = true; // 显示编辑界面
        this.editedItem = Object.assign({}, this.defaultItem); // 初始化空编辑对象
      },

      // 编辑人员按钮功能
      editItem(item) {
        this.isShowEditor = true;
        this.editedItem = Object.assign({}, item);
      },

      // 删除人员按钮功能
      deleteItem(item) {
        this.isShowDialog = true;
        this.editedItem = Object.assign({}, item);
      },

      // 转换为离组状态
      statusToFormer(item) {
        this.dialogMessage = "切换为离组状态";
        this.isShowDialogStatus = true;
        this.editedItem = Object.assign({}, item);
      },

      // 转换为在组状态
      statusToIn(item) {
        this.dialogMessage = "切换为在组状态";
        this.isShowDialogStatus = true;
        this.editedItem = Object.assign({}, item);
      },

      // 确认发布数据
      statusItemConfirm() {
        this.editedItem.status = !this.editedItem.status;
        this.editedItem.statusstr = this.editedItem.status ? "修改为在组状态" : "修改为离组状态";
        var formData = new FormData(); //创建表单数据
        for (let key in this.editedItem) {
            formData.append(key, this.editedItem[key])
          }
        this.$http.put("/api/people/?pid=" + this.editedItem.pid, formData,
          {headers: {"Authorization": "Token " + localStorage.token, 'content-type': 'multipart/form-data'}})
          .then(response => {
            this.isSubmitting = false; // 收到响应关闭按钮上传状态
            if (response.data == "success") {
              this.messages = [this.dialogMessage + "成功"];
              this.isSubmitSuccess = true; // 显示成功图标
              this.isShowSnackbar = true; // 显示消息提示框
              this.isShowDialogStatus= false; // 关闭发布界面
              this.loadData();      // 重新加载数据
            }
          })
      },

      // 关闭删除界面
      closeDelete() {
        this.isShowDialog = false;
      },

      // ----- 后端交互功能 -----
      // 加载数据
      loadData() {
        this.isLoading = true;
        this.$http.get("/api/people/", {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
            this.items = response.data;
            this.isLoading = false;
            for (let i = 0; i < this.items.length; i++) {
              if (this.items[i].portrait_url == null) {
                this.items[i].portrait_url = this.defaultItem.portrait_url;
              }
            }
            console.log(this.items[0])
          })

      },

      // 提交数据
      submit() {
        if (this.$refs.form.validate()) { // 表单数据合格开始上传
          this.isSubmitting = true; // 显示按钮上传状态

          this.editedItem.type_num = this.typeAll.indexOf(this.editedItem.type); // 得到type_num的值 (便于排序)
          this.editedItem.area_num = this.areaAll.indexOf(this.editedItem.area); // 得到area_num的值 (便于排序)
          var formData = new FormData(); //创建表单数据
          for (let key in this.editedItem) {
            formData.append(key, this.editedItem[key])
          }
          if (this.editedItem.pid == -1) {
            // 对于新增的数据 使用POST请求
            this.$http.post("/api/people/", formData,
              {headers: {"Authorization": "Token " + localStorage.token, 'content-type': 'multipart/form-data'}})
              .then(response => {
                this.isSubmitting = false; // 收到响应关闭按钮上传状态
                if (response.data == "success") {
                  this.messages = ["保存成功"]
                  this.isSubmitSuccess = true; // 显示成功图标
                  this.isShowSnackbar = true; // 显示消息提示框
                  this.isShowEditor = false;  // 关闭编辑界面
                  this.loadData();     // 重新加载数据
                } else {
                  this.isSubmitSuccess = false; // 显示失败图标
                  this.messages = []; // 清空消息缓存
                  var errors = response.data;
                  for (let key in errors) {
                    this.messages.push(key + ": " + errors[key])
                  }
                  this.isShowSnackbar = true;
                }
              })
          } else {
            // 对于修改的数据 使用PUT请求
            this.$http.put("/api/people/?pid=" + this.editedItem.pid, formData,
              {headers: {"Authorization": "Token " + localStorage.token, 'content-type': 'multipart/form-data'}})
              .then(response => {
                this.isSubmitting = false; // 收到响应关闭按钮上传状态
                if (response.data == "success") {
                  this.messages = ["保存成功"]
                  this.isSubmitSuccess = true; // 显示成功图标
                  this.isShowSnackbar = true; // 显示消息提示框
                  this.isShowEditor = false;  // 关闭编辑界面
                  this.loadData();      // 重新加载数据
                } else {
                  this.isSubmitSuccess = false; // 显示失败图标
                  this.messages = [];   // 清空消息缓存
                  var errors = response.data;
                  for (let key in errors) {
                    this.messages.push(key + ": " + errors[key])
                  }
                  this.isShowSnackbar = true;
                }
              })
          }
        }
      },

      // 确认删除数据
      deleteItemConfirm() {
        this.$http.delete("/api/people/?pid=" + this.editedItem.pid,
          {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
            this.messages = ["删除成功"];
            this.isSubmitSuccess = true; // 显示成功图标
            this.isShowDialog = false;  // 关闭删除提示框
            this.isShowSnackbar = true; // 进行消息提示
            this.loadData();            // 重新加载数据
          })
      },

      // 插件功能
      // 头像上传
      cropSuccess(imgDataUrl, field) {
        console.log('-------- crop success --------');
        this.editedItem.portrait_url = imgDataUrl;
        console.log(this.imgDataUrl);
      },
      cropUploadSuccess(jsonData, field) {
        console.log('-------- upload success --------');
        console.log(jsonData);
        console.log('field: ' + field);
      },
      cropUploadFail(status, field) {
        console.log('-------- upload fail --------');
        console.log(status);
        console.log('field: ' + field);
      },

    },
    computed: {
      formTitle() {
        return this.editedItem.pid === -1 ? '添加人员' : '编辑人员 ' + this.editedItem.name_zh
      },
      computePortraitUrl() {
        if (this.editedItem.portrait_url[0] == "/") {
          return this.editedItem.portrait_url + "?" + new Date().getTime();
        } else {
          return this.editedItem.portrait_url;
        }
      }
    },
    mounted() {
      this.loadData();
    },

  }
</script>

<style scoped>

</style>