<template>
  <v-container>
    <!-- 无需确认的提示框 snackbar 控制变量为 isShowSnackbar -->
    <v-snackbar top text color="primary" :timeout="2000" v-model="isShowSnack">
      <v-row align="center">
        <v-col class="py-0" v-if="isSnackSuccess">
          <v-icon small color="green">check_circle</v-icon>
        </v-col>
        <v-col class="py-0" v-else>
          <v-icon small color="red">cancel</v-icon>
        </v-col>
        <v-col cols="9" class="pa-0">
          <div class="caption" v-for="(message, i) in snackMessages" :key="i">{{message}}</div>
        </v-col>
      </v-row>
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="isShowSnack = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- 添加界面 dialog 控制变量为 isShowAdd-->
    <v-dialog v-model="isShowAdd" width="500" persistent>
      <v-card>
        <v-card-title class="card-title grey lighten-2">添加信息页</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row class="mt-4">
              <v-col cols="12" class="py-0">
                <v-text-field v-model="addItem.aspect" label="信息类型 Aspect"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-row class="ma-0 pa-0">
          <v-col cols="6">
            <v-btn color="primary" block text @click="isShowAdd=false">取消</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn color="primary" block text @click="saveAdd" :loading="isSavingAdd">添加</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- 需要确认的提示框 dialog 控制变量为 isShowDelete-->
    <v-dialog v-model="isShowDelete" max-width="500px">
      <v-card clas="text-center">
        <v-card-title class="dialog-text justify-center">{{formDeleteTitle}}</v-card-title>
        <v-card-actions>
          <v-row class="ma-0 pa-0">
            <v-col cols="6">
              <v-btn color="primary" block text @click="isShowDelete=false">取消</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn color="primary" block text @click="deleteItem" :loading="isSavingDelete">确认</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 排序界面 dialog 控制变量为 isShowOrder-->
    <v-dialog v-model="isShowOrder" width="500" persistent>
      <v-card>
        <v-card-title class="card-title grey lighten-2">拖动排序</v-card-title>
        <v-card-text class="py-4 px-12">

          <draggable
              class="list-group px-0"
              tag="ul"
              :animation="200"
              ghost-class="ghost"
              v-model="items"
              v-bind="dragOptions"
              @start="isDragging = true"
              @end="isDragging = false"
          >
            <transition-group type="transition" name="flip-list">
              <li class="list-group-item pa-2 my-2" v-for="(element, index) in items" :key="index">
                <i :class="element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'"
                   @click="element.fixed = !element.fixed" aria-hidden="true">
                  <span class="pr-2 primary--text"> {{element.order+1}}</span> <span>{{ element.aspect }}</span>
                </i>
              </li>
            </transition-group>
          </draggable>
        </v-card-text>
        <v-divider></v-divider>
        <v-row class="ma-0 pa-0">
          <v-col cols="6">
            <v-btn color="primary" block text @click="cancleOrder">取消排序</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn color="primary" block text @click="saveOrder" :loading="isSavingOrder">确认</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>


    <!-- 按钮行 -->
    <v-row v-if="isAuthority">
      <v-col cols="3">
        <v-btn depressed block @click="isShowAdd=true">
          <div>
            <v-icon left>add</v-icon>
            <span>添加</span>
          </div>
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn depressed block @click="isShowDelete=true">
          <div>
            <v-icon left>delete</v-icon>
            <span>删除</span>
          </div>
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn depressed block @click="isShowOrder=true">
          <div>
            <v-icon left>toc</v-icon>
            <span>排序</span>
          </div>
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn depressed @click="isEditing=true" block v-if="!isEditing">
          <div>
            <v-icon left>edit</v-icon>
            <span>编辑</span>
          </div>
        </v-btn>
        <v-btn depressed @click="saveEdit" block v-else :loading="isSavingEditing">
          <div >
            <v-icon left>check</v-icon>
            <span>保存并预览</span>
          </div>
        </v-btn>


      </v-col>
    </v-row>


    <v-card flat :loading="isLoading">
      <v-tabs v-model="tab" color="primary" v-if="isLoaded">
        <v-tab v-for="(item, index1) in items" :key="index1">
          {{ item.aspect }}
        </v-tab>
        <v-tab-item v-for="(item, index2) in items" :key="index2">
          <!-- markdown 预览界面 -->
          <v-card flat v-if="!isEditing">
            <v-card flat class="px-2 py-4">
              <v-md-editor mode="preview" height="100%" v-model="item.markdown" class="px-md-5"></v-md-editor>
            </v-card>
          </v-card>
          <!-- markdown 编辑界面 -->
          <v-card flat v-else>
            <v-card flat class="px-2 py-4">
              <v-md-editor v-model="item.markdown" height="600px" :left-toolbar="leftToolbar"></v-md-editor>
            </v-card>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
  import draggable from 'vuedraggable'

  export default {
    name: "consoleInfo",
    components: {"draggable": draggable},
    data() {
      return {
        // 页面控制变量
        isMounted: false,
        isShowSnack: false,
        isSnackSuccess: false,
        isShowAdd: false,
        isShowDelete: false,
        isShowOrder: false,


        // 按钮控制变量
        isSavingAdd: false,
        isSavingOrder: false,
        isSavingEditing: false,
        isSavingDelete: false,
        isLoading: false, // 数据是否正在加载
        isLoaded: true, // 数据是否加载完成

        // 页面消息变量
        snackMessages: [],

        length: 4,
        isAuthority: false, // 是否具有编辑权限
        isEditing: false,  // 是否正在编辑
        aspectsAll: ["财务报销", "数据标注"],
        markdownAll: ["111", "222"],
        // markdown区域参数
        leftToolbar: "undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image code | emoji todo-list tip",
        tab: null, // 初始化所在页面
        tabAspect: "", // 该信息页名称
        items: [],
        addItem: {iid: -1, aspect: "", markdown: ""},
        addItemDefault: {iid: -1, aspect: "", markdown: ""},
      }
    },
    methods: {
      // ----- 页面操作方法 -----

      saveAdd() {
        this.addItem.order = this.items.length;
        this.isSavingAdd = true;
        this.addData(); // 后端增加数据
      },
      saveOrder() {
        this.items.map((item, index, array) => {
          item.order = index;
        })
        this.isSavingOrder = true;
        this.modifyData("order"); // 后端修改数据
      },
      cancleOrder() {
        this.items = this.items.sort((a, b) => a.order - b.order);
        this.isShowOrder = false;
      },
      deleteItem() {
        console.log(this.tab);
        this.isSavingDelte = true;
        this.deleteData(); // 后端删除数据
      },
      saveEdit() {
        this.isSavingEditing = true;
        this.modifyData("edit"); // 后端编辑数据
      },

      // ----- 后端交互方法 -----
      // 加载数据
      loadData(index) {
        this.isLoaded = false;
        this.isLoading = true;
        this.$http.get("/api/info/", {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
              this.items = response.data;
              this.tab = index;
              // 更改加载指示变量
              this.isLoading = false;
              this.isLoaded = true;
            }
          )
      },
      // 修改后端数据
      modifyData(operation) {
        this.$http.put("/api/info/", this.items, {
          headers: {
            "Authorization": "Token " + localStorage.token,
            "Content-Type": "application/json"
          }
        }).then(response => {
            console.log(response.data);
            if (response.data == "success") {
              if (operation == "order") { // 对于排序修改数据
                // 关闭添加页面
                this.isSavingOrder = false;
                this.isShowOrder = false;
              } else if (operation=="edit") { // 对于编辑修改数据
                this.isSavingEditing = false;
                this.isEditing = false;
              }
              // 显示 snackbar 提示
              this.isSnackSuccess = true;
              this.snackMessages = ["修改成功"];
              this.isShowSnack = true;
              // 重新加载数据
              this.loadData(0);
            } else {
              // 显示 snackbar 提示
              this.isSnackSuccess = false;
              this.snackMessages = response.data;
              this.isShowSnack = true;
            }
          }
        )

      },
      // 添加信息页面
      addData() {
        var formData = new FormData(); //创建表单数据
        for (let key in this.addItem) {
          formData.append(key, this.addItem[key])
        }
        this.$http.post("/api/info/", formData, {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
              if (response.data == "success") {
                // 关闭添加页面
                this.isShowAdd = false;
                // 显示 snackbar 提示
                this.isSnackSuccess = true;
                this.snackMessages = ["添加成功"];
                this.isShowSnack = true;
                this.isSavingAdd = false;
                // 重新加载数据
                this.loadData(this.items.length);
                // 重新初始化添加的编辑对象
                this.addItem = Object.assign({}, this.addItemDefault);
              } else {
                // 显示 snackbar 提示
                this.isSnackSuccess = false;
                this.snackMessages = response.data;
                this.isShowSnack = true;
              }
            }
          )
      },
      // 删除后端数据
      deleteData() {
        this.$http.delete("/api/info/?iid=" + this.items[this.tab].iid, {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
              if (response.data == "success") {
                // 关闭删除页面
                this.isShowDelete = false;
                // 显示 snackbar 提示
                this.isSnackSuccess = true;
                this.snackMessages = ["删除成功"];
                this.isShowSnack = true;
                this.isSavingDelte = false;
                // 重新加载数据
                this.loadData(0);
              } else {
                // 显示 snackbar 提示
                this.isSnackSuccess = false;
                this.snackMessages = response.data;
                this.isShowSnack = true;
              }
            }
          )
      },
    },
    mounted() {
      this.isAuthority = localStorage.authority == "管理员";
      this.loadData(0);
      this.isMounted = true;
    },
    computed: {
      formDeleteTitle() {
        return "删除 " + this.tabAspect + " 信息页";
      },
      dragOptions() {
        return {
          animation: 0,
          group: "description",
          disabled: false,
          ghostClass: "ghost"
        };
      }
    },
    watch: {
      tab: {
        handler: function (val, oldVal) {
          this.tabAspect = this.items[val].aspect;
        }
      }
    }

  }
</script>

<style scoped>
  .list-group .flip-list-move {
    transition: transform 0.5s;
  }

  .list-group .flip-list-no-move {
    transition: transform 0.5s;
  }

  .list-group .ghost {
    opacity: 0.1;
    background: #c8ebfb;
  }

  .list-group {
    min-height: 20px;
  }

  .list-group-item {
    cursor: move;
    position: relative;
    display: block;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 5px;
  }

  .list-group-item i {
    cursor: pointer;
    font-style: normal;
  }
</style>