<template>
  <v-card :loading="!isMounted">
    <v-card-title>时间管理</v-card-title>
    <v-card-text>
      <!-- 选择学期 -->
      <v-row v-if="isMounted">
        <v-col cols="4" class="pl-8 pb-0">
          <v-select v-model="selectTerm" :items="termsAll" label="选择学期"></v-select>
        </v-col>
        <v-col class="pl-10 pt-6 pb-0">
          <v-row justify="space-around">
            <v-btn rounded @click="viewAll">查看全部</v-btn>
            <v-btn rounded @click="view">查看清零</v-btn>
          </v-row>
        </v-col>
      </v-row>

      <!-- 名字选择区域 -->
      <v-card class="mt-6" flat>
        <v-row class="mx-4">
          <v-col class="my-0 py-0" cols="12" sm="2" md="2" v-for="(personData, index) in peopleDataRaw[selectTerm]" :key="index">
            <v-checkbox class="small" v-model="peopleList" :label="showNameStatus(personData)" color="indigo"
                        :value="personData.name"
                        hide-details dense></v-checkbox>
          </v-col>
        </v-row>
      </v-card>

      <!-- 图像绘制区域 -->
      <v-card flat id="course"></v-card>
    </v-card-text>
  </v-card>
</template>

<script>
  import * as d3 from "d3"

  export default {
    name: "discTime",
    components: {},
    data() {
      return {
        isMounted: false, // 数据是否加载完全
        termsAll: [], // 所有备选学期
        selectTerm: "", // 选择的学期
        peopleAll: {}, // 所有人员列表
        peopleList: [], // 筛选人员列表
        peopleDataRaw: [], // 与后端交互返回的数据
        peopleDataDraw: {}, // 整合了矩形透明度的数据
        peopleData: [{x: 1, y: 1, occupy: false}, {x: 1, y: 2, occupy: true}], // 显示界面的占用情况
      }
    },
    methods: {
      // 零件函数
      // 是否填写
      showNameStatus(personData) {
        if (personData["occupy"].length) {
          return personData["name"];
        } else {
          return personData["name"]+" (未填)";
        }
      },
      // 查看全部
      viewAll() {
        this.peopleList = this.peopleAll[this.selectTerm];
      },

      // 查看清零
      view() {
        this.peopleList = [];
      },

      // 转换占用时间列表为矩形数据
      convertOccupy(occupyList) {
        var occupyRect = []
        for (let i = 1; i < 8; i++) {
          for (let j = 1; j < 16; j++) {
            var occupyBool = occupyList.filter(item => (item[0] == i) & (item[1] == j)).length > 0; //是否存在于数组中
            occupyRect.push({x: i, y: j, occupy: occupyBool})
          }
        }
        return occupyRect;
      },

      // 形成绘图数据
      formPeopleData(peopleData) {
        // 统计每个单元格数据
        var tempData = [];
        for (let i = 1; i < 8; i++) {
          for (let j = 1; j < 16; j++) {
            var tempSum = 0;
            var tempText = "";
            for (let k = 0; k < peopleData.length; k++) {
              var tempPeople = peopleData[k];
              var occupyList = tempPeople["occupy"]
              var occupyBool = occupyList.filter(item => (item[0] == i) & (item[1] == j)).length > 0;
              if (occupyBool) {
                tempSum += 0.3;
                tempText = tempText + tempPeople["name"] + " "
              }
            }
            tempData.push({x: i, y: j, text: tempText, alpha: tempSum,})
          }
        }
        // 对透明度进行归一化
        var maxAlpha = 0;
        tempData.forEach(function (item, index) {
          if (item["alpha"] > maxAlpha) {
            maxAlpha = item["alpha"];
          }
        })
        if (maxAlpha > 0) {
          tempData.forEach(function (item, index) {
            item["alpha"] = item["alpha"] / (maxAlpha) * 0.8
          })
        }
        return tempData;
      },

      // d3 绘制图像
      showPeopleSchedule(data) {
        // 清除已有图像
        d3.selectAll("svg").remove();
        d3.selectAll(".tooltip").remove();
        d3.selectAll(".tooltip2").remove();


        var rectData = data;

        //图像参数
        var width = window.innerWidth * .08;
        var height = 40;
        var width_ = 30;
        var height_ = 40;

        var m = {top: 0, right: 0, bottom: 0, left: 0},
          widthAll = window.innerWidth * .8,
          heightAll = 18 * height;

        var axisData = [
          {x: 1, y: 0, text: "星期一"},
          {x: 2, y: 0, text: "星期二"},
          {x: 3, y: 0, text: "星期三"},
          {x: 4, y: 0, text: "星期四"},
          {x: 5, y: 0, text: "星期五"},
          {x: 6, y: 0, text: "星期六"},
          {x: 7, y: 0, text: "星期天"},
          {x: 0, y: 1, text: "08:00-08:45"},
          {x: 0, y: 2, text: "08:55-09:40"},
          {x: 0, y: 3, text: "09:55-10:40"},
          {x: 0, y: 4, text: "10:50-11:35"},
          {x: 0, y: 5, text: "11:45-12:30"},
          {x: 0, y: 6, text: "午间休息"},
          {x: 0, y: 7, text: "13:30-14:15"},
          {x: 0, y: 8, text: "14:25-15:10"},
          {x: 0, y: 9, text: "15:25-16:05"},
          {x: 0, y: 10, text: "16:20-17:05"},
          {x: 0, y: 11, text: "17:15-18:00"},
          {x: 0, y: 12, text: "晚间休息"},
          {x: 0, y: 13, text: "18:30-19:15"},
          {x: 0, y: 14, text: "19:25-20:10"},
          {x: 0, y: 15, text: "20:20-21:05"},
        ];

        var svg = d3.select("#course")
          .selectAll("svg").data(d3.range(1))
          .enter().append("svg")
          .attr("id", "viz")
          .attr("width", widthAll + m.right + m.left)
          .attr("height", heightAll + m.top + m.bottom)
          .append("g")
          .attr('transform', 'translate(' + m.left + ', ' + m.top + ')');

        // 创建tooltip的div
        var div = d3.select("#course").append("div")
          .attr("class", "tooltip")
          .style("opacity", 0);

        var rect = svg.selectAll("rect")
          .data(rectData)
          .enter()
          .append("rect")
          .attr("class", "times bar")
          .attr("stroke", "black")
          .attr("stroke-width", 1)
          .attr("id", d => ("rect" + d.x + "_" + d.y))
          .attr("x", d => d.x * width + width_)
          .attr("y", d => d.y * height + height_)
          .attr("width", width)
          .attr("height", height)
          .attr("rx", 0)
          .attr("ry", 0)
          .style("fill", d => "rgba(37, 92, 191," + d.alpha + ")")
          .on("mouseover", function (event, d) {
            if (d.text) {
              div.transition()
                .duration(200)
                .style("opacity", .9);
              div.html(d.text)
                .style("left", (event.layerX + 10) + "px")
                .style("top", (event.layerY - 18) + "px");
            }
          })
          .on("mouseout", function (d) {
            div.transition()
              .duration(500)
              .style("opacity", 0);
          });

        var textAxis = svg.selectAll('text')
          .data(axisData)
          .enter()
          .append('text')
          .attr('x', d => d.x * width + width_ + 30)
          .attr('y', d => d.y * height + height_ + 20)
          .text(d => d.text)
          .attr('font-size', 12)
          .attr('text-anchor', 'start');
      }
      ,

      // 根据选择学期和人员列表展示数据
      drawPeopleSchedule(selectTerm, peopleList) {
        this.peopleData = this.peopleDataRaw[selectTerm].filter(item => peopleList.indexOf(item["name"])>=0)
        this.peopleDataDraw = this.formPeopleData(this.peopleData)
        this.showPeopleSchedule(this.peopleDataDraw);
      },

      // 后端交互: 加载数据
      loadData() {
        this.$http.get("/apiprivate/disctime/", {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
            this.peopleDataRaw = response.data;
            // 更新学期列表
            for (let key in this.peopleDataRaw) {
              this.termsAll.push(key); // 加入学期候选项
              this.peopleAll[key] = this.peopleDataRaw[key].map(function(item, index, arr) {return item["name"]});
              for (let j = 0; j < this.peopleDataRaw[key].length; j++) {
                this.peopleDataRaw[key][j]["occupyRect"] = this.convertOccupy(this.peopleDataRaw[key][j]["occupy"]); // 转换为矩形数据
              }
            }
            // 形成矩形数据
            for (let key in this.peopleDataRaw) {
              this.peopleDataDraw[key] = this.formPeopleData(this.peopleDataRaw[key]);
            }
            // 初始化学期默认选项
            this.selectTerm = this.termsAll[0];
            // 初始化显示名单
            this.peopleList = this.peopleDataRaw[this.selectTerm].map(function(item, index, arr) {return item["name"]});
            this.isMounted = true;
          });
      }
      ,
    },

    mounted() {
      this.loadData();
    },

    watch: {
      selectTerm(val, oldVal) {
        this.peopleData = this.peopleDataDraw[val];
        this.peopleList = this.peopleDataRaw[val].map(function(item, index, arr) {return item["name"]});
        this.drawPeopleSchedule(val, this.peopleList);
      },
      peopleList(val, oldVal) {
        this.drawPeopleSchedule(this.selectTerm, val);
      }
    }
  }
</script>

<style scoped>
  .axis path {
    fill: none;
    stroke: grey;
    shape-rendering: crispEdges;
  }

  .grid .tick {
    stroke: lightgrey;
    opacity: 0.8;
    stroke-dasharray: 2, 12;
  }

  .grid path {
    stroke-width: 0;
  }

  svg {
    display: block;
    margin: auto;
  }

  .bar {
    stroke: #113374;
    stroke-width: 1px;
  }

  svg {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  /* hover的tooltip */
  #course >>> .tooltip {
    z-index: 999;
    position: absolute;
    text-align: left;
    max-width: 200px;
    height: auto;
    padding: 4px;
    font: 12px sans-serif;
    background: rgba(252, 226, 200, 0.93);
    border: 0px;
    border-radius: 5px;
    box-shadow: 2px 2px 8px #ccc;
  }

</style>