<template>
  <v-container>
    <v-card :loading="!isMounted">
      <v-card-text>
        <!-- 选择学期 -->
        <v-row v-if="isMounted">
          <v-col cols="4" class="pl-8 pb-0">
            <v-select v-model="selectTerm" :items="termsAll" label="选择学期"></v-select>
          </v-col>
          <v-col cols="6" class="pt-8 pb-0">
            <p align="center">点击空白区域标记上课或固定安排时间，再次点击取消，确认后点击「保存」。</p>
          </v-col>
          <v-col md="1" class="">
            <v-btn color="primary" dark block class="mt-2 mb-0" @click="save" :loading="isSubmitting">保 存</v-btn>
          </v-col>
        </v-row>

        <v-row justify="center">

        </v-row>
        <!-- 图像绘制区域 -->
        <v-card flat id="course"></v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import * as d3 from "d3"

  export default {
    name: "consolePrivateTime",
    data() {
      return {
        isMounted: false, // 数据加载是否完成
        termsAll: [],
        selectTerm: "", // 选择的学期
        personDataRaw: [], // 与后端交互返回的数据
        personData: [], // 显示界面的占用情况
        // 保存按钮
        isSubmitting: false,
      }
    },
    methods: {
      // 后端交互: 加载数据
      loadData() {
        this.$http.get("/apiprivate/disctime/?uid=" + localStorage.uid, {headers: {"Authorization": "Token " + localStorage.token}})
          .then(response => {
            this.personDataRaw = response.data;
            if (Object.keys(this.personDataRaw).length==0) {
              console.log("无需时间管理");
            } else {
              // 更新学期列表
            for (let j = 0; j < this.personDataRaw.length; j++) {
              if (this.termsAll.indexOf(this.personDataRaw[j]["term"])<0) {
                this.termsAll.push(this.personDataRaw[j]["term"]); // 加入学期候选项
              }
              this.personDataRaw[j]["occupyRect"] = this.convertOccupy(this.personDataRaw[j]["occupy"]); // 转换为矩形数据
            }
            // 设置默认选中学期
            this.selectTerm = this.termsAll[0];
            this.filterTerm(this.selectTerm);
            this.personData = this.formPersonData(this.personData);
            this.personData = this.showPersonShedule(this.personData);
            }
            this.isMounted = true; // 数据加载完成开始渲染
          });
      },
      // 保存数据之前 查找占据数组
      findPersonOccupy(personData) {
        var tempOccupy = [];
        personData.forEach(function (item, index) {
          if (item["alpha"] > 0) {
            tempOccupy.push([item["x"], item["y"]])
          }
        })
        return tempOccupy;
      },
      // 后端交互: 保存数据
      save() {
        this.isSubmitting = true;
        var occupyList = this.findPersonOccupy(this.personData); // 将目前展示数据转换为 occupyList
        var saveData = {uid: localStorage.uid, name: localStorage.name_zh, occupy: occupyList, term: this.selectTerm};
        var regex = /.*csrftoken=([^;.]*).*$/;
        var xCSRFToken = document.cookie.match(regex) === null ? null : document.cookie.match(regex)[1];
        this.$http.put("/apiprivate/disctime/", saveData, {
          headers: {'content-type': 'application/json', "Authorization": "Token " + localStorage.token,}
        })
          .then(response => {
            this.loadData();
            this.isSubmitting = false;
          })
      },

      // 转换占用时间列表为矩形数据
      convertOccupy(occupyList) {
        var occupyRect = []
        for (let i = 1; i < 8; i++) {
          for (let j = 1; j < 16; j++) {
            var occupyBool = occupyList.filter(item => (item[0] == i) & (item[1] == j)).length > 0; //是否存在于数组中
            occupyRect.push({x: i, y: j, occupy: occupyBool})
          }
        }
        return occupyRect;
      },

      // 筛选学期数据
      filterTerm(term) {
        this.personData = this.personDataRaw.filter(item => item.term == term)[0]["occupyRect"];
      },

      // 构造个人数据
      formPersonData(personData) {
        var temp = personData;
        var tempData = [];
        temp.forEach(function (item, index) {
          var alpha = 0;
          var text = "";
          if (item["occupy"]) {
            alpha = 0.3;
            text = "有课";
          }
          tempData.push({x: item["x"], y: item["y"], text: text, alpha: alpha})
        });
        return tempData;
      },

      // 显示个人数据
      showPersonShedule(data) {
        // 清除已有图像
        d3.selectAll("svg").remove();

        var rectData = data;

        //图像参数
        var width = window.innerWidth * .08;
        var height = 40;
        var width_ = 30;
        var height_ = 40;

        var m = {top: 0, right: 0, bottom: 0, left: 0},
          widthAll = window.innerWidth * .8,
          heightAll = 18 * height;

        var axisData = [
          {x: 1, y: 0, text: "星期一"},
          {x: 2, y: 0, text: "星期二"},
          {x: 3, y: 0, text: "星期三"},
          {x: 4, y: 0, text: "星期四"},
          {x: 5, y: 0, text: "星期五"},
          {x: 6, y: 0, text: "星期六"},
          {x: 7, y: 0, text: "星期天"},
          {x: 0, y: 1, text: "08:00-08:45"},
          {x: 0, y: 2, text: "08:55-09:40"},
          {x: 0, y: 3, text: "09:55-10:40"},
          {x: 0, y: 4, text: "10:50-11:35"},
          {x: 0, y: 5, text: "11:45-12:30"},
          {x: 0, y: 6, text: "午间休息"},
          {x: 0, y: 7, text: "13:30-14:15"},
          {x: 0, y: 8, text: "14:25-15:10"},
          {x: 0, y: 9, text: "15:25-16:05"},
          {x: 0, y: 10, text: "16:20-17:05"},
          {x: 0, y: 11, text: "17:15-18:00"},
          {x: 0, y: 12, text: "晚间休息"},
          {x: 0, y: 13, text: "18:30-19:15"},
          {x: 0, y: 14, text: "19:25-20:10"},
          {x: 0, y: 15, text: "20:20-21:05"},
        ];

        var svg = d3.select("#course")
          .selectAll("svg").data(d3.range(1))
          .enter().append("svg")
          .attr("id", "viz")
          .attr("width", widthAll + m.right + m.left)
          .attr("height", heightAll + m.top + m.bottom)
          .append("g")
          .attr('transform', 'translate(' + m.left + ', ' + m.top + ')');

        function change(d) {
          var tempid = d.x + "_" + d.y
          var temprect = d3.select("#rect" + tempid);

          var tempx = d.x * width + width_ + width / 2 - 15
          var tempy = d.y * height + height_ + height / 2 + 5
          temprect.transition().duration(300).style("fill", "rgba(37, 92, 191," + (0.3 - d.alpha) + ")");
          //if (d.alpha == 0) {
          //svg.append("text").attr("id", "text" + tempid).attr("x", tempx).attr("y", d => tempy).text("有课").attr('font-size', 12).attr('text-anchor', 'start');
          //} else {
          //d3.select("#text" + tempid).transition().duration(300).style("opacity", 0).remove();
          //}
          d.alpha = 0.3 - d.alpha;
        }

        var rect = svg.selectAll("rect")
          .data(rectData)
          .enter()
          .append("rect")
          .attr("class", "times bar")
          .attr("id", d => ("rect" + d.x + "_" + d.y))
          .attr("x", d => d.x * width + width_)
          .attr("y", d => d.y * height + height_)
          .attr("width", width)
          .attr("height", height)
          .attr("rx", 0)
          .attr("ry", 0)
          .style("fill", d => "rgba(37, 92, 191," + d.alpha + ")")
          .on("click", function (event, d) {
            change(d);
          });

        var textAxis = svg.selectAll('text')
          .data(axisData)
          .enter()
          .append('text')
          .attr('x', d => d.x * width + width_ + 30)
          .attr('y', d => d.y * height + height_ + 20)
          .text(d => d.text)
          .attr('font-size', 12)
          .attr('text-anchor', 'start');
        return rectData;
      },
    },
    mounted() {
      this.loadData();
    },
    watch: {
      selectTerm(val, oldVal) {
        if (val) {
          // 访问该学期数据
          this.filterTerm(this.selectTerm);
          //可视化用户数据
          this.personData = this.formPersonData(this.personData);
          this.personData = this.showPersonShedule(this.personData);
        }
      },
    }
  }
</script>

<style scoped>
  axis path {
    fill: none;
    stroke: grey;
    shape-rendering: crispEdges;
  }

  .grid .tick {
    stroke: lightgrey;
    opacity: 0.8;
    stroke-dasharray: 2, 12;
  }

  .grid path {
    stroke-width: 0;
  }

  svg {
    display: block;
    margin: auto;
  }

  #course >>> rect {
    stroke: #113374;
    stroke-width: 1px;
  }

  svg {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  /* hover的tooltip */
  #course >>> .tooltip {
    z-index: 999;
    position: absolute;
    text-align: left;
    max-width: 200px;
    height: auto;
    padding: 4px;
    font: 12px sans-serif;
    background: rgba(252, 226, 200, 0.93);
    border: 0px;
    border-radius: 5px;
    box-shadow: 2px 2px 8px #ccc;
  }

</style>