<template>
    <v-container>
        <head>
  <!-- Required meta tags always come first -->
  <meta charset="utf-8">
  <meta http-equiv="x-ua-compatible" content="ie=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

  <title> DATA130006:自然语言处理 </title>
  
  <link rel="icon" href="./static/image/logo.png">

  <link rel="stylesheet" href="./static/css/bootstrap.min.css">
  <link rel="stylesheet" href="./static/css/font-awesome.min.css">
  <link rel="stylesheet" href="./static/css/pygments/default.min.css">
  <link rel="stylesheet" href="./static/css/style.css">
  <link rel="stylesheet" href="./static/css/custom.css">
  <link rel="stylesheet" href="./static/css/test.css">
  <link href="./static/css2/style.css" rel="stylesheet">
  <link href="./static/fonts/icomoon/style.css" rel="stylesheet">
   
  <meta name="description" content="DATA130006:自然语言处理">
</head>


<header class="header">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
            <h1 style="width:90%;font-size:40px"> DATA130006: 自然语言处理</h1>
          <br />
        </div>
      </div>
    </div>
  </header>

    <div class="site-section">
        <div class="container">
            <div class="row middle">

                <div class="col-lg-12" id="teacher">
                    <div class="section-title">
                        <h2>教师信息</h2>
                    </div>

                    <div class="trend-contents">
                      <table class="table table-bordered" style="width: 80%" align="center">
                        <tbody>
                            <tr> <td width="width:30%"><strong>授课教师</strong></td> <td width="width:50%">魏忠钰（副教授）</td></tr>
                            <tr> <td style="width:30%"><strong>电子邮箱</strong></td> <td style="width:50%">zywei@fudan.edu.cn</td></tr>
                            <tr> <td style="width:30%"><strong>办公室</strong></td> <td style="width:50%">新金博大厦 1407</td></tr>
                            <tr> <td style="width:30%"><strong>个人主页</strong></td> <td style="width:50%"><a href="http://www.sdspeople.fudan.edu.cn/zywei/">http://www.sdspeople.fudan.edu.cn/zywei/</a></td></tr>
                      </tbody>
                      </table>
                    </div>
                </div>
                <div class="col-lg-12" id="introduction">
                        <div class="section-title">
                            <br>
                            <h2 style="color: #1a1a1a">课程介绍</h2>
                        </div>
    
                        <div class="trend-entry d-flex">
                            <p>
                                本课程是计算机学科的核心课程，面向本科高年级学生。本课程的内容主要涵盖自然语言处理的基础知识，主要包括文本的预处理，语言模型，文本分类，情感分析，文本聚类，特征选择，序列标注等内容，为后续解决更复杂的自然语言问题奠定基础。
                            </p>
    
                        </div>
                    </div>
                <div class="col-lg-12" id="chapters">
                    <br>
                    <div class="section-title">
                        <h2>课程内容</h2>
                    </div>

                    <div class="trend-contents">
                      <table class="table table-bordered" style="width: 80%" align="center">
                        <tbody>
                            <tr> <td width="200px"><strong>第零章</strong></td> <td width="650px">自然语言处理导论 <a href="./slides/Lecture1.0.pdf">课件</a><br> 介绍Python和NLTK <a href="./slides/Tutorial1.0.pdf">课件</a></td></tr>
                            <tr> <td width="200px"><strong>第一章</strong></td> <td width="650px">正则表达式和最小编辑距离 <a href="./slides/Lecture2.1.pdf">课件</a><br> 文本预处理 <a href="./slides/Lecture2.2.pdf">课件</a></td></tr>
                            <tr> <td width="200px"><strong>第二章</strong></td> <td width="650px">语言模型  <a href="./slides/Lecture3.1.pdf">课件</a><br> 拼写校正 <a href="./slides/Lecture3.2.pdf">课件</a> <br> 语料库资源和SRILM  <a href="./slides/Tutorial2.0.pdf">课件</a></td></tr>
                            <tr> <td width="200px"><strong>第三章</strong></td> <td width="650px">文本分类和朴素贝叶斯 <a href="./slides/Lecture4.1.pdf">课件</a><br> 情感分析 <a href="./slides/Lecture4.2.pdf">课件</a></td></tr>
                            <tr> <td width="200px"><strong>第四章</strong></td> <td width="650px">主题分析的语言模型 <a href="./slides/Lecture5.1.pdf">课件</a><br> 文本聚类 <a href="./slides/Lecture5.2.pdf">课件</a></td></tr>
                            <tr> <td width="200px"><strong>第五章</strong></td> <td width="650px">主题分析的语言模型 <a href="./slides/Lecture6.1.pdf">课件</a><br> 使用NLTK进行文本分类 <a href="./slides/Tutorial3.0.pdf">课件</a></td></tr>
                            <tr> <td width="200px"><strong>第六章</strong></td> <td width="650px">最大熵模型 <a href="./slides/Lecture7.0.pdf">课件</a><br> 信息抽取 <a href="./slides/Lecture7.1.pdf">课件</a></td></tr>
                            <tr> <td width="200px"><strong>第七章</strong></td> <td width="650px">嘉宾讲座：知识图谱 <a href="./slides/KB-guest-lecture.pdf">课件</a><br> 特征选择 <a href="./slides/FS.pdf">课件</a></td></tr>
                            <tr> <td width="200px"><strong>第八章</strong></td> <td width="650px">序列标注 <a href="./slides/Lecture8.1.pdf">课件</a></td></tr>
                            <tr> <td width="200px"><strong>第九章</strong></td> <td width="650px">词性标注 <a href="./slides/Lecture9.1.pdf">课件</a><br> 统计自然语言解析I <a href="./slides/Lecture9.2.pdf">课件</a><br> 自动内容抽取(ACE)简介  <a href="./slides/Tutorial4.0.pdf">课件</a></td></tr>
                            <tr> <td width="200px"><strong>第十章</strong></td> <td width="650px">统计自然语言解析II <a href="./slides/Lecture10.1.pdf">课件</a></td></tr>
                            <tr> <td width="200px"><strong>第十一章</strong></td> <td width="650px">词汇化的PCFGs <a href="./slides/Lecture11.1.pdf">课件</a><br> 依赖关系解析 <a href="./slides/Lecture11.2.pdf">课件</a></td></tr>
                            <tr> <td width="200px"><strong>第十二章</strong></td> <td width="650px">词表示 <a href="./slides/Lecture12.1.pdf">课件</a></td></tr>
                            <tr> <td width="200px"><strong>第十三章</strong></td> <td width="650px">神经网络基础 <a href="./slides/Lecture13.1.pdf">课件</a><a href="https://github.com/BinRoot/TensorFlow-Book"> Tensorflow资料</a></td></tr>
                            <tr> <td width="200px"><strong>第十四章</strong></td> <td width="650px">课程复习 <a href="./slides/Lecture14.x.pdf">课件</a><br> CNN和RNN基础 <a href="./slides/Lecture14.1.pdf">课件</a></td></tr>
                      </tbody>
                      </table>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <a href="http://www.sdspeople.fudan.edu.cn/zywei/Intro-AI/slides/%E4%BA%BA%E5%B7%A5%E6%99%BA%E8%83%BD%20-%20%E8%AF%BE%E4%BB%B6%20-%20PDF.zip">课件打包下载</a>
                    </div>


                </div>
                </div>
                <div class="col-lg-12" id="referrences">
                    <div class="section-title">
                        <br>
                        <h2 style="color: #1a1a1a">教材及参考书目</h2>
                    </div>

                    <div class="trend-entry d-flex">
                        <ul>
                            <li>Foundations of Statistical Natural Language Processing, C. Manning and H Schutze, 1999.</li>
                            <li>Speech and Language Processing (2nd Edition), an Jurafsky and James H. Martin, 2009.</li>
                            <li>Natural Language Processing with Python, Steven Bird, Ewan Klein, and Edward Loper, 2009.</li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
<br>
<br>

    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    感谢斯坦福大学，伊利诺伊大学厄巴纳-香槟分校分享幻灯片中使用的材料。     <br><br>

    
    </v-container>
    </template>
    
    <script>
      export default {
        name: "pageIntro2nlp"
      }
    </script>
    
    <style scoped>
    
    </style>