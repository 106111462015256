<template>
  <v-container>
    <v-card elevation="4">
      <v-card-text v-if="isLoaded">
        <div flat class="title-article mb-4">
          <v-card-title class="py-0 black--text">{{info.title}}</v-card-title>
          <v-card-title class="caption py-0"> {{convertTime(info.time3)}}</v-card-title>
          <v-card-title class="caption py-0"> 供稿 丨 {{info.authors.join(" ")}}</v-card-title>
          <v-card-title class="caption py-0"> 编辑 丨 {{info.editors.join(" ")}}</v-card-title>
        </div>
        <v-row>
          <!--          <v-col cols="12">-->
          <!--            <v-md-editor v-model="content" height="400px"></v-md-editor>-->
          <!--          </v-col>-->
          <v-col cols="12">
            <v-md-editor mode="preview" height="100%" v-model="content" class="px-md-5"
                         @copy-code-success="handleCopyCodeSuccess"></v-md-editor>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: "pageArticle",
    data() {
      return {
        isLoading: false,
        isLoaded: false,


        peopleInfo: {}, // 用户信息
        linkType: 0, // 链接类别
        content: "", // 显示内容
        hljsCss: 'agate', // markdown主题

      }
    },
    methods: {
      test() {

      },
      // 加载数据
      loadData() {
        this.aid = this.$route.params.pathMatch;
        this.$http.get("/api/read_article/?aid=" + this.aid)
          .then(response => {
            this.info = response.data;
            this.content = this.info.markdown;
            this.isLoaded = true;
          })
      },

      // 转换时间
      convertTime(timeStamp) {
        if (timeStamp) {
          var timeStampObj = new Date(timeStamp)
          return timeStampObj.toLocaleDateString().replace(/\//g, "-") + " " + timeStampObj.toTimeString().substr(0, 8);
        } else {
          return null;
        }
      },

      // 复制markdown中代码块
      handleCopyCodeSuccess(code) {
      },

    },

    mounted() {
      this.loadData();
    },
  }
</script>

<style scoped>

  .title-article {
    border-left: 4px solid #255cbf;
  }

  .v-md-editor-preview {
    padding: 0px !important;
  }

</style>