<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col class="pb-md-16" cols="12" md="4" lg="4" xl="4">
        <v-card-title> {{$t('home.works_annotation.title')}}</v-card-title>
        <v-card-text> {{$t('home.works_annotation.intro')}}</v-card-text>
      </v-col>
      <v-col class="text-center mx-0 my-0 pa-0 example-3d d-flex justify-center" md="8" lg="8" xl="8">
        <v-sheet color="transparent" :width="swiperWidth">
          <!--轮播图-->
          <swiper ref="mySwiperRef" class="swiper" :options="swiperOption">
            <swiper-slide v-for="(item, index) in swiperData" :key="index" class="my-4">
              <v-sheet>
                <v-sheet height="100%" class="parallax-bg " :style="{backgroundImage:'url(' + item.img + ')'}">
                  <v-card flat color="transparent" height="100%" width="100%"
                          class="d-flex align-end justify-center text-center" :href="item.href" target="_blank">
                    <v-card tile class="annotate-swiper-text pa-2" width="300">
                      <span v-if="$i18n.locale=='zh'">{{item.title_zh}}</span>
                      <span v-else>{{item.title_en}}</span>
                    </v-card>
                  </v-card>
                </v-sheet>
              </v-sheet>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-black" slot="pagination"></div>
          </swiper>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "indexHomeAnnotation",
    props: ["annotateWidth"],
    data() {
      return {
        swiperWidth: 780,
        swiperHeight: 300,
        swiperItemWidth: 270,
        swiperOption: {
          autoplay: {delay: 3000, stopOnLastSlide: false, disableOnInteraction: false},
          speed: 1000, //控制切换的速度
          loop: true,
          // loopFillGroupWithBlank: true,
          loopedSlides: 2,
          effect: 'coverflow',
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: 'auto',
          coverflowEffect: {
            rotate: 40,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true
          },
          pagination: {
            el: '.swiper-pagination'
          }
        },
        swiperData: [
          {
            title_zh: "舆情传播",
            title_en: "Information Cascade",
            href: "http://fudan-disc.com/project/annotation/propagation/",
            img: "http://fudan-disc.com/resource/users/lchen/image/home-annotation-propagation.png",
          },
          {
            title_zh: "医疗对话",
            title_en: "Medical Dialogue",
            href: "http://fudan-disc.com/project/annotation/medicaldialogue",
            img: "http://fudan-disc.com/resource/users/lchen/image/home-annotation-medical.png",
          },
          {
            title_zh: "知识推理",
            title_en: "Reasoning Path",
            href: "http://fudan-disc.com/project/annotation/reasoningpath/",
            img: "http://fudan-disc.com/resource/users/lchen/image/home-annotation-reasoning.png",
          },
        ],
      }
    },
    computed: {
    },
    watch: {
      annotateWidth: {
        handler: function (val, oldVal) {
          console.log(val);
          var tempWidth = Math.min(window.innerWidth / 2, 780);
          if (window.innerWidth < 500) {
            this.swiperWidth = window.innerWidth - 20;
          } else {
            this.swiperWidth = tempWidth;
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .annotate-swiper-text {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: rgba(0, 0, 0, 0.58);
    color: white;
  }


  .parallax-bg {
    position: absolute;
    /*z-index: -100;*/
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 5px;
    box-shadow: 0px 5px 15px #ccc;

  }

  .example-3d {
    width: 100%;
    height: 350px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .swiper {
    height: 100%;
    width: 100%;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 270px;
      height: 270px;
      text-align: center;
      font-weight: bold;

      background-position: center;
      background-size: cover;
    }

    .swiper-pagination {
      .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: white;
      }
    }
  }
</style>