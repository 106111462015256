<template>
  <v-card flat color="transparent">
    <v-card-title class="pt-0"><span class="title-type">{{$t('home.news')}}</span></v-card-title>
    <v-timeline dense v-if="isLoaded">
      <v-timeline-item color="primary" fill-dot right :small="index!=0" v-for="(item, index) in items"
                       :key="index">
        <v-card>
          <v-card-title class="py-0">
            <h6 class="font-weight-light">{{item.date}}</h6>
          </v-card-title>
          <v-card-text class="text-news">
            <v-card flat color="transparent" v-if="$i18n.locale=='zh'" v-html="item.content_zh"></v-card>
            <v-card flat color="transparent" v-else v-html="item.content_en"></v-card>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-card>
</template>

<script>
  export default {
    name: "indexHomeNews",
    data() {
      return {
        isLoaded: false, // 数据加载是否完成
        items: [], // 新闻列表
        timelineData: [
          {
            time: "2021-03-11",
            news_zh: "Fudan DISC 两篇论文被 NACCL 2021 录取。",
            news_en: "Two paper was accepted by NACCL 2021!"
          },
          {
            time: "2021-01-20",
            news_zh: "Fudan DISC 获华为2020年度优秀技术成果奖",
            news_en: "Fudan DISC won Huawei 2020 Excellent Technical Achievement Award!"
          },
          {
            time: "2020-12-02",
            news_zh: "Fudan DISC 一篇论文被 AAAI 2021 录取。",
            news_en: "One paper was accepted by AAAI 2021!"
          },
        ],
      }
    },
    methods:{
      // ----- 后端交互 -----
      // 读取数据
      loadData(){
        this.$http.get("/api/read_news/")
          .then(response => {
            this.items = response.data;
            this.isLoaded = true;
            console.log(this.items);
          })
      },
    },
    mounted() {
      this.loadData();
    },
  }
</script>

<style scoped>
  .title-type {
    border-bottom: 2px solid #255cbf;
  }

  .text-news >>> a {
    text-decoration: none;
  }

</style>